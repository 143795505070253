import React from 'react';
import UnButton from "../../components/UnButton";
import {useHistory} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

import useCollapse from 'react-collapsed';
import '../../App.css';

function Section(props) {
    const config = {
        defaultExpanded: props.defaultExpanded || false,
        collapsedHeight: props.collapsedHeight || 0
    };
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
return (
    <div className="collapsible">
        <div className="header mt-6 mb-6" {...getToggleProps()}>
            <div className="title underline font-bold text-black-theme" >{(isExpanded ? 'Weitere Informationen' : props.title)}</div>
            {/* <div className="icon">
                <i className={'fas fa-chevron-circle-' + (isExpanded ? 'up' : 'down')}></i>
            </div> */}
        </div>
        <div {...getCollapseProps()}>
            <div className="content mb-6">
                {props.children}
            </div>
        </div>
    </div>
    );
}

// https://files.oncodemia.com/de/privacy/

const PrivacyPolicy = () => {
  const {goBack} = useHistory();
  return (
    <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">  
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6">Datenschutz- und Cookie-Richtlinie</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Dies ist die Datenschutz- und Cookie-Richtlinie für die Oncodemia-Plattform
    auf&nbsp; <a className="underline font-bold text-black-theme" href="https://www.oncodemia.com/">www.oncodemia.com</a>
    &nbsp;(die "Plattform") und die separate „Registrierungswebseite zum
    Oncodemia-Apotheker auf&nbsp;
     <a className="underline font-bold text-black-theme" href="https://registrierung.unifyhealth.app/">
      https://registrierung.unifyhealth.app
    </a>
    &nbsp;(die "Registrierungswebseite"). Die Plattform und die
    Registrierungswebseite werden von oder im Namen von Accord Healthcare
    Limited („wir“, „uns“ oder „unser“) betrieben.
    <br />
    <br />
    Diese Datenschutz- und Cookie-Richtlinie legt fest, wie wir Ihre
    personenbezogenen Daten im Zusammenhang mit dem Betrieb der Plattform
    erheben und nutzen. Diese Datenschutz- und Cookie-Richtlinie umfasst
    folgende Informationen:
  </p>
  <ul className="md:pl-8 list-disc">
  <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-1">Daten, die wir über Sie erheben und wie wir sie nutzen
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-2">Cookies und andere Technologien
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-3">Unsere Werbeupdates und -mitteilungen
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-4">An wen wir Ihre Daten weitergeben
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-5">Wo wir Ihre Daten speichern und wohin wir sie übermitteln
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-11">Sicherheit von Kindern
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-6">Wie wir Ihre Daten schützen
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-7">Wie lange wir Ihre Daten aufbewahren
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-8">Ihre Rechte
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-9">Änderungen dieser Datenschutzerklärung
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-10">Kontakt</HashLink>
    </li>
  </ul>

  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
    Wir verpflichten uns zum Schutz und zur Achtung Ihrer Privatsphäre. Bitte
    lesen Sie die folgenden Informationen sorgfältig durch, um zu verstehen, wie
    wir mit Ihren personenbezogenen Daten umgehen und wie wir sie verarbeiten.
    <br />
    <br />
    Durch die Inanspruchnahme unserer Dienstleistungen und durch die Nutzung der
    Plattform und der Registrierungswebseite bestätigen Sie, dass Sie diese
    Datenschutzerklärung gelesen und verstanden haben.
    <br />
    <br />
    Für die Zwecke der im Vereinigten Königreich und im Europäischen
    Wirtschaftsraum geltenden Datenschutzgesetze (das „Datenschutzrecht“) ist
    der datenschutzrechtlich Verantwortliche: Accord Healthcare Limited of Sage
    House, 319 Pinner Road, North Harrow, Middlesex, HA1 4HF, Vereinigtes
    Königreich.
  </p>

  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-1">
    Daten, die wir über Sie erheben, und wie wir sie nutzen
  </h1>

  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Wir erheben und verarbeiten folgende personenbezogenen Daten von Ihnen:
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Daten, die Sie uns zur Verfügung stellen</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Diese sind Daten über Sie, die Sie uns direkt angeben, wenn Sie mit uns
    interagieren, indem Sie Formulare auf der Plattform oder der
    Registrierungswebseite ausfüllen oder indem Sie uns telefonisch, per E-Mail
    oder anderweitig kontaktieren. Dazu gehören Daten, die für die
    Bereitstellung der folgenden Funktionalitäten erforderlich sind:
  </p>
  <ul className="md:pl-8 list-disc">
    <li className="text-black-theme tracking-wide leading-normal">Registrierung für die Nutzung der Plattform.</li>
    <li className="text-black-theme tracking-wide leading-normal">
      Registrierung als Oncodemia-Apotheker (die „Registrierung“), um innerhalb
      des Apothekenfinders in der separaten Unify Health App zu erscheinen.
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Teilnahme an einer Plattform-Werbeaktion oder -Umfrage, oder wenn Sie eine
      Anfrage stellen oder ein Problem mit der Plattform oder mit der
      Registrierungswebseite melden.
    </li>
  </ul>
  {/* <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" > */}
     {/* <a className="underline font-bold text-black-theme"  href="#sec1">Weitere Informationen</a> */}
  <Section title={'Weitere Informationen'}>
     <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >Zu den Daten, die Sie uns zur Verfügung stellen, können gehören:</p>
    <ul className="md:pl-8 list-disc">
      <li className="text-black-theme tracking-wide leading-normal">
        Angaben zu Ihrer Identität: Ihr Name, Ihr Benutzername und Ihr gewähltes
        Passwort;
      </li>
      <li className="text-black-theme tracking-wide leading-normal">Kontaktinformationen: E-Mail-Adresse, Stadt und Wohnsitzland;</li>
      <li className="text-black-theme tracking-wide leading-normal">
        Inhalt Ihrer Anfrage bzw. Kontaktaufnahme sowie daraufhin stattfindender
        Kommunikation
      </li>
    </ul>
     <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
      Wir nutzen entsprechende Daten um Ihr Oncodemia-Konto zu erstellen und um
      Sie zu identifizieren, wenn Sie sich bei der Plattform anmelden oder Ihre
      Registrierung mit Ihrem bestehenden Oncodemia-Konto abgleichen. Wir nutzen
      Ihre Kontaktinformationen und Informationen zu Ihrer Anfrage, um diese zu
      bearbeiten.

    </p>
    <ul className="md:pl-8 list-disc">
      <li className="text-black-theme tracking-wide leading-normal">
        Informationen zum Berufsprofil: Informationen, die für Ihre Registrierung
        relevant sind, wenn Sie diese vornehmen, einschließlich:
        <br />
        <br />
        Das Land, in dem Sie arbeiten, Ihre Position, Ihre Apotheken Meldenummer,
        Land/Berufsverband der Registrierung Arbeitsort, Name, Adresse, Website,
        Profilbild (sofern Sie eins teilen möchten), und eine kurze Erklärung zu
        Ihren Fachgebieten oder denen Ihrer Apotheke, um Krebspatienten in der
        separaten Unify Health App die Suche nach geeigneten Apothekern zu
        erleichtern.
        <br />
        <br />
        Durch Ihr im Rahmen der Registrierung ausgefülltes Profil, können
        Krebspatienten Sie leichter über die separate Unify Health-App finden, wo
        entsprechende Profildaten angezeigt werden.
      </li>
    </ul>
  </Section>
  {/* </p> */}

  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" >
    Daten, die wir über Sie bei der Nutzung der Plattform und der
    Registrierungswebseite erheben
  </h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Wir erheben automatisch Daten von Ihnen, wenn Sie die Plattform oder die
    Registrierungswebseite besuchen. Dazu können gehören:
  </p>
  <ul className="md:pl-8 list-disc">
    <li className="text-black-theme tracking-wide leading-normal">
      Technische Informationen – die mit Ihrem Gerät und Browser verknüpft sind.
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Sitzungsinformationen – aus Ihrer Interaktion mit der Plattform und der
      Registrierungswebseite.
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Schulungsinformationen – über Ihre Interaktion mit den Kursinhalten der
      Oncodemia-Plattform und den Status Ihrer Schulung.
    </li>
  </ul>
  {/* <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
     <a className="underline font-bold text-black-theme"  href="#sec2">Weitere Informationen</a>
  </p> */}
<Section title={'Weitere Informationen'}>

      <ul className="md:pl-8 list-disc">
      <li className="text-black-theme tracking-wide leading-normal">
        Standortdaten auf einer aggregierten Ebene, die es uns ermöglicht,
        Plattforminhalte zu gestalten, die den geltenden lokalen gesetzlichen
        Anforderungen entsprechen
      </li>
      <li className="text-black-theme tracking-wide leading-normal">
        Zu den technischen Informationen gehören die Internetprotokoll-Adresse
        (IP-Adresse), mit der Ihr Computer an das Internet angebunden ist, Ihre
        Login-Daten, Browsertyp und -version, Zeitzoneneinstellung,
        Browser-Plug-in-Typen und -Versionen, Betriebssystem und Plattform;
      </li>
      <li className="text-black-theme tracking-wide leading-normal">
        Sitzungsinformationen über Ihren Besuch einschließlich des vollständigen
        Uniform Resource Locators (URL), der Clickstream zu, durch und von unserer
        Plattform (einschließlich Datum und Uhrzeit); angesehene oder gesuchte
        Elemente, Seitenreaktionszeiten, Downloadfehler, Länge der Besuche auf
        bestimmten Seiten, Informationen zur Seiteninteraktion (wie Scrollen, Klicks
        und Mauszeiger), Methoden, die verwendet werden, um die Seite zu verlassen,
        und Telefonnummern oder Social-Media-Handles, die verwendet werden, um mit
        unserem Kundenservice Kontakt aufzunehmen.
      </li>
      <li className="text-black-theme tracking-wide leading-normal">
        Schulungsinformationen einschließlich der belegten und abgeschlossenen
        Module, der Antworten, die Sie auf Fallstudien und/oder Prüfungen zu
        „Wissensüberprüfungen“ innerhalb der Plattform geben, der konsultierten
        Lernressourcen der Plattform, aller Anmerkungen, die Sie zu den Kursinhalten
        machen, sowie Informationen über die berufliche Entwicklung, die Sie
        möglicherweise haben.
      </li>
      <li className="text-black-theme tracking-wide leading-normal">
        Standortdaten: relevant für die Identifizierung des Standorts, von dem aus
        Sie auf die Plattform zugreifen, damit wir den Inhalt der Plattform
        entsprechend der geltenden lokalen gesetzlichen Bestimmungen wiedergeben
        können und um die Standorte der Nutzer, die auf unsere Dienste zugreifen, in
        aggregierter Form zu ermitteln.
      </li>
    </ul>
</Section>


    
   
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6" >Daten, die wir aus anderen Quellen erhalten.</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Dies sind Daten, die wir über Sie oder Ihre Apotheke erhalten, insbesondere:
  </p>
  <ul className="md:pl-8 list-disc">
    <li className="text-black-theme tracking-wide leading-normal">
      Professionelle Profilinformationen – über Ihre Apotheke als Ergänzung zu
      Ihrem Registrierungsprofil.
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Wenn Sie oder Ihre Apotheke eine der anderen von uns betriebenen Websites
      oder Apps oder andere von uns angebotene Dienste nutzen.
    </li>
  </ul>
  {/* <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
     <a className="underline font-bold text-black-theme"  href="#sec3">Weitere Informationen</a>
  </p> */}
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6">Eine vollständige Liste der von uns verarbeiteten
    Daten, der Zwecke, für die wir sie verarbeiten, und der Rechtsgrundlage, auf
    Grund derer sie verarbeitet werden, finden Sie am Ende dieser Datenschutz-
    und Cookie-Richtlinie.
  </p>

  <Section title={'Weitere Informationen'}>

    <ul className="md:pl-8 list-disc">
      <li className="text-black-theme tracking-wide leading-normal">
        Berufsbezogene Profilinformationen, die Ihre Registrierung enthält, werden
        durch weitere Informationen aus dem öffentlich zugänglichen Google-Profil
        Ihrer Apotheke ergänzt, wie bspw. Öffnungszeiten, Außenfotos. Bitte
        überprüfen Sie, ob diese Informationen auf
        https://business.google.com/create aktuell sind.
      </li>
      <li className="text-black-theme tracking-wide leading-normal">
        Wenn Sie andere unserer Websites und Dienste nutzen, kann dies Auswirkungen
        darauf haben, wie Ihr Profil innerhalb des Moduls „Apothekenfinder“, welches
        den Nutzern der separaten Unify Health App zur Verfügung steht, eingestuft
        wird.
      </li>
      <li className="text-black-theme tracking-wide leading-normal">
        Wir arbeiten eng mit Dritten zusammen (z. B. Geschäftspartnern,
        Subunternehmern in den Bereichen Technik, Dienstleistungen, Analyseanbietern
        und Anbietern von Suchinformationen). Wir werden Sie benachrichtigen, wenn
        wir Daten über Sie von ihnen erhalten und zu welchen Zwecken wir diese Daten
        nutzen wollen.
      </li>
    </ul>

  </Section>

  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"  id="section-2">Cookies und andere Technologien</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Die Plattform verwendet Cookies und/oder ähnliche Technologien, um bestimmte
    Daten zu erheben und zu speichern. Dabei handelt es sich in der Regel um
    kleine Textdateien, die eine Website auf Ihrem Gerät speichert und auf die
    sie zugreift, um Informationen über Sie bzw. Ihr Gerät oder Ihren Browser zu
    erheben. Cookies und ähnliche Technologien ermöglichen es, dass man sich an
    Sie erinnert, wenn Sie Ihr Gerät benutzen, um mit Websites und
    Online-Diensten zu interagieren, und können zur Verwaltung einer Reihe von
    Funktionen und Inhalten sowie zur Speicherung von Suchvorgängen und zur
    Darstellung personalisierter Inhalte verwendet werden.
    <br />
    <br />
    Die Plattform verwendet Cookies und ähnliche Technologien, um Sie von
    anderen Benutzern der Plattform zu unterscheiden. Dies hilft uns, Ihnen eine
    verbesserte Benutzererfahrung zu bieten, wenn Sie auf unserer Website
    surfen, und ermöglicht es uns auch, die Plattform zu verbessern.
    <br />
    <br />
    Wir informieren Sie auch darüber, wie Sie Cookies deaktivieren können.
    Sofern Sie alle oder einzelne Cookies deaktivieren, können Sie unsere
    Plattform und ihre Funktionalitäten möglicherweise nicht in vollem Umfang
    nutzen. Eine Reihe der von uns verwendeten Cookies und ähnlichen
    Technologien gilt nur für die Dauer Ihrer Web-Sitzung und läuft nach Ihrer
    Web-Sitzung ab, sobald Sie Ihren Browser schließen. Andere Cookies werden
    dazu verwendet, dass die Plattform Sie wiedererkennt, wenn Sie zu ihr
    zurückkehren; diese sind für einen längeren Zeitraum gültig.
    <br />
    <br />
    
    Wir verwenden diese Cookies und andere Technologien auf der Grundlage Ihrer
    Einwilligung oder, sofern Ihre Einwilligung nicht erforderlich ist, weil die
    Nutzung zur Erfüllung des Vertrages mit Ihnen erforderlich ist oder in
    unserem berechtigten Interesse liegt (sofern wir der Ansicht sind, dass Ihre
    Interessen oder Grundrechte und Grundfreiheiten gegenüber unseren Interessen
    nicht überwiegen).
   
  </p>
    <Section title={'Weitere Informationen'}>

         <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >Wir verwenden folgende Arten von Cookies:</p>
        <ul className="md:pl-8 list-disc">
          <li className="text-black-theme tracking-wide leading-normal">
            Technisch notwendige Cookies: Dies sind Cookies, die für den Betrieb
            unserer Plattform gemäß unserer vertraglichen Vereinbarungen mit Ihnen
            erforderlich sind. Dazu gehören beispielsweise Cookies, die es Ihnen
            ermöglichen, die sicheren Bereiche unserer Plattform zu besuchen und sich
            dort anzumelden.
          </li>
          <li className="text-black-theme tracking-wide leading-normal">
            Funktionale Cookies: Mit Ihrer Einwilligung wird ein Cookie aktiviert, der
            es uns ermöglicht, sich Ihren Benutzernamen für die Plattform für Ihre
            nächste Anmeldung zu merken.
          </li>
          <li className="text-black-theme tracking-wide leading-normal">
            Analytische/Performance-Cookies: Mit Ihrer Einwilligung werden Cookies
            aktiviert, die es uns ermöglichen, die Besucher unserer Plattform zu
            erkennen und sie zählen und zu sehen, wie sich Besucher auf unserer
            Plattform bewegen. Dies hilft uns, die Funktionsweise unserer Website zu
            verbessern, indem wir beispielsweise sicherstellen, dass die Benutzer das
            finden, was sie suchen.
          </li>
        </ul>
        <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Cookies deaktivieren</h1>
         <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
          Welche Auswirkungen die Deaktivierung von Cookies hat, hängt davon ab,
          welche Cookies Sie deaktivieren, aber im Allgemeinen kann die Plattform
          nicht ordnungsgemäß funktionieren, wenn alle Cookies deaktiviert sind.
          <br />
          <br />
          Wenn Sie Cookies auf unserer Website deaktivieren möchten, müssen Sie die
          Browsereinstellungen Ihrer Website ändern, um Cookies abzulehnen. Wie das
          geht, hängt vom verwendeten Browser ab. Weitere Informationen zur
          Deaktivierung von Cookies für die wichtigsten Browser finden Sie unten:
        </p>
        <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Für Microsoft Internet Explorer:</h1>
        <ol  className="md:pl-8">
          <li className="text-black-theme tracking-wide leading-normal">Wählen Sie das Menü „Extras“ und dann „Internetoptionen“</li>
          <li className="text-black-theme tracking-wide leading-normal">Klicken Sie auf die Registerkarte „Datenschutz“</li>
          <li className="text-black-theme tracking-wide leading-normal">Wählen Sie die entsprechende Einstellung</li>
        </ol>
        <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Für Google Chrome:</h1>
        <ol  className="md:pl-8">
          <li className="text-black-theme tracking-wide leading-normal">Wählen Sie „Einstellungen“ und dann „Erweitert“</li>
          <li className="text-black-theme tracking-wide leading-normal">
            Klicken Sie unter „Datenschutz und Sicherheit“ auf „Inhaltseinstellungen“.
          </li>
          <li className="text-black-theme tracking-wide leading-normal">Klicken Sie auf „Cookies“</li>
        </ol>
        <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Für Safari:</h1>
        <ol  className="md:pl-8">
          <li className="text-black-theme tracking-wide leading-normal">Wählen Sie „Einstellungen“ und dann „Datenschutz“</li>
          <li className="text-black-theme tracking-wide leading-normal">Klicken Sie auf „Alle Website-Daten entfernen“</li>
        </ol>
        <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Für Mozilla firefox:</h1>
        <ol  className="md:pl-8">
          <li className="text-black-theme tracking-wide leading-normal">Wählen Sie das Menü „Tools“ und dann „Options“.</li>
          <li className="text-black-theme tracking-wide leading-normal">Klicken Sie auf die Kategorie „Datenschutz“</li>
          <li className="text-black-theme tracking-wide leading-normal">
            Suchen Sie die Option „Cookie“ und wählen Sie die entsprechenden Optionen
            aus
          </li>
        </ol>
        <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> Für Opera 6.0 und weitere:</h1>
        <ol  className="md:pl-8">
          <li className="text-black-theme tracking-wide leading-normal">Wählen Sie im Menü „Dateien“ und dann „Einstellungen“</li>
          <li className="text-black-theme tracking-wide leading-normal">Datenschutz</li>
        </ol>
         <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
          Wenn Sie Ihre Berechtigungen nicht eingestellt haben, können wir Sie auch
          auffordern, die Verwendung von Cookies auf der Plattform gesondert zu
          prüfen. Weitere Informationen zu den einzelnen Cookies, die wir verwenden,
          und den Zwecken, für die wir sie verwenden, finden Sie in der nachfolgenden
          Cookie Übersicht:

        </p>
          

        <table className="table flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6">
  <thead>
    <tr>
      <th scope="col" className='unify-table-header'>Cookie</th>
      <th scope="col" className='unify-table-header'>Name</th>
      <th scope="col" className='unify-table-header'>Umfang und Zwecke der Verarbeitung</th>
      <th scope="col" className='unify-table-header'>Speicherdauer</th>
      <th scope="col" className='unify-table-header'>Weitere informationen</th>
    </tr>
  </thead>
  <tbody>

    <tr>
      <td dangerouslySetInnerHTML={{__html: 
      '<!-- OneTrust Cookies List start --><div id="ot-sdk-cookie-policy"></div><!-- OneTrust Cookies List end -->'
      }} />
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>Persistant cookie</td>
      <td className='align-top unify-table-cell-first'>OptanonConsent</td>
      <td className='align-top unify-table-cell-first'>Dieses Cookie wird durch die Cookie-Compliance-Lösung von OneTrust gesetzt. Es speichert Informationen über die Kategorien von Cookies, die die Website verwendet und ob Besucher ihre Zustimmung für die Verwendung jeder Kategorie gegeben oder widerrufen haben. Dadurch können Website-Besitzer verhindern, dass Cookies in jeder Kategorie im Benutzerbrowser gesetzt werden, wenn keine Zustimmung erteilt wird. Der Cookie hat eine normale Lebensdauer von einem Jahr, so dass zurückkehrende Besucher auf der Website ihre Vorlieben in Erinnerung behalten. Sie enthält keine Informationen, die den Websitebesucher identifizieren können.</td>
      <td className='align-top unify-table-cell'>365 Tage</td>
      <td />
    </tr>
    {/* The lifespan for the cookie can be configured in Geolocation rules. 
    
    https://community.cookiepro.com/s/article/UUID-1e75fb0d-bb79-2af8-cb85-f905d16f1220?language=en_US

    https://www.onetrust.de/cookie-hinweis/

    OptanonAlertBoxClosed -> It is persistent and has a default lifespan of one year.
    
    */}
    <tr>
      <td className='align-top unify-table-cell-first'>Persistant cookie</td>
      <td className='align-top unify-table-cell-first'>OptanonAlertBoxClosed</td>
      <td className='align-top unify-table-cell-first'>Dieses Cookie wird von Websites gesetzt, die bestimmte Versionen der Cookie-Compliance-Lösung von OneTrust verwenden. Sie wird gesetzt, nachdem Besucher einen Cookie-Informationshinweis gesehen haben und in einigen Fällen nur, wenn sie die Benachrichtigung aktiv schließen. Es ermöglicht der Website, die Nachricht nicht mehr als einmal für einen Benutzer anzuzeigen. Das Cookie hat eine Lebensdauer von einem Jahr und enthält keine persönlichen Informationen.</td>
      <td className='align-top unify-table-cell'>365 Tage</td>
      <td />
    </tr>
    {/* <tr>
      <td className='align-top unify-table-cell'>Session cookie</td>
      <td className='align-top unify-table-cell'>TotaraSession MoodleSessionXXX</td>
      <td className='align-top unify-table-cell'>
        This cookie is essential in our legitimate interests for the operation
        of our Platform. It remembers you as the same user as you navigate the
        platform.
      </td>
      <td />
    </tr>
    <tr>
      <td className='align-top unify-table-cell'>User id cookie</td>
      <td className='align-top unify-table-cell'>Totara MOODLEID1_XXX</td>
      <td className='align-top unify-table-cell'>
        If selected by the user and on the basis of their consent, this cookie
        enables the user's username to be remembered at sign-in.
      </td>
      <td />
    </tr> 
    <tr>
      <td className='align-top unify-table-cell'>Universal Analytics</td>
      <td className='align-top unify-table-cell'>Google _ga _ gali _ gat _gid</td>
      <td className='align-top unify-table-cell'>
        These cookies collect, with your consent, information about how visitors
        use our platform. We use information to compile reports to understand
        how users interact with the platform and so we can make improvements.
        The cookies collect information in a way that does not directly identify
        anyone, including the number of visitors to the platform, where visitors
        have come to the platform from and the pages that are visited.
      </td>
      <td className='align-top unify-table-cell'>
        See Google's Safeguarding your data overview
        https://support.google.com/analytics/answer/6004245 opt-out
        https://support.google.com/analytics/answer/181881?
      </td>
    </tr>*/}
  </tbody>
</table>




  </Section>



  

  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-3">Unsere Werbeupdates und -mitteilungen</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Mit Ihrer vorherigen Einwilligung werden wir Ihre personenbezogenen Daten
    für Marketinganalysen verwenden und Ihnen Werbemitteilungen per Email
    zusenden Sofern Sie bereits Produkte und/oder Dienstleistungen von uns
    erworben haben, können wir Sie jedoch über ähnliche Produkte oder
    Dienstleistungen von uns per E-Mail informieren, sofern wir Sie vorab über
    diese Möglichkeit informiert haben und Ihnen die Möglichkeit gegeben haben,
    dies abzulehnen. Diese Datenverarbeitung erfolgt aufgrund unseres
    berechtigten Interesses, unser Geschäft mit Bestandskunden zu fördern. Bitte
    beachten Sie, dass Sie sich von diesen Werbemitteilungen jederzeit abmelden
    können, indem Sie uns unter&nbsp;
     <a className="underline font-bold text-black-theme"  href="mailto:privacy@oncodemia.com">privacy@oncodemia.com</a>&nbsp;(für
    Oncodemia) oder unter&nbsp;
     <a className="underline font-bold text-black-theme"  href="privacy@unifyhealth.app">
      privacy@unifyhealth.app
    </a>
    &nbsp;(für Unify Health Registrierungswebseite) kontaktieren.

  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-4">An wen wir Ihre Daten weitergeben</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Ziel der Plattform ist es, Schulungen und Ressourcen anzubieten, die sich
    auf die Unterstützung von Patienten mit Krebsbehandlung in Ihrer Nähe
    beziehen. Die Registrierungswebseite ermöglicht es Apothekern, die ein
    geeignetes Schulungsprogramm wie das auf der Plattform oder eine andere
    geeignete Weiterbildung nach ihrem Abschluss absolviert haben, sich über die
    Registrierungswebseite anzumelden, damit sich Krebspatienten in ihrer Nähe
    an sie wenden können. Dafür geben Sie Daten zu Ihrem beruflichen Hintergrund
    und zur Apotheke, in der Sie tätig sind, an. Die Patienten erhalten Zugang
    zu den Google-Maps-Informationen über den Standort der Apotheke, in der bei
    uns registrierte Apotheker arbeiten, sowie zu den damit verbundenen
    Informationen, die Sie über die Registrierung angegeben haben. Wir geben
    Ihre Daten ggf. an die folgenden Empfänger weiter:
  </p>
  <ul className="md:pl-8 list-disc">
    <li className="text-black-theme tracking-wide leading-normal">
      Unsere Gruppenunternehmen, d. h. unsere Tochtergesellschaften, unsere
      Muttergesellschaft und ihre Tochtergesellschaften, die unsere Verarbeitung
      personenbezogener Daten gemäß dieser Datenschutz- und Cookie-Richtlinie
      unterstützen. Eine vollständige Liste dieser Unternehmen finden Sie&nbsp;
       <a className="underline font-bold text-black-theme"  href="https://www.accord-healthcare.com/about-accord/global-presence">
        hier.
      </a>
      &nbsp;Wenn eines dieser Unternehmen Ihre Daten für Zwecke der
      Direktwerbung verwenden möchte, werden wir die Daten nur mit Ihrer
      vorherigen Einwilligung weitergeben.
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Ausgewählte Dritte, wie z. B. Dienstleister, die uns bei der
      Bereitstellung unserer Angebote unterstützen.
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Öffentliche Stellen und Behörden (Verwaltung oder Justiz) in Fällen, in
      denen eine gesetzliche Verpflichtung zur Weitergabe personenbezogener
      Daten besteht.
    </li>
  </ul>
  {/* <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
     <a className="underline font-bold text-black-theme"  href="#sec6">Weitere Informationen</a>
  </p> */}
 <Section title={'Weitere Informationen'}>



<h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6"> 
  Zu den ausgewählten Dritten, mit denen wir Ihre personenbezogenen Daten ggf.
  teilen, zählen unter anderem:
</h1>
<ul className="md:pl-8 list-disc">
  <li className="text-black-theme tracking-wide leading-normal">
    Unternehmen, die Ihre personenbezogenen Daten in unserem Auftrag und gemäß
    unseren Anweisungen und dem relevanten Datenschutzrecht verarbeiten. Dazu
    gehören Unternehmen, die die folgenden Aufgaben übernehmen: die
    Unterstützung der Dienstleistungen, die wir über die Plattform und die
    Registrierungswebseite anbieten, insbesondere die Bereitstellung von
    Website- und Datenhosting-Dienstleistungen, die Bereitstellung von
    Fulfillment-Dienstleistungen, Kommunikationsdienstleistungen, die
    Aktualisierung oder Führung von Marketinglisten, die Feedback-, Umfrage
    und Kundendienstangebote und die gelegentliche Bereitstellung von
    IT-App-Support- und Entwicklungsdienstleistungen. Diese Unternehmen (zu
    denen auch Drittanbieter, Vertreter, Subunternehmer und/oder andere
    Unternehmen unserer Gruppe gehören können) werden nur in dem Umfang Ihre
    Daten verarbeiten, der für die Erfüllung ihrer Supportfunktionen
    erforderlich ist. Die Weitergabe von Daten an diese Anbieter erfolgt auf
    Grundlage von einschlägigen Vereinbarung nach dem jeweiligen
    Datenschutzrecht.
  </li>
  <li className="text-black-theme tracking-wide leading-normal">
    Werbetreibende/Inserenten: Wir geben keine Informationen über
    identifizierbare Personen an unsere Werbetreibenden weiter, aber wir
    können ihnen aggregierte Informationen über unsere Benutzer zur Verfügung
    stellen, um unsere Plattform potenziellen Partnern zu erklären.
  </li>
  <li className="text-black-theme tracking-wide leading-normal">
    Anbieter von Analysen und Suchmaschinen, die uns bei der Verbesserung und
    Optimierung unserer Plattform unterstützen und die im Abschnitt über
    „Cookies und andere Technologien“ in dieser Datenschutz- und
    Cookie-Richtlinie beschrieben sind.
  </li>
</ul>
 <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6" >
  Wir geben Ihre personenbezogenen Daten in bestimmten Fällen außerdem an
  folgende Dritte weiter:
</p>
<ul className="md:pl-8 list-disc">
  <li className="text-black-theme tracking-wide leading-normal">
    Wenn wir unser Unternehmen oder Unternehmensteile verkaufen oder ein
    anderes Unternehmen oder Unternehmensteile kaufen, geben wir
    personenbezogenen Daten an den potenziellen Verkäufer oder Käufer weiter.
  </li>
  <li className="text-black-theme tracking-wide leading-normal">
    Wenn wir verpflichtet sind, Ihre personenbezogenen Daten offenzulegen oder
    weiterzugeben, um einer gesetzlichen Verpflichtung nachzukommen oder um
    unsere Lieferbedingungen und andere Vereinbarungen mit Ihnen durchzusetzen
    oder anzuwenden; oder um die Rechte, das Eigentum oder die Sicherheit von
    Accord Healthcare Limited, unseren Benutzern oder anderen zu schützen.
    Dazu gehört der Austausch von Informationen mit anderen Unternehmen und
    Organisationen zum Zwecke des Betrugsschutzes und zur Verhinderung von
    Cyberkriminalität.
  </li>
</ul>
</Section>
  
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-5">Wo wir Ihre Daten speichern</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Die Plattform, die Registrierungswebseite und die zugehörigen Daten, die von
    Ihnen erfasst werden, werden auf Servern in Europa gehostet. Wir sitzen im
    Vereinigten Königreich und damit außerhalb des Europäischen Wirtschaftsraums
    („EWR“). Datenübermittlungen in das Vereinigte Königreich sind über einen
    Angemessenheitsbeschluss der Europäischen Kommission rechtlich abgesichert.
    Gelegentlich beauftragen wir Dienstleister oder arbeiten mit unseren
    Konzerngesellschaften zusammen, wobei es zu Übermittlungen personenbezogener
    Daten außerhalb des EWR kommt.
    <br />
    <br />
     {/* <a className="underline font-bold text-black-theme"  href="#sec7">Weitere Informationen</a> */}
  </p>
  
  <Section title={'Weitere Informationen'}>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" >
  Wir können Ihre personenbezogenen Daten auch in Länder außerhalb des EWR
  übermitteln:
</h1>
<ul className="md:pl-8 list-disc">
  <li className="text-black-theme tracking-wide leading-normal">
    Um den Betrieb der über die Plattform und die Registrierungswebseite
    bereitgestellten Dienste oder aus anderen Gründen, die für die
    Vertragserfüllung erforderlich ist, zu unterstützen.
  </li>
  <li className="text-black-theme tracking-wide leading-normal">Wenn wir gesetzlich dazu verpflichtet sind.</li>
  <li className="text-black-theme tracking-wide leading-normal">
    Um die Geschäftstätigkeiten unserer Unternehmensgruppe zu erleichtern,
    sofern unser dahingehendes berechtigtes Interesse gegenüber Ihren Rechten
    und Freiheiten im konkreten Fall überwiegt.
  </li>
</ul>
  </Section>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-11">Sicherheit von Kindern</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Die Sicherheit und der Schutz von Kindern bei der Nutzung des Internets und
    der Online-Dienste ist uns wichtig. Die Plattform und die
    Registrierungswebseite sind nur für Personen bestimmt, die mindestens 18
    Jahre alt sind. Mit Ihrer Nutzung dieser Dienste bestätigen Sie, dass Sie
    diese Anforderungen erfüllen. Wenn wir durch eine Benachrichtigung an&nbsp;
     <a className="underline font-bold text-black-theme"  href="mailto:privacy@oncodemia.com">privacy@oncodemia.com</a>&nbsp;oder
    an&nbsp; <a className="underline font-bold text-black-theme"  href="mailto:privacy@unifyhealth.app">privacy@unifyhealth.app</a>
    &nbsp;feststellen, dass die von uns erhobenen Informationen von einem
    Minderjährigen (unter 18 Jahren) stammen, werden wir Maßnahmen ergreifen, um
    das Konto zu schließen.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-6">Wie wir Ihre Daten schützen</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Alle Informationen, die Sie uns zur Verfügung stellen, werden auf sicheren
    Servern gespeichert. Wenn Sie ein Passwort gewählt haben, das Ihnen den
    Zugriff auf bestimmte Teile der Plattform ermöglicht, sind Sie dafür
    verantwortlich, dieses Passwort vertraulich zu behandeln. Wir bitten Sie,
    niemandem das Passwort mitzuteilen.
    <br />
    <br />
    Leider ist die Übermittlung von Daten über das Internet nicht vollständig
    sicher. Wir werden unser Bestes tun, um Ihre personenbezogenen Daten zu
    schützen, einschließlich der Verschlüsselung von Daten während der
    Übertragung und der Verschlüsselung im Ruhezustand für Nicht-Live-Systeme
    (einschließlich Backups). Sobald wir Ihre Daten erhalten haben, werden wir
    strenge Verfahren und Sicherheitsfunktionen anwenden, um unbefugten Zugriff
    möglichst zu verhindern.
    <br />
    <br />
    Unsere Plattform und die Registrierungswebseite können von Zeit zu Zeit
    Links zu externen Websites enthalten. Wir sind nicht verantwortlich für die
    Datenschutzerklärungen oder den Inhalt dieser Websites und übernehmen keine
    Verantwortung oder Haftung für diese Datenschutzerklärungen oder Ihre
    Nutzung dieser Websites.
    <br />
    <br />
    Im Rahmen der Plattform und der Registrierungswebseite verarbeiten wir keine
    personenbezogene Daten zum Zwecke der automatisierten Entscheidungsfindung,
    die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise
    erheblich beeinträchtigt.

  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-7">Wie lange wir Ihre Daten aufbewahren</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Personenbezogene Daten werden so lange gespeichert, wie dies zur Erfüllung
    des jeweiligen Verarbeitungszwecks erforderlich ist und danach gelöscht,
    soweit keine Rechtspflichten zur weitergehenden Aufbewahrung bestehen.
    <br />
    <br />
    Wir können auch aggregierte Daten über diesen Zeitraum hinaus zu
    Forschungszwecken und zur Entwicklung und Verbesserung unserer Produkte und
    Dienstleistungen aufbewahren. Sie können nicht anhand von aggregierten
    Informationen identifiziert werden, die für diese Zwecke aufbewahrt oder
    verwendet werden.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-8">Ihre Rechte</h1>

  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Das Datenschutzrecht gewährt Ihnen verschiedene Rechte in Bezug auf die Art
    und Weise, wie wir personenbezogene Daten von Ihnen verarbeiten:
  </p>


  <table className="table flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
  <tbody>
    <tr>
      <td className='align-top unify-table-cell-first'>
        A. Sie haben das Recht, Auskunft über Ihre personenbezogenen Daten zu
        verlangen
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht auf Auskunft zu Ihren personenbezogenen Daten,
        einschließlich das Recht eine Kopie der durch uns verarbeiteten
        personenbezogenen Daten zu verlangen.
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        B. Sie haben das Recht, die Berichtigung Ihrer personenbezogenen Daten
        durch uns zu verlangen
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, eine Berichtigung Ihrer personenbezogenen Daten zu
        verlangen, insbesondere wenn diese unrichtig oder unvollständig sind.
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        C. Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten durch
        uns zu verlangen
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu
        verlangen, soweit deren Nutzung datenschutzrechtlich unzulässig ist.
        Dieses Recht kommt insbesondere zum Tragen, wenn die Daten unvollständig
        oder unrichtig sind, wir personenbezogene Daten für die Zwecke für die
        sie erhoben wurden nicht mehr benötigen, wenn Sie Ihre Einwilligung zur
        Verarbeitung bestimmter Kategorien Ihrer personenbezogenen Daten
        widerrufen oder wenn Sie erfolgreich von Ihrem Widerspruchsrecht
        Gebrauch machen (siehe nachstehend Unterabschnitt F).
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        D. Sie haben das Recht, von uns zu verlangen, dass die Verarbeitung
        Ihrer personenbezogenen Daten eingeschränkt wird
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, von uns zu verlangen, dass die Verarbeitung der
        personenbezogenen Daten, die bei uns über Sie vorliegen, eingeschränkt
        wird. Dieses Recht kommt insbesondere dann zum Tragen, wenn die
        personenbezogenen Daten unrichtig sind, wenn die Verarbeitung Ihrer
        personenbezogenen Daten unrechtmäßig ist und sie keine Löschung dieser
        Daten verlangen möchten, wenn wir die personenbezogenen Daten für den
        Zweck, für den Sie erhoben wurden, nicht mehr benötigen, Sie die Daten
        jedoch für die Feststellung, Ausübung oder Abwehr von Rechtsansprüchen
        benötigen oder wenn Sie erfolgreich von Ihrem Widerspruchsrecht Gebrauch
        machen (siehe nachstehend Unterabschnitt F).
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>E. Sie haben das Recht auf Datenübertragbarkeit</td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, personenbezogene Daten, die Sie uns bereitgestellt
        haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
        erhalten, und dass diese Daten durch uns ohne Behinderung an einen
        anderen Verantwortlichen übermittelt werden, sofern die Verarbeitung auf
        Grundlage Ihrer Einwilligung oder deren vertraglicher Notwendigkeit
        beruht und mithilfe automatisierter Verfahren erfolgt.
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first font-weight-bold' style={{ fontWeight: 'bold'}}>
        F. Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten
        zu widersprechen
      </td>
      <td className='align-top unify-table-cell font-weight-bold' style={{ fontWeight: 'bold'}}>
        Sofern wir Ihre Daten auf Grundlage unserer berechtigten Interessen oder
        für Zwecke der Direktwerbung (auch im Rahmen von Profiling) verarbeiten,
        haben Sie das Recht dieser Verarbeitung jederzeit aus Gründen, die sich
        aus Ihrer besonderen Situation ergeben, zu widersprechen. Wir
        verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn,
        wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die ggü. Ihren Interessen, Rechten und Freiheiten überwiegen
        oder die Verarbeitung dient der Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen. <br />
        <br />
        Unsere Werbeaktualisierungen und -mitteilungen können Sie außerdem über
        den Link „Abmelden“ am Ende aller unserer Marketing- und
        Werbemitteilungen abbestellen oder indem Sie uns in Bezug auf die
        Plattform eine Mail senden an privacy@oncodemia.com oder in Bezug auf
        die Registrierungswebseite eine Mail senden an privacy@unifyhealth.app
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first unify-table-cell'>
        G. Sie haben das Recht, keiner automatisierten Entscheidungsfindung
        ausgesetzt zu sein
      </td>
      <td className='align-top unify-table-cell unify-table-cell'>
        Sie haben das Recht, nicht einer Entscheidung zu unterliegen, die
        ausschließlich auf einer automatisierten Verarbeitung, einschließlich
        Profiling, beruht, die Ihnen gegenüber rechtliche Wirkung entfaltet oder
        Sie in ähnlicher Weise erheblich beeinträchtigt (bitte beachten Sie,
        dass diese Plattform und die Registrierungswebseite keine
        personenbezogenen Daten verwendet, um solche automatisierten
        Entscheidungen zu treffen).
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first unify-table-cell'>H. Sie haben das Recht, Ihre Einwilligung zu widerrufen</td>
      <td className='align-top unify-table-cell unify-table-cell'>
        Wenn die Verarbeitung Ihrer personenbezogenen Daten durch uns auf einer
        Einwilligung beruht, haben Sie das Recht, diese Einwilligung jederzeit
        und ohne Nachteile zu widerrufen mit einer Mail an privacy@oncodemia.com
        (für Oncodemia) bzw. mit einer Mail an privacy@unifyhealth.app (für
        Unify Health Registrierungswebseite).
      </td>
    </tr>
  </tbody>
</table>

  {/*
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    A. Sie haben das Recht, Auskunft über Ihre personenbezogenen Daten zu
    verlangenSie haben das Recht auf Auskunft zu Ihren personenbezogenen Daten,
    einschließlich das Recht eine Kopie der durch uns verarbeiteten
    personenbezogenen Daten zu verlangen.
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    B. Sie haben das Recht, die Berichtigung Ihrer personenbezogenen Daten durch
    uns zu verlangenSie haben das Recht, eine Berichtigung Ihrer
    personenbezogenen Daten zu verlangen, insbesondere wenn diese unrichtig oder
    unvollständig sind.
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    C. Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten durch uns
    zu verlangenSie haben das Recht, die Löschung Ihrer personenbezogenen Daten
    zu verlangen, soweit deren Nutzung datenschutzrechtlich unzulässig ist.
    Dieses Recht kommt insbesondere zum Tragen, wenn die Daten unvollständig
    oder unrichtig sind, wir personenbezogene Daten für die Zwecke für die sie
    erhoben wurden nicht mehr benötigen, wenn Sie Ihre Einwilligung zur
    Verarbeitung bestimmter Kategorien Ihrer personenbezogenen Daten widerrufen
    oder wenn Sie erfolgreich von Ihrem Widerspruchsrecht Gebrauch machen (siehe
    nachstehend Unterabschnitt F).
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    D. Sie haben das Recht, von uns zu verlangen, dass die Verarbeitung Ihrer
    personenbezogenen Daten eingeschränkt wirdSie haben das Recht, von uns zu
    verlangen, dass die Verarbeitung der personenbezogenen Daten, die bei uns
    über Sie vorliegen, eingeschränkt wird. Dieses Recht kommt insbesondere dann
    zum Tragen, wenn die personenbezogenen Daten unrichtig sind, wenn die
    Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig ist und sie keine
    Löschung dieser Daten verlangen möchten, wenn wir die personenbezogenen
    Daten für den Zweck, für den Sie erhoben wurden, nicht mehr benötigen, Sie
    die Daten jedoch für die Feststellung, Ausübung oder Abwehr von
    Rechtsansprüchen benötigen oder wenn Sie erfolgreich von Ihrem
    Widerspruchsrecht Gebrauch machen (siehe nachstehend Unterabschnitt F).
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    E. Sie haben das Recht auf DatenübertragbarkeitSie haben das Recht,
    personenbezogene Daten, die Sie uns bereitgestellt haben, in einem
    strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und dass
    diese Daten durch uns ohne Behinderung an einen anderen Verantwortlichen
    übermittelt werden, sofern die Verarbeitung auf Grundlage Ihrer Einwilligung
    oder deren vertraglicher Notwendigkeit beruht und mithilfe automatisierter
    Verfahren erfolgt.
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    F. Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu
    widersprechenSofern wir Ihre Daten auf Grundlage unserer berechtigten
    Interessen oder für Zwecke der Direktwerbung (auch im Rahmen von Profiling)
    verarbeiten, haben Sie das Recht dieser Verarbeitung jederzeit aus Gründen,
    die sich aus Ihrer besonderen Situation ergeben, zu widersprechen. Wir
    verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn, wir
    können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
    ggü. Ihren Interessen, Rechten und Freiheiten überwiegen oder die
    Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
    Rechtsansprüchen.
    <br />
    <br />
    Unsere Werbeaktualisierungen und -mitteilungen können Sie außerdem über den
    Link „Abmelden“ am Ende aller unserer Marketing- und Werbemitteilungen
    abbestellen oder indem Sie uns in Bezug auf die Plattform eine Mail senden
    an&nbsp;privacy@oncodemia.com&nbsp;oder in Bezug auf die
    Registrierungswebseite eine Mail senden an&nbsp;privacy@unifyhealth.app
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    G. Sie haben das Recht, keiner automatisierten Entscheidungsfindung
    ausgesetzt zu seinSie haben das Recht, nicht einer Entscheidung zu
    unterliegen, die ausschließlich auf einer automatisierten Verarbeitung,
    einschließlich Profiling, beruht, die Ihnen gegenüber rechtliche Wirkung
    entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt (bitte
    beachten Sie, dass diese Plattform und die Registrierungswebseite keine
    personenbezogenen Daten verwendet, um solche automatisierten Entscheidungen
    zu treffen).
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    H. Sie haben das Recht, Ihre Einwilligung zu widerrufenWenn die Verarbeitung
    Ihrer personenbezogenen Daten durch uns auf einer Einwilligung beruht, haben
    Sie das Recht, diese Einwilligung jederzeit und ohne Nachteile zu widerrufen
    mit einer Mail an&nbsp;privacy@oncodemia.com&nbsp;(für Oncodemia) bzw. mit
    einer Mail an&nbsp;privacy@unifyhealth.app&nbsp;(für Unify Health
    Registrierungswebseite).
  </p> */}
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Wenn wir eine Anfrage zur Ausübung eines der oben genannten Rechte erhalten,
    werden wir normalerweise innerhalb eines Monats antworten. Diese Frist kann
    um bis zu zwei weitere Monate verlängert werden, wenn es sich um komplexe
    oder zahlreiche Anfragen handelt und eine solche Verlängerung nach dem
    Datenschutzrecht zulässig ist. In diesem Fall werden wir Sie innerhalb eines
    Monats über eine etwaige Verlängerung und den Grund dafür informieren. Sie
    können die oben aufgeführten Rechte jederzeit ausüben, indem Sie uns und
    unseren Ansprechpartner für Datenschutz unter&nbsp;
     <a className="underline font-bold text-black-theme"  href="mailto:privacy@oncodemia.com">privacy@oncodemia.com</a>&nbsp;(für
    Oncodemia) oder&nbsp;
     <a className="underline font-bold text-black-theme"  href="mailto:privacy@unifyhealth.app">privacy@unifyhealth.app</a>
    &nbsp;(für Unify Health Registrierungswebseite) kontaktieren. Sie haben das
    Recht, sich jederzeit an eine Datenschutzbehörde zu wenden, wenn Sie der
    Ansicht sind, dass Ihre Rechte infolge einer nicht rechtskonformen
    Datenverarbeitung verletzt wurden. Die Kontaktdaten der europäischen
    Datenschutzbehörden finden Sie unter folgendem Link:&nbsp;
     <a className="underline font-bold text-black-theme"  href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
      Datenschutzbehörden – Europäische Kommission (europa.eu).
    </a>
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-9">Änderungen an dieser Richtlinie</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Alle Änderungen, die wir in Zukunft an unserer Datenschutzerklärung
    vornehmen, werden auf dieser Seite veröffentlicht und Ihnen, falls es sich
    um wesentliche Änderungen handelt, per E-Mail mitgeteilt. Diese Richtlinie
    wurde zuletzt im März 2022 überprüft und aktualisiert.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"  id="section-10">Kontakt</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Fragen, Anmerkungen und Anfragen zu dieser Datenschutzerklärung sind
    willkommen und sollten an unseren europäischen Ansprechpartner für
    Datenschutz für Oncodemia bzw. für Unify Health gerichtet werden unter:
    <br />
    <br />
    Oncodemia-Plattform – E-Mail:&nbsp;
     <a className="underline font-bold text-black-theme"  href="mailto:privacy@oncodemia.com">privacy@oncodemia.com</a>
    <br />
    Registrierungswebseite – E-Mail:&nbsp;
     <a className="underline font-bold text-black-theme"  href="mailto:privacy@unifyhealth.app">privacy@unifyhealth.app</a>
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6"> Zweck und Rechtsgrundlage der Verarbeitung personenbezogener Daten</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >Diese Tabelle enthält Informationen dazu:</p>
  <ul className="md:pl-8 list-disc">
    <li className="text-black-theme tracking-wide leading-normal">Welche personenbezogenen Daten wir verarbeiten</li>
    <li className="text-black-theme tracking-wide leading-normal">Wofür wir diese Daten verarbeiten</li>
    <li className="text-black-theme tracking-wide leading-normal">Die Rechtsgrundlage für die Verarbeitung</li>
  </ul>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6  mb-6" >
    Sie ist Teil unserer Datenschutz- und Cookie-Richtlinie, die Sie ebenfalls
    lesen sollten.
  </p>

  <table className="table flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6">
  <tbody>
    <tr>
      <td className='align-top unify-table-cell-first'>
        A. Sie haben das Recht, Auskunft über Ihre personenbezogenen Daten zu
        verlangen
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht auf Auskunft zu Ihren personenbezogenen Daten,
        einschließlich das Recht eine Kopie der durch uns verarbeiteten
        personenbezogenen Daten zu verlangen.
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        B. Sie haben das Recht, die Berichtigung Ihrer personenbezogenen Daten
        durch uns zu verlangen
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, eine Berichtigung Ihrer personenbezogenen Daten zu
        verlangen, insbesondere wenn diese unrichtig oder unvollständig sind.
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        C. Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten durch
        uns zu verlangen
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu
        verlangen, soweit deren Nutzung datenschutzrechtlich unzulässig ist.
        Dieses Recht kommt insbesondere zum Tragen, wenn die Daten unvollständig
        oder unrichtig sind, wir personenbezogene Daten für die Zwecke für die
        sie erhoben wurden nicht mehr benötigen, wenn Sie Ihre Einwilligung zur
        Verarbeitung bestimmter Kategorien Ihrer personenbezogenen Daten
        widerrufen oder wenn Sie erfolgreich von Ihrem Widerspruchsrecht
        Gebrauch machen (siehe nachstehend Unterabschnitt F).
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        D. Sie haben das Recht, von uns zu verlangen, dass die Verarbeitung
        Ihrer personenbezogenen Daten eingeschränkt wird
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, von uns zu verlangen, dass die Verarbeitung der
        personenbezogenen Daten, die bei uns über Sie vorliegen, eingeschränkt
        wird. Dieses Recht kommt insbesondere dann zum Tragen, wenn die
        personenbezogenen Daten unrichtig sind, wenn die Verarbeitung Ihrer
        personenbezogenen Daten unrechtmäßig ist und sie keine Löschung dieser
        Daten verlangen möchten, wenn wir die personenbezogenen Daten für den
        Zweck, für den Sie erhoben wurden, nicht mehr benötigen, Sie die Daten
        jedoch für die Feststellung, Ausübung oder Abwehr von Rechtsansprüchen
        benötigen oder wenn Sie erfolgreich von Ihrem Widerspruchsrecht Gebrauch
        machen (siehe nachstehend Unterabschnitt F).
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>E. Sie haben das Recht auf Datenübertragbarkeit</td>
      <td className='align-top unify-table-cell unify-table-cell'>
        Sie haben das Recht, personenbezogene Daten, die Sie uns bereitgestellt
        haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
        erhalten, und dass diese Daten durch uns ohne Behinderung an einen
        anderen Verantwortlichen übermittelt werden, sofern die Verarbeitung auf
        Grundlage Ihrer Einwilligung oder deren vertraglicher Notwendigkeit
        beruht und mithilfe automatisierter Verfahren erfolgt.
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first font-weight-bold' style={{ fontWeight: 'bold'}}>
        F. Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten
        zu widersprechen
      </td>
      <td className='align-top unify-table-cell font-weight-bold' style={{ fontWeight: 'bold'}}>
        Sofern wir Ihre Daten auf Grundlage unserer berechtigten Interessen oder
        für Zwecke der Direktwerbung (auch im Rahmen von Profiling) verarbeiten,
        haben Sie das Recht dieser Verarbeitung jederzeit aus Gründen, die sich
        aus Ihrer besonderen Situation ergeben, zu widersprechen. Wir
        verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn,
        wir können zwingende schutzwürdige Gründe für die Verarbeitung
        nachweisen, die ggü. Ihren Interessen, Rechten und Freiheiten überwiegen
        oder die Verarbeitung dient der Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen. <br />
        <br />
        Unsere Werbeaktualisierungen und -mitteilungen können Sie außerdem über
        den Link „Abmelden“ am Ende aller unserer Marketing- und
        Werbemitteilungen abbestellen oder indem Sie uns in Bezug auf die
        Plattform eine Mail senden an privacy@oncodemia.com oder in Bezug auf
        die Registrierungswebseite eine Mail senden an privacy@unifyhealth.app
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>
        G. Sie haben das Recht, keiner automatisierten Entscheidungsfindung
        ausgesetzt zu sein
      </td>
      <td className='align-top unify-table-cell'>
        Sie haben das Recht, nicht einer Entscheidung zu unterliegen, die
        ausschließlich auf einer automatisierten Verarbeitung, einschließlich
        Profiling, beruht, die Ihnen gegenüber rechtliche Wirkung entfaltet oder
        Sie in ähnlicher Weise erheblich beeinträchtigt (bitte beachten Sie,
        dass diese Plattform und die Registrierungswebseite keine
        personenbezogenen Daten verwendet, um solche automatisierten
        Entscheidungen zu treffen).
      </td>
    </tr>
    <tr>
      <td className='align-top unify-table-cell-first'>H. Sie haben das Recht, Ihre Einwilligung zu widerrufen</td>
      <td className='align-top unify-table-cell'>
        Wenn die Verarbeitung Ihrer personenbezogenen Daten durch uns auf einer
        Einwilligung beruht, haben Sie das Recht, diese Einwilligung jederzeit
        und ohne Nachteile zu widerrufen mit einer Mail an privacy@oncodemia.com
        (für Oncodemia) bzw. mit einer Mail an privacy@unifyhealth.app (für
        Unify Health Registrierungswebseite).
      </td>
    </tr>
  </tbody>
</table>


{/*   
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Zweck/TätigkeitArt der DatenRechtsgrundlage für die Verarbeitung
    einschließlich berechtigter Interessen
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Um unsere vertragliche Beziehung zu Ihnen zu verwalten, die Folgendes
    umfasst: (a) Benachrichtigung über Änderungen unserer Geschäftsbedingungen
    oder Datenschutzerklärung (b) Bitten an Sie, eine Bewertung abzugeben oder
    an einer Umfrage teilzunehmen (c) Beantwortung Ihrer Fragen/Anfragen(a)
    Identität (b) Kontakt (c) Berufsprofil (d) Marketing und Kommunikation(a)
    Erfüllung eines Vertrags mit Ihnen, Art. 6 Abs. 1 S. 1 lit. b) DSGVO bzw.
    bzw. Art. 6 Abs. 1 S. 1 lit. b) UK-GDPR (b) Erforderlich, um einer
    rechtlichen Verpflichtung nachzukommen, Art. 6 Abs. 1 S. 1 lit. c) DSGVO
    bzw. Art. 6 Abs. 1 S. 1 lit. c) UK-GDPR (c) Erforderlich für unsere
    berechtigten Interessen (um unsere Aufzeichnungen auf dem neuesten Stand zu
    halten und zu ermitteln, wie Apotheker unsere Dienstleistungen nutzen), Art.
    6 Abs. 1 S. 1 lit. f) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. f) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Bereitstellung von Schulungen und Ressourcen sowie ggf. Ausgabe von
    CPD-Punkten (falls zutreffend) und Zertifikat nach Abschluss der Schulung
    auf der Plattform(a) Identität (b) Zertifizierung (c) Berufsprofil(a)
    Erfüllung eines Vertrags mit Ihnen, Art. 6 Abs. 1 S. 1 lit. b) DSGVO bzw.
    Art. 6 Abs. 1 S. 1 lit. b) UK-GDPR (b) Erforderlich für unsere berechtigten
    Interessen (um unsere Aufzeichnungen auf dem neuesten Stand zu halten), Art.
    6 Abs. 1 S. 1 lit. f) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. f) UK- GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Verwaltung Ihrer Registrierung sowie Ihres Standortes, damit Sie
    Krebspatienten in Ihrer Nähe angezeigt werden können(a) Identität (b)
    Kontakt (c) Berufsprofil (d) StandortdatenErforderlich für unsere
    berechtigten Interessen (Ziel ist es, nahversorgende Apotheken dazu zu
    befähigen, Krebspatienten zusätzliche, informierte Unterstützung und
    Ressourcen zur Verfügung zu stellen), Art. 6 Abs. 1 S. 1 lit. f) DSGVO bzw.
    Art. 6 Abs. 1 S. 1 lit. f) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Damit Sie an einer Werbeaktion oder einer Umfrage teilnehmen können(a)
    Identität (b) Kontakt (c) Berufsprofil (d) Nutzung (e) Marketing und
    KommunikationErforderlich für unsere berechtigten Interessen (um zu
    ermitteln, wie Kunden unsere Produkte/Dienstleistungen nutzen), Art. 6 Abs.
    1 S. 1 lit. f) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. f) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Verwaltung und Gewährleistung des Schutzes unseres Geschäfts und der
    Plattform und Registrierungswebseite (einschließlich Fehlerbehebung,
    Datenanalyse, Tests, Systemwartung, Support, Reporting und Hosting von
    Daten)(a) Identität (b) Kontakt (c) Technisch(a) Erforderlich für unsere
    berechtigten Interessen (für die Führung unseres Geschäfts, die
    Bereitstellung von Verwaltungs- und IT-Dienstleistungen, die
    Netzwerksicherheit, die Verhinderung von Betrug und im Rahmen einer
    Unternehmens- oder Konzernumstrukturierung), Art. 6 Abs. 1 S. 1 lit. f)
    DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. f) UK-GDPR (b) Erforderlich zur Erfüllung
    einer rechtlichen Verpflichtung, Art. 6 Abs. 1 S. 1 lit. c) DSGVO bzw. Art.
    6 Abs. 1 S. 1 lit. c) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Um Ihnen die Teilnahme an interaktiven Funktionen unseres Dienstes zu
    ermöglichen, wenn Sie dies wünschen.a) Identität (b) Kontakt (c) Profil (d)
    Nutzung(a) Erfüllung eines Vertrages mit Ihnen, Art. 6 Abs. 1 S. 1 lit. b)
    DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. b) UK-GDPR (b) Erforderlich für unsere
    berechtigten Interessen (zur Bereitstellung interaktiver Funktionen), Art. 6
    Abs. 1 S. 1 lit. f) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. f) UK-GDPR (c) Ihre
    Einwilligung, Art. 6 Abs. 1 S. 1 lit. a) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit.
    a) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Um Ihnen relevante Online-Inhalte und Anzeigen der Plattform bereitzustellen
    und die Effektivität der Werbung zu messen oder zu verstehen, die wir Ihnen
    anbieten(a) Identität (b) Kontakt (c) Profil (d) Nutzung (e) Marketing und
    Kommunikation (f) TechnischIhre Einwilligung, Art. 6 Abs. 1 S. 1 lit. a)
    DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. a) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Nutzung von Datenanalysen zur Verbesserung unserer Produkte/Dienstleistungen
    auf der Plattform, Marketing, Kundenbeziehungen und Kundenerlebnisse(a)
    Technisch (b) NutzungIhre Einwilligung, Art. 6 Abs. 1 S. 1 lit. a) DSGVO
    bzw. Art. 6 Abs. 1 S. 1 lit. a) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Um Ihnen Vorschläge und Empfehlungen zu Waren oder Dienstleistungen zu
    machen, die für Sie von Interesse sein könnten(a) Identität (b) Kontakt (c)
    Technisch (d) Nutzung (e) BerufsprofilIhre Einwilligung, Art. 6 Abs. 1 S. 1
    lit. a) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. a) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    De-Identifizierung von Daten, um auf aggregierter Ebene
    Forschungsschwerpunkte zu identifizieren und unsere Dienstleistungen zu
    verbessern.IdentitätErforderlich für unsere berechtigten Interessen (um
    unsere Produkte/Dienstleistungen zu entwickeln und zu verbessern, Art. 6
    Abs. 1 S. 1 lit. f) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. f) UK-GDPR
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6" >
    Übermittlung an Behörden(a) Identität (b) Kontakt (c) Berufsprofil (d)
    StandortdatenErforderlich, um einer rechtlichen Verpflichtung nachzukommen,
    Art. 6 Abs. 1 S. 1 lit. c) DSGVO bzw. Art. 6 Abs. 1 S. 1 lit. c) UK-GDPR
  </p> */}


      
      <p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Zurück"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
    </div>
  )
}


export default PrivacyPolicy;
