export const [ COUNTRY, LANGUAGE, SITE ] = (() => {
    switch(window.location.hostname) {
        case 'registrati.unifyhealth.app':
            return ['it','it','default'];
        case 'registrati-stage.unifyhealth.app':
            if ((window.location.pathname).indexOf('areamanager') !== -1) {
                return ['it','it','areamanager'];
            } else if ((window.location.pathname).indexOf('rep') !== -1) {
                return ['it','it','rep'];
            } else {
                return ['it','it','default'];
            }
        case 'apply.unifyhealth.app':
            return ['uk','en','default'];
        case 'apply-dev-it.unifyhealth.app':
            if ((window.location.pathname).indexOf('areamanager') !== -1) {
                return ['it','it','areamanager'];
            } else if ((window.location.pathname).indexOf('rep') !== -1) {
                return ['it','it','rep'];
            } else {
                return ['it','it','default'];
            }
        case 'apply-dev.unifyhealth.app':
            return ['uk','en','default'];
        case 'apply-stage.unifyhealth.app':
            return ['uk','en','default'];
        case 'registrierung.unifyhealth.app':
            return ['de','de','default'];
        case 'registrierung-stage.unifyhealth.app':
            return ['de','de','default'];
        case 'apply-dev-de.unifyhealth.app':
            return ['de','de','default'];
        default:
            //TODO: revert
            if ((window.location.pathname).indexOf('areamanager') !== -1) {
                return ['it','it','areamanager'];
            } else if ((window.location.pathname).indexOf('rep') !== -1) {
                return ['it','it','rep'];
            } else {
                return ['uk','en','default'];
            }
            //return ['it','it','default'];
      }
    })();
