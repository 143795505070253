import clsx from "clsx";
import React from "react";

/**
 * @typedef {object} Props
 * @prop {(() => void)=} onClick
 * @prop {Array<Object>} steps
 * @prop {number} activeStep
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement}
 */
const index = ({ steps, activeStep }) => {
  return (
    <div className="flex flex-row justify-between mt-4 mb-10">
      {steps.map((data, index) => (
        <div key={'step-' + index} className={
            clsx(
                'flex flex-1 py-1 rounded-full',
                (index + 1 !== steps.length) && ' mr-3',
                (activeStep < index + 1) ? 'bg-gray-100' : 'bg-yellow-theme',
            )
        }></div>
      ))}
    </div>
  );
};

export default index;
