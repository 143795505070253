import React, { useState } from 'react';
import {GoogleMap, Marker, InfoWindow} from "@react-google-maps/api";
import MapSearch from "./MapSearch";
import { CHECKPHARMACY_ENDPOINT } from '../../constants';
import { COUNTRY } from '../../screens/Form/constant';

let defaultCenter = {
  lat: 53.408371,
  lng: -2.991573,
};

if (COUNTRY === 'it') {
  defaultCenter = {
    lat: 41.902584,
    lng: 12.498445,
  };
}

if (COUNTRY === 'de') {
  defaultCenter = {
    lat: 52.577355,
    lng: 13.369690,
  };
}

const Map = ({onAddressSelect, className = '', isPlaceId, lat, lng, address, place_id }) => {
  const [center, setCenter] = useState(defaultCenter)
  const [marker, setMarker] = useState(null)
  const addressSelect = async(data) => {
    setMarker(data);
    const isPharmacyExists = await checkPharmacy(data.place_id);
    if(isPharmacyExists){
      onAddressSelect({
        address: isPharmacyExists.address,
        place_id: isPharmacyExists.googleplacesid,
        lat: isPharmacyExists.lat,
        lng: isPharmacyExists.lon,
        services: isPharmacyExists.services,
        whereYouWork : isPharmacyExists.whereYouWork,
      }, true);
    }else{
      onAddressSelect(data);
    }
  }

  const checkPharmacy = async(placeId) =>{
    try {
      const response = await fetch(CHECKPHARMACY_ENDPOINT,{
        method:'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({placeId})
      })
      const result = await response.json();
      if(response.ok && (result && ((result.message === 'found') && result.data))){
        return result.data;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  return (
    <div className={className}>
      <MapSearch setCenter={setCenter} onAddressSelect={addressSelect} setMarker={setMarker} isPlaceId={isPlaceId} lat={lat} lng={lng} address={address} place_id={place_id} />
      <GoogleMap
        zoom={18}
        id={'alma'}
        mapContainerClassName="w-full h-96 my-8"
        center={center}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
      {marker &&
        <>
          <Marker
            visible={true}
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}

          />
          <InfoWindow visible={true}
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
          ><div>PlaceID {marker.place_id}</div></InfoWindow>
        </>
      }
      </GoogleMap>
    </div>
  );
};

export default Map;

