import React from 'react';
import UnButton from "../../components/UnButton";
import {useHistory} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const PrivacyPolicy = () => {
  const {goBack} = useHistory();
  return (
    <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
      <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Privacy and Cookies Policy</h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">This is the Privacy and Cookies Policy for the Oncodemia Platform at &nbsp;<a className="underline font-bold text-blue-theme" href={"https://www.oncodemia.com"} target={"_blank"} rel={"noreferrer"}>www.oncodemia.com</a> (the "Platform") and the separate Community Cancer Champion web application and charter at &nbsp;<a className="underline font-bold text-blue-theme" href={"https://apply.unifyhealth.app"} target={"_blank"} rel={"noreferrer"}>https://apply.unifyhealth.app</a> (the "Application Site"). The Platform and Application Site are operated by or on behalf of Accord Healthcare Limited ('we', 'us' or 'our').</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">This policy sets out how we collect and use your personal data in connection with operation of the Platform. This policy covers:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#InformationCollectAboutYou">Information we collect about you and how we use it</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#CookiesAndOtherTech">Cookies and other technologies</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#PromotionalUpdate">Promotional updates and communications</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#WhoWeGiveInfo">Who we give your information to</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#WhereWeStoreInfo">Where we store and transfer your information</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#ChildSafety">Child safety</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#HowWeProtectInfo">How we protect your information</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#HowLongWeKeepInfo">How long we keep your information</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#YourRights">Your rights</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#ChangesToPolicy">Changes to this policy</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#ContactUs">Contact us</HashLink></li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We are committed to protecting and respecting your privacy. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">By accepting our services and using the Platform and Application Site you acknowledge you have read and understood this privacy policy.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">For the purposes of United Kingdom and European Economic Area data protection applicable laws, (the "Data Protection Law"), the data controller is: Accord Healthcare Limited of Sage House, 319 Pinner Road, North Harrow, Middlesex, HA1 4HF.</p>
      <h1 id="InformationCollectAboutYou" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Information we collect about you and how we use it
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We will collect and process the following personal data from you:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Information you give us</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">This is information about you that you give us directly when you interact with us by filling in forms on the Platform or Application Site or by corresponding with us by phone, e-mail or otherwise. It includes information to:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Register to use the Platform.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Complete the Application and sign the Charter Commitments in order to be designated a Community Cancer Champion (the "Application") for presentation within the pharmacy locator in the separate Unify Health App</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Participate in a Platform promotion or survey, submit a query, or if you report a problem with the Platform or Application Site</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">The information you give us may include:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Identity information your name, username and chosen password;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Contact information your e-mail address, city/town and country of residence</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">This is so we can create your account and identify you when you log in to the Platform or verify your Application against your existing Oncodemia account.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Professional Profile information: information relevant to your Application when you complete this and which includes:<br/>Your country of employment, job role, professional registration number(s), whether you are a locum pharmacist or not, other qualifications, work location, pharmacy name, address, website, a profile picture, (if you choose to share one) and a short statement on areas of expertise for you or your pharmacy to help cancer patients on the separate Unify Health App to refine their search.<br/>Your completed profile from your Application helps cancer patients using the separate Unify Health Application to more easily locate you.</li>
        </ul>
      </p>
      <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Information we collect about you from your use the Platform</h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">We will automatically collect information from you each time you visit the Platform or Application Site. This includes:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Technical information - associated with your device and browser.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Session information - from your interaction with the platform.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Certification information - about your engagement with the Oncodemia Platform course content and Application certification status.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Location data</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Technical information will include the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Session information about your visit may include the full Uniform Resource Locators (URL), clickstream to, through and from our Platform (including date and time), items that you viewed or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, and any phone number used or social media handle used to connect with our support team.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Certification information including the modules taken and completed, the answers you give to "knowledge check" tests case studies and / or exams within the Platform, Platform learning resources consulted , any notes you make on the training and information relating to Common Professional Development (CPD) you may have undertaken.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Location information: relevant to identifying the location from which you are accessing the Platform in order that we can take steps to reflect Platform content relevant to applicable local regulatory requirements and to understand at an aggregate level the locations of users accessing our services.</li>
        </ul>
      </p>
      <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Information we receive from other sources.</h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">This is information we receive about you or your pharmacy, in particular:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Professional profile information – about your pharmacy to supplement your Application profile.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">If you or your pharmacy uses any of the other websites or apps we operate or the other services we provide.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">From third parties we work closely with.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4 break-all">Professional profile information your Application will be supplemented with further information from your Pharmacy's Google profile, such as opening times, exterior photos, number of pharmacists in the pharmacy. You should check this information is up to date at <a className="underline font-bold text-blue-theme" href={"https://business.google.com/create"} target={"_blank"} rel={"noreferrer"}>https://business.google.com/create.</a></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">If you use any other of our sites and services, the strength of your relationship with us may reflect in how your profile is ranked within the pharmacy locator available to users of the separate Unify Health App.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">We are working closely with third parties (including, for example, business partners, sub-contractors in technical, services, analytics providers, and search information providers). We will notify you when we receive information about you from them and the purposes for which we intend to use that information.</li>
         </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You can see a full list of the types of data we process, the purpose for which we process it and the lawful basis on which it is processed at the end of this policy.</p>
      <h1 id="CookiesAndOtherTech" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Cookies and other technologies
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform uses cookies and/or other similar technologies to collect and store certain information. These typically involve pieces of information or code that a website transfers to or accesses from your computer hard drive or mobile device to store and sometimes track information about you. Cookies and similar technologies enable you to be remembered when using that computer or device to interact with websites and online services and can be used to manage a range of features and content as well as storing searches and presenting personalised content.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">The Platform uses cookies and similar technologies to distinguish you from other users of the Platform. This helps us to provide you with a good experience when you browse our site and also allows us to improve the Platform.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root">Most web browsers automatically accept cookies and similar technologies, but if you prefer, you can change your browser to prevent that and your help screen or manual will tell you how to do this. We also give you <HashLink className="underline font-bold text-blue-theme" smooth to="#DisableCookies">information about how to disable cookies.</HashLink> However, you may not be able to take full advantage of our website if you do so.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">A number of cookies and similar technologies we use last only for the duration of your web session and expire when you close your browser. Others are used to remember you when you return to the Platform and will last for longer.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">We use these cookies and other technologies on the basis that they are necessary for the performance of a contract with you, or because using them is in our legitimate interests (where we have considered that these are not overridden by your rights), and, in some cases, where required by law, where you have consented to their use.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">We use the following types of cookies:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Strictly necessary cookies. These are cookies that are required for the operation of our platform session and under our terms with you. They include, for example, cookies that enable you to navigate and log into secure areas of our Platform.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Functional cookies – you can choose to enable a cookie so that the platform remembers your username when you next sign in.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
        </ul>
      </p>
      <p id="DisableCookies" className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Disabling cookies</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The effect of disabling cookies depends on which cookies you disable but, in general, the Platform may not operate properly if all cookies are switched off.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">If you want to disable cookies on our site, you need to change your website browser settings to reject cookies. How you can do this will depend on the browser you use.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Further details on how to disable cookies for the most popular browsers are set out below:-</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">For Microsoft Internet Explorer:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-decimal">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Choose the menu “tools” then “Internet Options”</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Click on the “privacy” tab</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Select the setting the appropriate setting</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">For Google Chrome:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-decimal">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Choose Settings then Advanced</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Under "Privacy and security," click “Content settings”.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Click “Cookies”</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">For Safari:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-decimal">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Choose Preferences then Privacy</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Click on “Remove all Website Data”</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">For Mozilla firefox:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-decimal">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Choose the menu “tools” then “Options”</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Click on the icon “privacy”</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Find the menu “cookie” and select the relevant options</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">For Opera 6.0 and further:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-decimal">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Choose the menu Files then “Preferences”</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Privacy</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Where you have not set your permissions, we may also separately prompt you regarding our use of cookies on the Platform.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You can find more information about the individual cookies we use and the purposes for which we use them below.</p>
      <table className="hidden md:block md:flex-wrap table-fixed mb-6 w-full border-separate">
        <thead>
          <tr>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-center md:p-2">Cookie</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-center md:p-2">Name</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-center md:p-2">Purpose and lawful basis</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-center md:p-2">More information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">Session cookie</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">TotaraSession MoodleSessionXXX</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">This cookie is essential in our legitimate interests for the operation of our Platform. It remembers you as the same user as you navigate the platform.</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2"></td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">User id cookie</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">Totara MOODLEID1_XXX</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">If selected by the user and on the basis of their consent, this cookie enables the user's username to be remembered at sign-in.</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2"></td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">Universal Analytics</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">Google _ga _ gali _ gat _gid</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2">These cookies collect, with your consent, information about how visitors use our platform. We use information to compile reports to understand how users interact with the platform and so we can make improvements. The cookies collect information in a way that does not directly identify anyone, including the number of visitors to the platform, where visitors have come to the platform from and the pages that are visited.</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal md:p-2 break-all">See Google's Safeguarding your data overview <a className="underline font-bold text-blue-theme" href={"https://support.google.com/analytics/answer/6004245"} target={"_blank"} rel={"noreferrer"}>https://support.google.com/analytics/answer/6004245</a> opt-out <a className="underline font-bold text-blue-theme" href={"https://support.google.com/analytics/answer/181881?"} target={"_blank"} rel={"noreferrer"}>https://support.google.com/analytics/answer/181881?</a></td>
          </tr>
        </tbody>
      </table>
      <div className="block md:hidden">
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Session cookie
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">TotaraSession MoodleSessionXXX</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">This cookie is essential in our legitimate interests for the operation of our Platform. It remembers you as the same user as you navigate the platform.</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          User id cookie
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Totara MOODLEID1_XXX</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">If selected by the user and on the basis of their consent, this cookie enables the user's username to be remembered at sign-in.</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          User id cookie
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Universal Analytics</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Google _ga _ gali _ gat _gid</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">These cookies collect, with your consent, information about how visitors use our platform. We use information to compile reports to understand how users interact with the platform and so we can make improvements. The cookies collect information in a way that does not directly identify anyone, including the number of visitors to the platform, where visitors have come to the platform from and the pages that are visited.</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 break-all">See Google's Safeguarding your data overview <a className="underline font-bold text-blue-theme" href={"https://support.google.com/analytics/answer/6004245"} target={"_blank"} rel={"noreferrer"}>https://support.google.com/analytics/answer/6004245</a> opt-out <a className="underline font-bold text-blue-theme" href={"https://support.google.com/analytics/answer/181881?"} target={"_blank"} rel={"noreferrer"}>https://support.google.com/analytics/answer/181881?</a></p>
      </div>
      <h1 id="PromotionalUpdate" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Our promotional updates and communications
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Where permitted in our legitimate interest or with your prior consent where required by law, we will use your personal information for marketing analysis and to provide you with promotional update communications by email. Please refer to the <HashLink className="underline font-bold text-blue-theme" smooth to="#YourRights">'Your rights'</HashLink> section of this policy if you wish to change your direct marketing and contact preferences.</p>
      <h1 id="WhoWeGiveInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Who we give your information to
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">The aim of the Platform is to provide training and resources related to helping patients with cancer care in the community.  The Application Site enables pharmacists who have completed an appropriate training programme such as on the Platform or other appropriate post-graduate training, to apply through the Application Site to make themselves available to cancer patients in their community by sharing professional and pharmacy details. The patients will access Google maps information for the location of the pharmacy that successful applicants  work at and associated information shared through the Application. We may share your information with:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Any member of our group, which means our subsidiaries, our ultimate parent company and its subsidiaries, who support our processing of personal data under this privacy policy. To see a full list of such companies please click here. If any of these parties are using your information for direct marketing purposes, we will only transfer the information to them for that purpose with your prior consent.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Selected third parties such as suppliers who provide us services for the proper rendering of the services we provide.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Public bodies and authorities (administrative or judicial) in cases where a legal obligation applies.</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Our selected third parties may include:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Organizations who process your personal data on our behalf and in accordance with our instructions and the Data Protection Law. This includes in supporting the services we offer through the Platform and Application Site in particular those providing website and data hosting services, providing fulfilment services, distributing any communications we send, supporting or updating marketing lists, facilitating feedback on our services and providing IT support services from time to time. These organizations (which may include third party suppliers, agents, sub-contractors and/or other companies in our group) will only use your information to the extent necessary to perform their support functions.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Advertisers. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users to explain our platform to potential partners.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Analytics and search engine providers that assist us in the improvement and optimization of our Platform and subject to the cookie section of this policy (this will not directly identify you as an individual).</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">We may disclose your personal information to third parties:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets subject to the terms of this privacy policy.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">If Accord Healthcare Limited or substantially all of its assets are acquired by a third party, in which case personal data held by it about Platform and Application Site users will be one of the transferred assets.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms of supply terms and other agreements with you; or to protect the rights, property, or safety of Accord Healthcare Limited our users, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and to prevent cybercrime.</li>
        </ul>
      </p>
      <h1 id="WhereWeStoreInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Where we store your information
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform, Application Site and associated data collected from you is hosted on servers in Europe. We may on occasion, also use service providers or collaborate with our group companies to whom personal data may be transferred to, and stored at, a destination outside the European Economic Area ("EEA") and that may not be subject to equivalent Data Protection Law.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Where your information is transferred outside the EEA, we will take steps to ensure that your data is subject to appropriate safeguards, such as relying on a recognised legal adequacy mechanism, and that it is treated securely and in accordance with this privacy policy.</p>
      <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto">We may transfer your personal information outside the EEA:</h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">In order to support the operation of the services provided through the Platform and the Application Site.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Where we are legally required to do so.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">In order to facilitate the operation of our group of businesses, where it is in our legitimate interests and we have concluded these are not overridden by your rights.</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Where we transfer your personal information to a jurisdiction not subject to a legal adequacy finding, we will take steps to ensure an appropriate legal adequacy safeguard is in place which usually means, use of specific approved contracts that rely upon standard contractual clauses (scc)s for the protection of personal data.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">For further information on transfers outside of the EEA and/or specific safeguard methods adopted, please contact <a className="underline font-bold text-blue-theme" href={"mailto:privacy@oncodemia.com"}>privacy@oncodemia.com.</a></p>
      <h1 id="ChildSafety" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Child safety
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Protecting the safety of children when they use the Internet and online services is important to us. The Platform and Application Site are intended for use only by persons who are at least 18 years of age. By using either, you confirm to us that you meet this requirement.  If we establish by way of a notification to <a className="underline font-bold text-blue-theme" href={"mailto:privacy@oncodemia.com"}>privacy@oncodemia.com</a> that any information we collect has been provided by a child under 18 we will take steps to close that account.</p>
      <h1 id="HowWeProtectInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        How we protect your information
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">All information you provide to us is stored on secure servers. Where you have chosen a password, which enables you to access certain parts of the Platform, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Unfortunately, the transmission of information via the internet is not completely secure. We will do our best to protect your personal data including encryption of data in transit and encryption at rest for non-live systems (including backups). Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Our Platform and the Application Site may, from time to time, contain links to third party external sites. We are not responsible for the privacy policies or the content of such sites and do not accept any responsibility or liability for those policies or your use of those sites.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform and Application Site do not process personal data for the purpose of automated decision making that has any legal effect or significantly affects you.</p>
      <h1 id="HowLongWeKeepInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        How long we keep your information
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We retain personal data for as long as you use the Platform and have an account with us or as necessary to administer your Application or for the term of your valid Community Cancer Champion designation and in each case, for period after this to anticipate and resolve any legal issues or claims.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We may also retain aggregate information beyond this time for research purposes and to help us develop and improve our services. You cannot be identified from aggregate information retained or used for these purposes.</p>
      <h1 id="YourRights" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Your rights
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">You have the right under certain circumstances:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">to be provided with a copy of your personal data held by us;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">to request the rectification or erasure of your personal data held by us;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">to request that we restrict the processing of your personal data (while we verify or investigate your concerns with this information, for example);</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">to object to the further processing of your personal data, or to object to marketing and any profiling relating to such marketing (as mentioned in 'Our promotional updates and communications' section by selecting the "unsubscribe" link at the end of all our marketing and promotional update communications to you, or by sending us an email In respect of the Platform to <a className="underline font-bold text-blue-theme" href={"mailto:privacy@oncodemia.com"}>privacy@oncodemia.com</a> or In respect of the Application Site to <a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app</a></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">to request that your provided personal data be moved to a third party;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you or that similarly significantly affect you (note, as stated above, the Platform and Application Site do not use personal data to make such automated decisions).</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Your right to withdraw consent:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Where the processing of your personal information by us is based on consent, you have the right to withdraw that consent without detriment at any time. You can also change your marketing preferences at any time as described in 'Our promotional updates and communications' section;</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Where we receive a request to exercise one of the above rights, we will normally respond within one month. This may be extended by up to two further months in cases where requests are complex or numerous and where such an extension is permitted by Data Protection Law. Where that is the case we will inform you within one month of any extension and the reason for this.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">You can also exercise the rights listed above at any time by contacting us at Platform - &nbsp;<a className="underline font-bold text-blue-theme" href={"mailto:privacy@oncodemia.com"}>privacy@oncodemia.com.</a>&nbsp; Application Site – &nbsp;<a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app</a></p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 break-all flow-root">If your request or concern is not satisfactorily resolved by us, you may approach your local data protection authority competent in your country. You will find the contact details by entering the following link: <a className="underline font-bold text-blue-theme" target={"_blank"} rel={"noreferrer"} href={"https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"}>Data Protection Authorities - European Commission (europa.eu).</a></p>

      <h1 id="ChangesToPolicy" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Changes to this policy
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Any changes we make to our privacy policy in future will be posted on this page and, in relation to substantive changes, will be notified to you by e-mail. This policy was last reviewed and updated in August 2021.</p>
      <h1 id="ContactUs" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Contact Us
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Questions, comments and requests regarding this privacy policy are welcomed and should be addressed for the attention of our European Representative to:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Oncodemia Platform - Email: &nbsp;<a className="underline font-bold text-blue-theme" href={"mailto:support@oncodemia.com"}>support@oncodemia.com</a></p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Application Site - Email: &nbsp;<a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app</a></p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Purpose and lawful basis for processing of personal data</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">This table sets out:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">What personal data we process</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">What we use that data for</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">The lawful basis for the processing</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">It forms part of our privacy policy which you should also read.</p>
      <table className="hidden md:block md:flex-wrap table-fixed mb-6 w-full border-separate">
        <thead>
          <tr>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-left">Purpose/Activity</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-left">Type of data</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-left">Lawful basis for processing including basis of legitimate interest</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To register you as a new user of the Platform and to administer your Application profile.</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Professional profile</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Performance of a contract with you</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To manage our relationship with you which will include: (a) Notifying you about changes to our terms or privacy policy (b) Asking you to leave a review or take a survey</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Professional profile (d) Marketing and Communications</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Performance of a contract with you (b) Necessary to comply with a legal obligation (c) Necessary for our legitimate interests (to keep our records updated and to study how pharmacists use our services)</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To provide you with training and resources and issue CPD points (where applicable) and certificate upon completion of the Platform training</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Certification (c) Professional profile</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Performance of a contract with you (b) Necessary for our legitimate interests (to keep our records updated)</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To administer your Application to make yourself available to cancer patients in your area</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Professional profile</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Necessary for our legitimate interests (being the aim of equipping community pharmacists to provide additional, informed support and resources to cancer patients</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To enable you to partake in a promotion or complete a survey</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Professional profile (d) Usage (e) Marketing and Communications</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Performance of a contract with you (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop the</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To administer and protect our business and the Platform and Application Site (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Technical</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise) (b) Necessary to comply with a legal obligation</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To allow you to participate in interactive features of our service, when you choose to do so.</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">a) Identity (b) Contact (c) Profile (d) Usage</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Necessary for our legitimate interests (to provide interactive features)</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To deliver relevant Platform online content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications (f) Technical</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Necessary for our legitimate interests (to study how pharmacists use our products/services, to develop them, to grow our business and to inform our marketing strategy).</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To use data analytics to improve our Platform products/services, marketing, customer relationships and experiences</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Technical (b) Usage</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Necessary for our legitimate interests (to define types of users for our products and services, to keep our Platform updated and relevant, to develop our business and to inform our marketing strategy)</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identity (b) Contact (c) Technical (d) Usage (e) Professional profile</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Necessary for our legitimate interests (to develop our products/services and grow our business)</td>
          </tr>
        </tbody>
      </table>
      <div className="block md:hidden">
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To register you as a new user of the Platform and to administer your Application profile.
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Professional profile</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Performance of a contract with you</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To manage our relationship with you which will include: (a) Notifying you about changes to our terms or privacy policy (b) Asking you to leave a review or take a survey
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Professional profile (d) Marketing and Communications</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Performance of a contract with you (b) Necessary to comply with a legal obligation (c) Necessary for our legitimate interests (to keep our records updated and to study how pharmacists use our services)</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To provide you with training and resources and issue CPD points (where applicable) and certificate upon completion of the Platform training
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Certification (c) Professional profilee</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Performance of a contract with you (b) Necessary for our legitimate interests (to keep our records updated)</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To administer your Application to make yourself available to cancer patients in your area
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Professional profile</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Necessary for our legitimate interests (being the aim of equipping community pharmacists to provide additional, informed support and resources to cancer patients</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To enable you to partake in a promotion or complete a survey
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Professional profile (d) Usage (e) Marketing and Communications</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Performance of a contract with you (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop the</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To administer and protect our business and the Platform and Application Site (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Technical</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise) (b) Necessary to comply with a legal obligation</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To allow you to participate in interactive features of our service, when you choose to do so.
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">a) Identity (b) Contact (c) Profile (d) Usage</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Necessary for our legitimate interests (to provide interactive features)</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To deliver relevant Platform online content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Profile (d) Usage (e) Marketing and Communications (f) Technical</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Necessary for our legitimate interests (to study how pharmacists use our products/services, to develop them, to grow our business and to inform our marketing strategy)</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To use data analytics to improve our Platform products/services, marketing, customer relationships and experiences
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Technical (b) Usage</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Necessary for our legitimate interests (to define types of users for our products and services, to keep our Platform updated and relevant, to develop our business and to inform our marketing strategy)</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          To make suggestions and recommendations to you about goods or services that may be of interest to you
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identity (b) Contact (c) Technical (d) Usage (e) Professional profile</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Necessary for our legitimate interests (to develop our products/services and grow our business)</p>

      </div>
      <p className={'flex flex-col mt-10'}>
          <UnButton
            onClick={goBack}
            title="Back"
            type={'submit'}
            fullWidth={false}
            position="right"
          />
        </p>
    </div>
  )
}

export default PrivacyPolicy;
