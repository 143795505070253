import React, { useEffect }  from "react";
import { Helmet } from "react-helmet";
import divider from "../assets/images/div.svg";
import dividerTop from "../assets/images/div2.svg";
import logo from "../assets/images/icon.svg";
import UnThankYou from "../components/UnThankYou";
import BecomeAMemberDE from "./BecaomeAMemberDE";
import { Route } from "react-router-dom";
import Form from "./Form";
import Footer from "../components/Footer";
import PharmacyDataLoader from "../components/PharmacyDataLoaderRedirect";
import PrivacyPolicyDE from "./PrivacyPolicyDE";
import TermsAndConditionsDE from "./TermsAndConditionsDE";
import ContactUsDE from "./ContactUsDE";


const De = () => {
  useEffect(() => {
    document.title = "Pharmacies Reg DE";
    document.description = "DE";
  }, []);
  return (
    <div className={'flex flex-col bg-blue-theme justify-center items-center min-h-screen h-full py-20'}>
      <Helmet>
        <title>Unify Health Registrierung</title>
        <meta name="description" content="DE" />
      </Helmet>

      <div className="hidden md:fixed bottom-0 left-1 md:left-16">
        <img src={divider} alt="" />
      </div>

      <div className="hidden md:fixed md:flex top-0 right-16">
        <img src={dividerTop} alt="" />
      </div>

      <img src={logo} alt="" className="mx-auto -mt-10 mb-10" />

      <Route exact path={'/'} component={BecomeAMemberDE} />
      <Route exact path={'/pharmacist/edit/:id'}><PharmacyDataLoader /></Route>
      <Route exact path={'/form/:step'} ><Form /></Route>
      <Route exact path={'/thank-you'}><UnThankYou /></Route>
      <Route exact path={'/datenschutz'}><PrivacyPolicyDE /></Route>
      <Route exact path={'/nutzungsbedingungen'}><TermsAndConditionsDE /></Route>
      <Route exact path={'/impressum'}><ContactUsDE /></Route>
      <Footer />
    </div>
  );
};

export default De;
