import React, { useState, useEffect } from 'react';
import UnInput from "../../../../src/components/UnInput";
import UnButton from "../../../../src/components/UnButton";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { stripHtml} from "../../../../src/screens/Form/utils";
import Loader from "../../../../src/components/Loader";
import { AREAMANAGER_ADD_REPS_ENDPOINT, AREAMANAGER_GET_REPS_ENDPOINT } from "../../../../src/constants";
import ErrorMessage from "../../../../src/screens/Form/ErrorMessage";
import { useTranslation } from 'react-i18next';
import { COUNTRY, LANGUAGE, SITE } from '../../../../src/screens/Form/constant';
import {useForm} from "react-hook-form";

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const createRep = async (data) => {

  let options = {};
  let method = 'POST'; 

  options = {
    method,
    headers: {
    'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }
 
  return fetch(AREAMANAGER_ADD_REPS_ENDPOINT, options)
    .then((resp) => {
      console.log("HERE add rep 1");
      if (resp.ok) {
        console.log("HERE add rep 2");
        return resp.json();
      } else {
        console.log("HERE add rep 3");
        throw resp.json();
      }
    })
}

const AddRep = () => {
  const params = useParams()
  const history = useHistory();
  const [data, setData] = useState({});
  const [repData, setRepData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { t } = useTranslation();

  const areaManagerId = params.areaManagerId ? (params.areaManagerId * 1) : null;
  
   const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: data
  });

  setValue('areaManagerId', areaManagerId);
  
  const goBack = () => {
    history.goBack()
  }  

  const submit = async function(formData = {}) {
    console.log('sub rep')
    const newData = {
      //...data,
      ...formData,
      appStoreCountry: COUNTRY,
      formLanguage: LANGUAGE
    }
   
    setRepData(newData)
  
    setIsLoading(true);

    newData.firstName = stripHtml(newData.firstName);
    newData.lastName = stripHtml(newData.lastName);
    //newData.email = newData.email;
    
    try {
        console.log('HERE rep submit 1')
        const result = await createRep(newData);
        setIsLoading(false);
        if (!result) {
            //setErrorMesage('ERROR 1: No results found 1');
            setHasError(true);
        } else {
            console.log('HERE 2a')
            if (result.status === 'failed') {
            console.log('HERE 2b')
            let message = (!result.message) ?  t('errorsGlobal.temporarilyUnavailableService') : result.message;
            if (message === 'errorEmailRegistered' || message === 'errorEmailRegistered2' ) {
                message =  t('step3.errorEmailIsAlreadyRegistered');
            } else if (message === 'errorRegisterantNumberRegistered' || message === 'errorRegisterantNumberRegistered2' ) {
                message =  t('step3.errorRegistrantIdIsAlreadyRegistered');
            } else {
                message =  t('errorsGlobal.temporarilyUnavailableService');
            }       
            setErrorMesage(message);
            setHasError(true);
            } else {
            console.log('HERE 2')
            if (SITE === 'areamanager') {
                history.push(`/areamanager/thank-you`);
            } else {
                history.push(`/thank-you`);
            }
            }
        }
    } catch (err) {
        console.log('rep submit fininshed',err)
        setIsLoading(false);
        //setErrorMesage('ERROR 2: No results found 2', err);
        setHasError(true);
    }
    
  };

  useEffect(() => {
    if (areaManagerId && !isDataLoaded) {
      setIsLoading(true);
      fetch(AREAMANAGER_GET_REPS_ENDPOINT.replace('##id##', areaManagerId))
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log('error - could not get pharmacy and pharmacist details from API')
            throw Error('Error happened at fetch');
          }
        })
        .then((json) => {
          setIsDataLoaded(true);
          setData(json.data || json);
        })
        .catch((err) => {setHasError(true); console.log('Was error',err)})
        .finally(() => {setIsLoading(false); });
    }
  }, [areaManagerId, isDataLoaded])

  const nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;

  return (
    <div className="flex flex-col py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
      <h1 className="text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        {t('registration.title')}
      </h1>
    
      {isLoading && (
        <div className={'flex mx-auto'}>
          <Loader />
        </div>
      )}
      {hasError && <ErrorMessage errorMessage={errorMessage} />}
      {(!hasError && !isLoading) &&
         <>
        <p className={'flex flex-col mt-10mt-10'}>
          <UnButton
            onClick={goBack}
            title="Back"
            type={'submit'}
            fullWidth={false}
            position="right"
          />
        </p>
         <form onSubmit={handleSubmit(submit)}>
         <input type={'hidden'} {...register('areaManagerId', { required: false })} />
        
         <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-12">
           * Obbligatorio
         </p>
             <UnInput
               hasError={!!errors.email}
               control={register('email', {
                 required: false,
                 pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
               })}
               label={t('step1.emailAddress')}
               placeholder={t('step1.emailPlaceHolder')}
             />
   
             <div className="flex flex-col md:flex-row justify-between">
               <UnInput
                 hasError={!!errors.firstName}
                 control={register('firstName', {required: false, pattern: nameRegex })}
                 fullWidth={false} label={t('step1.firstName')}
                 placeholder={t('step1.firstNamePlaceHolder')} />
               
               <UnInput
                 hasError={!!errors.lastName}
                 control={register("lastName", {required: false, pattern: nameRegex })}
                 fullWidth={false}
                 label={t('step1.lastName')}
                 placeholder={t('step1.lastNamePlaceHolder')}
                 styles="md:ml-4"
               />
             </div>
             <div className={'mt-10'}>
               <UnButton
               disabled={false}
               title={t('registration.submit')}
               type={'submit'}
               fullWidth={false}
               position="right"
               />
             </div>
             </form>
           </> 
        }
    </div>

  )
}
export default AddRep;

