/* eslint-disable no-useless-escape*/
import "@reach/combobox/styles.css";
import React, { useState, useEffect } from "react";
import UnInput from "../UnInput";
import UnDropDown from "../UnDropDown";
import { useLoadScript } from "@react-google-maps/api";
import {useForm} from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Map from "./Map";
import UnButton from "../UnButton";
import PlaceDetails from "./PlaceDetails";
import Loader from "../Loader";
import { AREAMANAGER_GET_REPS_ENDPOINT } from '../../../src/constants';
import ErrorMessage from "../../screens/Form/ErrorMessage";
import { COUNTRY, LANGUAGE, SITE } from "../../screens/Form/constant";
import { saveData, stripHtml } from "../../screens/Form/utils";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'


const libraries = ['places'];

const oncStatusList = [
  { label: 'Not Registered', value: 'Not Registered' },
  { label: 'Registered', value: 'Registered' },
  { label: 'On Going', value: 'On Going' },
  { label: 'Completed', value: 'Completed' }
];

const italianCities = [
  { id: 1, name: 'Rome' },
  { id: 2, name: 'Milan' },
  { id: 3, name: 'Naples' },
  { id: 4, name: 'Turin' },
  { id: 5, name: 'Palermo' },
  { id: 6, name: 'Genoa' },
  { id: 7, name: 'Bologna' },
  { id: 8, name: 'Florence' },
  { id: 9, name: 'Bari' },
  { id: 10, name: 'Catania' }
]

const italianRegionsAndProvinces = [
  { region: "Abruzzo", provinces: ["Teramo", "Pescara", "L'Aquila", "Chieti"] },
  { region: "Basilicata", provinces: ["Matera", "Potenza"] },
  { region: "Calabria", provinces: ["Catanzaro", "Cosenza", "Crotone", "Reggio Calabria", "Vibo Valentia"] },
  { region: "Campania", provinces: ["Avellino", "Benevento", "Caserta", "Napoli", "Salerno"] },
  { region: "Emilia-Romagna", provinces: ["Bologna", "Ferrara", "Forlì-Cesena", "Modena", "Parma", "Piacenza", "Ravenna", "Reggio Emilia", "Rimini"] },
  { region: "Friuli-Venezia Giulia", provinces: ["Gorizia", "Pordenone", "Trieste", "Udine"] },
  { region: "Lazio", provinces: ["Frosinone", "Latina", "Rieti", "Roma", "Viterbo"] },
  { region: "Liguria", provinces: ["Genova", "Savona", "Imperia", "La Spezia"] },
  { region: "Lombardia", provinces: ["Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Milano", "Monza e Brianza", "Pavia", "Sondrio", "Varese"] },
  { region: "Marche", provinces: ["Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro e Urbino"] },
  { region: "Molise", provinces: ["Campobasso", "Isernia"] },
  { region: "Piemonte", provinces: ["Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Torino", "Verbano-Cusio-Ossola", "Vercelli"] },
  { region: "Puglia", provinces: ["Bari", "Barletta-Andria-Trani", "Brindisi", "Foggia", "Lecce", "Taranto"] },
  { region: "Sardegna", provinces: ["Metropolitan City of Cagliari", "Nuoro", "Oristano", "Sassari", "Sud Sardegna"] },
  { region: "Sicilia", provinces: ["Agrigento", "Caltanisetta", "Catania", "Enna", "Messina", "Palermo", "Ragusa", "Siracusa", "Trapani"] },
  { region: "Toscana", provinces: ["Arezzo", "Firenze", "Grosseto", "Livorno", "Lucca", "Massa-Carrara", "Pisa", "Pistoia", "Prato", "Siena"] },
  { region: "Trentino-Alto Adige", provinces: ["Bolzano", "Trento"] },
  { region: "Umbria", provinces: ["Perugia", "Terni"] },
  { region: "Valle d'Aosta", provinces: ["Valle d'Aosta Region"] },
  { region: "Veneto", provinces: ["Belluno", "Padova", "Rovigo", "Treviso", "Venezia", "Verona", "Vicenza"] }
];

let REGIONS = [];
REGIONS = italianRegionsAndProvinces.map(item => {
  return {label:item.region,value:item.region};
})

const StepOneITAreaManager = () => {

  const [provinces, setProvinces] = useState([]);
  const [isDataLoading, setIsLoading] = useState(false);
  const [repData, setRepData] = useState([]);
  const [hasError, setHasError] = useState(null);
  const [placeId, setPlaceId] = useState('');
  const [pharmacyPopulated, SetPharmacyPopulated] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');
 
  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
    defaultValues: {}
  });

  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation();
  const placeIdInFormHook = getValues('placeId');
  const lat = getValues('lat');
  const lng = getValues('lng');
  const address = getValues('address');
  const place_id = getValues('placeId'); 
  const regionChange = watch('region');
  const areaManagerId = params.areaManagerId ? (params.areaManagerId * 1) : null;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    setValue('areaManagerId', areaManagerId);
  }, [areaManagerId])

  useEffect(() => {
    setPlaceId(placeIdInFormHook)
  }, [placeIdInFormHook])
  
  useEffect(() => {
    italianRegionsAndProvinces.map(data => {
      if(data.region === regionChange){
        const provinceData = data.provinces.map( province =>{
          return({
            label: province,
            value: province,
          })
        });
        setProvinces(provinceData);
      }
      return [];
    })
  }, [regionChange])

  useEffect(() => {
    getRepData();
    }, [])

  const getRepData = async() =>{
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await fetch(AREAMANAGER_GET_REPS_ENDPOINT.replace('##id##', areaManagerId));
      const result = await response.json();
      if(result?.data){
        let repDropDownData = result.data.map(rep => (rep = {label:(rep.firstName + ' ' + rep.lastName) ,value:rep.nid}));
        setRepData(repDropDownData);
      }
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  if (loadError) {
    return "Error loading maps";
  }
  if (!isLoaded) {
    return <div className={"flex justify-center"}><Loader /></div>;
  }

  const addressSelect = ({place_id, lat, lng, address, services, whereYouWork }, isPharmacyExists = false) => {
    setValue('placeId', place_id);
    setValue('lat', lat);
    setValue('lng', lng);
    setValue('address', address);
    setPlaceId(place_id);
    if(isPharmacyExists){
      SetPharmacyPopulated(true);
      setValue('whereYouWork', whereYouWork);
      setValue('services', services);
    }else if(pharmacyPopulated){
      SetPharmacyPopulated(false);
      setValue('whereYouWork', '');
      setValue('services', '');
    }
  }

  const resetPlace = () => {
    setPlaceId('');
    setValue('placeId', '');
  }
  
  const goBack = () => {
    history.goBack()
  }

  const onSubmit = async function(formData = {}) {

      const newData = {
        ...formData,
        appStoreCountry: COUNTRY,
        formLanguage: LANGUAGE,
      }
  
      setIsLoading(true);
      setHasError(false);
      newData.whereYouWork = stripHtml(newData.whereYouWork);
      newData.anythingElseAboutYou = stripHtml(newData.anythingElseAboutYou);
      newData.address = stripHtml(newData.address);
      newData.howDidYouFindAboutTheProgram = stripHtml(newData.howDidYouFindAboutTheProgram);
      newData.services = stripHtml(newData.services);

      // if(COUNTRY === 'uk' && LANGUAGE === 'en'){
      //   if(newData.haveYouCompleted === 'yes1'){
      //     newData.haveYouCompleted = 'yes';
      //     newData.additionalTrainingNotes = stripHtml(newData.additionalTrainingNotes);
      //   }else if(newData.haveYouCompleted === 'yes2'){
      //     newData.haveYouCompleted = 'yes';
      //     delete newData.additionalTrainingNotes;
      //   }else if(newData.haveYouCompleted === 'no'){
      //     delete newData.additionalTrainingNotes;
      //   }
      // }
      // if(nextPage === 'uk') {
      //   delete newData.bodyOfRegistration;
      //   delete newData.howDidYouFindAboutTheProgram;
      //   delete newData.completedAdditionalTraining;
      // }
      try {
        const result = await saveData(newData);
        setIsLoading(false);
        if (!result) {
          setHasError(true);
        } else {
          if (result.status === 'failed') {
            let message = (!result.message) ?  t('errorsGlobal.temporarilyUnavailableService') : result.message;
            if (message === 'errorEmailRegistered' || message === 'errorEmailRegistered2' ) {
              message =  t('step3.errorEmailIsAlreadyRegistered');
            } else if (message === 'errorRegisterantNumberRegistered' || message === 'errorRegisterantNumberRegistered2' ) {
              message =  t('step3.errorRegistrantIdIsAlreadyRegistered');
            } else {
              message =  t('errorsGlobal.temporarilyUnavailableService');
            }       
            setErrorMesage(message);
            setHasError(true);
          } else {
            history.push(`/areamanager/thank-you`);
          }
        }
      } catch (err) {
        console.log('HERE 3')
        setIsLoading(false);
        setHasError(true);
      }
  };

  const handleOnSelect = (item) => {
    setValue('city', item.name);
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left', color: 'black', fontFamily: "Arimo", fontSize:'14px' }}>{item.name}</span>
      </>
    )
  }
 
  return (
    <div className="flex flex-col py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">   

    { (isDataLoading)
        ? <div className={'flex mx-auto'}> <Loader /> </div>
        : hasError ? <div className={'flex mx-auto mt-10'}><ErrorMessage errorMessage={errorMessage} /> </div>
        :
        <>
        <p className={'flex flex-col mt-10'}>
          <UnButton
            onClick={goBack}
            title="Back"
            // type={'submit'}
            fullWidth={false}
            position="right"
          />
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          
          <input type={'hidden'} {...register('placeId', { required: true })} />
          <input type={'hidden'} {...register('lat', { required: true })} />
          <input type={'hidden'} {...register('lng', { required: true })} />
          <input type={'hidden'} {...register('address', { required: true })} />
          <input type={'hidden'} {...register('areaManagerId', { required: true })} />
          <input type={'hidden'} {...register('city', { required: true })} />

          <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-12">
            * Obbligatorio
          </p>

          <UnDropDown
            hasError={!!errors.salesRep}
            control={register('salesRep', {required: true})}
            label={t('areaManager.step1.salesRep.lable')}
            options={repData}
            fullWidth={false}
          />

          <UnDropDown
              hasError={!!errors.oncStatus}
              control={register('oncStatus', {required: false})}
              label={t('areaManager.step1.oncStatus.lable')}
              options={oncStatusList}
              fullWidth={false}
            />

          <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mt-12">
            Pharmacy Information:
          </p>

          <UnInput
            hasError={!!errors.whereYouWork}
            control={register('whereYouWork', {required: true})}
            label={t('step1.questionPharmacyName')}
            placeholder={t('step1.pharmacyNamePlaceHolder')}
            note={t('step1.pharmacyNameNote')}
          />

          <p className="font-bold text-lg mt-8 mb-4 text-blue-theme h">
            {t('step1.findPlaceId')}
          </p>
          {(!!errors.placeId && !placeId) && ( <div className={'pt-4 text-red-600'}> <p>{t('step1.required')}</p> </div> )}
          {placeId && <PlaceDetails data={getValues()} reset={resetPlace} />}
          {<Map onAddressSelect={addressSelect} isPlaceId={!!errors.placeId && !placeId} lat={lat} lng={lng} address={address} place_id={place_id} className="relative w-full" />}
          

          <div style={{ width: '100%', marginBottom:10 }}>
            <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mt-12 mb-6">
              {t('areaManager.step1.city.lable')}
            </p>
            <ReactSearchAutocomplete
              items={italianCities}
              onSelect={handleOnSelect}
              showIcon={false}
              styling={{
                height: "34px",
                border: "1px solid gray",
                borderRadius: "4px",
                backgroundColor: "white",
                boxShadow: "none",
                hoverBackgroundColor: "#fec209",
                color: "black",
                fontSize: "14px",
                fontFamily: "Arimo",
                iconColor: "black",
                lineColor: "lightgreen",
                placeholderColor: "gray",
                clearIconMargin: "3px 8px 0 0",
                zIndex: 2,
                fontWeight: 600
              }}
              formatResult={formatResult}
              placeholder={t('areaManager.step1.city.placeHolder')}
            />
            {!!errors.city && <p className="mt-2" style={{color:'#DC2626'}}>Please select city</p>}
          </div>

          <UnDropDown
            hasError={!!errors.region}
            control={register('region', {required: true})}
            label={t('areaManager.step1.region.lable')}
            options={REGIONS}
            fullWidth={false}
          /> 

          <UnDropDown
            hasError={!!errors.prov}
            control={register('prov', {required: true})}
            label={t('areaManager.step1.prov.lable')}
            options={provinces}
            fullWidth={false}
          />

          <div className={'flex flex-col mt-10'}>
            <UnButton
              title={t('registration.next')}
              type={'submit'}
              fullWidth={false}
              position="right"
            />
          </div>

        </form>
        </>
    }
    </div>
  );
};

export default StepOneITAreaManager;
