/* eslint-disable no-useless-escape*/
import "@reach/combobox/styles.css";
import React, { useEffect, useState }  from "react";
import { useLoadScript } from "@react-google-maps/api";
import {useForm} from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import {Link, useHistory } from "react-router-dom";
import UnTextArea from "../UnTextArea";
import UnInput from "../UnInput";
import UnDropDown from "../UnDropDown";
import { useQuery } from "../../screens/RepIT";
import ErrorMessage from "../../screens/Form/ErrorMessage";
import UnButton from "../UnButton";
import ImageUpload from "./ImageUpload";
import Loader from "../Loader";
import { COUNTRY, LANGUAGE, SITE } from "../../screens/Form/constant";
import { SALES_REP_GET_PHARMACY_ENDPOINT } from "../../constants";
import { saveData, stripHtml } from "../../screens/Form/utils";


const libraries = ['places'];

const oncStatusList = [
  { label: 'Not Registered', value: 'Not Registered' },
  { label: 'Registered', value: 'Registered' },
  { label: 'On Going', value: 'On Going' },
  { label: 'Completed', value: 'Completed' }
];

const call_flags = [
  { label: 'Appointment scheduled', value: 'appointment_scheduled' },
  { label: 'not interested', value: 'not_interested' },
  { label: 'interested but requires another visit', value: 'interested_but_requires_another_visit' },
  { label: 'Joined Unify', value: 'joined_unify' },
  { label: 'Post joining Unify', value: 'post_joining_unify' },
];

const months = [
{ label: 'January', value: 1 },
{ label: 'February', value: 2 },
{ label: 'March', value: 3 },
{ label: 'April', value: 4 },
{ label: 'May', value: 5 },
{ label: 'June', value: 6 },
{ label: 'July', value: 7 },
{ label: 'August', value: 8 },
{ label: 'September', value: 9 },
{ label: 'October', value: 10 },
{ label: 'November', value: 11 },
{ label: 'December', value: 12 }
];

const formKeys = [
    "email","firstName","lastName","bodyOfRegistration","haveYouCompleted","professionalRegistrationNumber",
    "areYouLocum","anythingElseAboutYou","services","howDidYouFindAboutTheProgram","whereYouWork","placeId",
    "photo","photoId","lat","lng","address","loginToken","additionalTrainingNotes","completedAdditionalTraining",
    "agreementCheck1","agreementCheck2","agreementCheck3","agreementCheck4","agreementCheck5","region",
    "willUpdateGoogleMapEntry","isChecked","pharmacistsDescription","city","province","pharmacyRegion",
    "callFlag","piva","callHistory","month","completionMonth","oncStatus","areaManagerId"
  ];

const BODY_OF_REGISTRATION_OPTIONS = (() => {
  switch(LANGUAGE+COUNTRY){
    case 'enuk':
      return [{ label: 'UK (GPhC)', value: 'UK (GPhC)' }];
    case 'itit':
      return [{ label: 'Italia', value: 'Italia (AIFA)'}];
    default:
      return [{ label: 'UK (GPhC)', value: 'UK (GPhC)' }];
  }
})();

let nameRegex;
switch(LANGUAGE+COUNTRY){
  case 'enuk':
    nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ]+$/i;
    break;
  case 'itit':
    nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
    break;
  case 'dede':
    nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
    break;
  default:
    nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ]+$/i;
}

const StepOneRep = () => {

  const [dataPharmacy, setDataPharmacy] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPharmacy, setIsLoadingPharmacy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');
  const [isDataLoadedPharmacy, setIsDataLoadedPharmacy] = useState(false);
  const [ adtnPhrmsts, setAdtnPhrmsts ] = useState([]);

  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm();
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation();
  const pharmacyId = query.get('pharmacyId'); 
  const callFlag = watch('callFlag');
  const call_flag_form_visible = callFlag === 'joined_unify';
  const PRIVACY_EMAIL = 'mailto:registrati@unifyhealth.app';
  const imageName = getValues('photo');
  const place_id = getValues('placeId');
  setValue('placeId', place_id);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(()=>{
    if(pharmacyId, !isDataLoadedPharmacy){
      fetchPharmacyData();
    }
  },[pharmacyId, isDataLoadedPharmacy]) 

  const fetchPharmacyData = async() =>{
    try {
      setIsLoading(true);
      const response = await fetch(SALES_REP_GET_PHARMACY_ENDPOINT.replace('##id##', pharmacyId));
      const result = await response.json();
      if(result.data){
        setDataPharmacy(result.data || result);
        if(result.data){
          formKeys.map( key=>{
            setValue(key, result.data[key])
          })
          setIsDataLoadedPharmacy(true);
        }
      }
      setIsLoading(false)
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  const goBack = () => {
    history.goBack()
  }

  const submitRep = async function( formData = {}) {

    const newFormData = {};
    formKeys.map( key => {
      newFormData[key] = formData[key];
    });

    adtnPhrmsts.map((val,index)=>{
      const pharmCnt = index + 2;
      newFormData['firstName'+pharmCnt] = formData['firstName'+pharmCnt];
      newFormData['lastName'+pharmCnt] = formData['lastName'+pharmCnt]
      newFormData['bodyOfRegistration'+pharmCnt] = formData['bodyOfRegistration'+pharmCnt]
      newFormData['professionalRegistrationNumber'+pharmCnt] = formData['professionalRegistrationNumber'+pharmCnt]
      newFormData['anythingElseAboutYou'+pharmCnt] = stripHtml(formData['anythingElseAboutYou'+pharmCnt])
    })

    const newData = {
      ...dataPharmacy,
      ...formData,
      appStoreCountry: COUNTRY,
      formLanguage: LANGUAGE
    }

    setIsLoadingPharmacy(true);
    
    newData.whereYouWork = stripHtml(newData.whereYouWork);
    newData.anythingElseAboutYou = stripHtml(newData.anythingElseAboutYou);
    newData.address = stripHtml(newData.address);
    newData.howDidYouFindAboutTheProgram = stripHtml(newData.howDidYouFindAboutTheProgram);
    newData.services = stripHtml(newData.services);
    newData.questionServicesPharmacyProvide = stripHtml(newData.questionServicesPharmacyProvide);
    newData.pharmacistsDescription = stripHtml(newData.pharmacistsDescription);
    
    try {
      const result = await saveData(newData, pharmacyId);
      setIsLoadingPharmacy(false);
      if (!result) {
        //setErrorMesage('ERROR 1: No results found 1');
        setHasError(true);
      } else {
        if (result.status === 'failed') {
          let message = (!result.message) ?  t('errorsGlobal.temporarilyUnavailableService') : result.message;
          if (message === 'errorEmailRegistered' || message === 'errorEmailRegistered2' ) {
            message =  t('step3.errorEmailIsAlreadyRegistered');
          } else if (message === 'errorRegisterantNumberRegistered' || message === 'errorRegisterantNumberRegistered2' ) {
            message =  t('step3.errorRegistrantIdIsAlreadyRegistered');
          } else {
            message =  t('errorsGlobal.temporarilyUnavailableService');
          }       
          setErrorMesage(message);
          setHasError(true);
        } else {
          history.push(`/rep/thank-you`);
        }
      }
    } catch (err) {
      console.log('err: ', err);
      console.log('HERE 3zzzzz')
      setIsLoadingPharmacy(false);
      //setErrorMesage('ERROR 2: No results found 2', err);
      setHasError(true);
    }
  };

  if (loadError) {
    return "Error loading maps";
  }
  if (!isLoaded) {
    return <div className={"flex justify-center"}><Loader /></div>;
  }

  return (
    <div className="flex flex-col py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">   
    
      <p className={'flex flex-col'}>
        <UnButton
          onClick={goBack}
          title="Back"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>

    { (isLoading | isLoadingPharmacy)
        ? <div className={'flex mx-auto'}> <Loader /> </div>
        : hasError ? <div className={'flex mx-auto mt-10'}><ErrorMessage errorMessage={errorMessage} /> </div>
        : 
          <form onSubmit={handleSubmit(submitRep)}>
            <input type={'hidden'} {...register('placeId', { required: true })} />
            <input type={'hidden'} {...register('lat', { required: true })} />
            <input type={'hidden'} {...register('lng', { required: true })} />
            <input type={'hidden'} {...register('address', { required: true })} />

            <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-12">
              * Obbligatorio
            </p>

            <UnDropDown
              hasError={!!errors.callFlag}
              control={register('callFlag', {required: true})}
              label={t('rep.form.callFlag.lable')}
              options={call_flags}
              fullWidth={false}
            />

            <UnDropDown
              hasError={!!errors.month}
              control={register('month', {required: true})}
              label={t('rep.form.callFlagMonth.lable')}
              options={months}
              fullWidth={false}
            />

            {
              call_flag_form_visible &&
              <>
                <UnDropDown
                  hasError={!!errors.completionMonth}
                  control={register('completionMonth', {required: false})}
                  label={t('rep.form.callFlagMonthCompltn.lable')}
                  options={months}
                  fullWidth={false}
                />

                <UnDropDown
                  hasError={!!errors.oncStatus}
                  control={register('oncStatus', {required: false})}
                  label={t('rep.form.oncStatus.lable')}
                  options={oncStatusList}
                  fullWidth={false}
                />

                <UnInput
                  hasError={!!errors.piva}
                  control={register('piva', {required: true })}
                  fullWidth={false} 
                  label={t('rep.form.PIVA.lable')} 
                  placeholder={t('rep.form.PIVA.placeHolder')} 
                />

                <ImageUpload setFormValue={setValue} imageName={imageName} />

                <UnTextArea
                  control={register('services')}
                  label={t('step1.questionServicesPharmacyProvide')}
                  placeholder=""
                  note={t('step1.servicesPharmacyProvideNote')}
                />

                <UnDropDown
                  hasError={!!errors.haveYouCompleted}
                  control={register('haveYouCompleted', { required: true})}
                  labelWithLink={
                    <div className="flex md:flex-wrap" style={{ whiteSpace: "pre-wrap", display: "flow-root" }}>
                      <Trans i18nKey="step1.questionOncodemiaCertificate" > 
                        <label htmlFor="" className="mb-4">
                        Have you successfully completed the
                        </label>
                        <a className="underline font-bold text-blue-theme" href={t('step1.oncodemiaLink')}  target={"_blank"} rel={"noreferrer"}>
                          Oncodemia training              
                        </a>
                        <label htmlFor="" className="mb-4">
                          and downloaded a certificate?
                        </label>
                      </Trans>
                    </div>
                  }
                  options={[
                    {
                      label: t('step1.yes'),
                      value: 'yes',
                    },
                    {
                      label: t('step1.no'),
                      value: 'no',
                    },
                  ]}
                />

                <UnInput
                  hasError={!!errors.email}
                  control={register('email', {
                    required: true,
                    pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                  })}
                  label={t('step1.emailAddress')}
                  placeholder={t('step1.emailPlaceHolder')}
                />

                <div className="flex flex-col md:flex-row justify-between">
                  <UnInput
                    hasError={!!errors.firstName}
                    control={register('firstName', {required: true, pattern: nameRegex })}
                    fullWidth={false} label={t('step1.firstName')} placeholder={t('step1.firstNamePlaceHolder')} />
                  <UnInput
                    hasError={!!errors.lastName}
                    control={register("lastName", {required: true, pattern: nameRegex })}
                    fullWidth={false}
                    label={t('step1.lastName')}
                    placeholder={t('step1.lastNamePlaceHolder')}
                    styles="md:ml-4"
                  />
                </div>

                <div className="flex lg:flex-row md:flex-col justify-between">
                  <UnDropDown
                    hasError={!!errors.bodyOfRegistration}
                    control={register('bodyOfRegistration', {required: true})}
                    label={t('step1.registrationCountry')}
                    options={BODY_OF_REGISTRATION_OPTIONS}
                    fullWidth={true}
                  />

                  <UnInput
                    hasError={!!errors.professionalRegistrationNumber}
                    control={register('professionalRegistrationNumber', {required: true, pattern: /^\d{3,7}$/})}
                    fullWidth={true}
                    label={t('step1.registrationNumber')}
                    placeholder={t('step1.registrationNumberPlaceHolder')}
                    styles="lg:ml-4"
                    note={t('step1.registrationNumberNote')}
                  />
                </div>

                <UnTextArea
                  hasError={!!errors.anythingElseAboutYou}
                  control={register('anythingElseAboutYou')}
                  label={t('step1.questionAnythingToKnowAbout')}
                  placeholder=""
                  note={t('step1.anythingToKnowAboutNote')}
                />

                {  
                  (adtnPhrmsts.length > 0) &&
                  <>
                  {
                    adtnPhrmsts.map((adtnPhamr, index) =>{
                      const pharmCnt = index + 2;
                      return(
                        <>
                          <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}} className="mt-10 mb-10">
                            {
                              (adtnPhrmsts.length == (1 + index)) &&
                                <UnButton
                                  disabled={false}
                                  title={t('step1.removeAdtnlphrmst')}
                                  fullWidth={false}
                                  position="right"
                                  onClick={()=>{setAdtnPhrmsts( adtnPhrmsts.splice(1) )}}
                                />
                            }
                          </div>
                          <div className="flex flex-col md:flex-row justify-between">
                            <UnInput
                              hasError={!!errors.firstName + pharmCnt}
                              control={register('firstName' + pharmCnt, {required: true, pattern: nameRegex })}
                              fullWidth={false} 
                              label={t('step1.firstName') + " " + pharmCnt} 
                              placeholder={t('step1.firstNamePlaceHolder')} />
                            <UnInput
                              hasError={!!errors.lastName + pharmCnt}
                              control={register("lastName" + pharmCnt, {required: true, pattern: nameRegex })}
                              fullWidth={false}
                              label={t('step1.lastName') + " " + pharmCnt}
                              placeholder={t('step1.lastNamePlaceHolder')}
                              styles="md:ml-4"
                            />
                          </div>

                    <div className="flex lg:flex-row md:flex-col justify-between">
                      <UnDropDown
                        hasError={!!errors.bodyOfRegistration + pharmCnt}
                        control={register('bodyOfRegistration'+ pharmCnt, {required: true})}
                        label={t('step1.registrationCountry') + " " + pharmCnt}
                        options={BODY_OF_REGISTRATION_OPTIONS}
                        fullWidth={true}
                      />

                      <UnInput
                        hasError={!!errors.professionalRegistrationNumber + pharmCnt}
                        control={register('professionalRegistrationNumber' + pharmCnt, {required: true, pattern: /^\d{3,7}$/})}
                        fullWidth={true}
                        label={t('step1.registrationNumber') + " " + pharmCnt}
                        placeholder={t('step1.registrationNumberPlaceHolder')}
                        styles="lg:ml-4"
                        note={t('step1.registrationNumberNote')}
                      />
                    </div>

                    <UnTextArea
                      hasError={!!errors.anythingElseAboutYou + pharmCnt}
                      control={register('anythingElseAboutYou') + pharmCnt}
                      label={t('step1.questionAnythingToKnowAbout') + " " + pharmCnt}
                      placeholder=""
                      note={t('step1.anythingToKnowAboutNote')}
                    />
                        </>
                      )
                    })
                  }
                  </>
                }

                <div className="mt-10 mb-10">
                  <UnButton
                    disabled={false}
                    title={t('step1.addAdtnlphrmst')}
                    fullWidth={false}
                    position="right"
                    onClick={()=>{setAdtnPhrmsts( adtnPhrmsts.concat(1) )}}
                  />
                </div>

                <div className="flex flex-col mt-8">
                  <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal">
                    {t('step3.nandatoryConfirmations.heading')}
                  </p>
                  <ul className="mt-6 md:pl-4">
                    <li className="text-blue-theme tracking-wide leading-normal mb-4">
                      <input {...register('check1', {required: true})} id={'check1'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
                      <label htmlFor={'check1'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.one')}</label>
                      {!!errors.check1 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
                    </li>
                    <li className="text-blue-theme tracking-wide leading-normal mb-4">
                      <input {...register('check2', {required: true})} id={'check2'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
                      <label htmlFor={'check2'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.two')}</label>
                      {!!errors.check2 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
                    </li>
                    <li className="text-blue-theme tracking-wide leading-normal mb-4">
                      <input {...register('check3', {required: true})} id={'check3'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
                      <label htmlFor={'check3'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.three')}</label>
                      {!!errors.check3 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
                    </li>
                  </ul>
                </div>
                <div className="flex justify-center mt-14">
                  {/* <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
                    size="normal"
                    ref={reRef}
                    onChange={validateHuman}
                  /> */}
                </div>
              </>
            }

            <div className={'flex flex-row items-center justify-end mt-10'}>
              <UnButton
                disabled={false}
                title={t('registration.submit')}
                type={'submit'}
                fullWidth={false}
                position="right"
              />
            </div>
            
            <div className="flex flex-col mt-8">
              <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal">
                {t('step3.notes.heading')}
              </p>
              <ul className="mt-6 md:pl-8 list-disc flow-root">
                <Trans i18nKey="step3.notes.one">
                  <li className="text-blue-theme tracking-wide leading-normal mb-4">
                    Our simple online application form takes about 5 minutes to complete. Every application is hand-reviewed on a rolling basis, and you can typically expect a decision within 4 weeks. We receive many applications each week, so please bear with us until your application is confirmed and Champion profile is live. If you have not received confirmation of your Champion status within 6 weeks of completion of submission of this form, please contact <a className="underline font-bold text-blue-theme" target={"_blank"} rel="noreferrer" href={PRIVACY_EMAIL}>privacy@unifyhealth.app</a> and a member of our team will be happy to assist you
                  </li>
                </Trans>
                <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.notes.two')}</li>
              </ul>
              <Trans i18nKey="step3.moreInforLink">
                <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">
                  For information about how we use your information see our &nbsp;
                  <Link className="underline font-bold text-blue-theme" to={'/privacy-policy'}>Privacy and Cookies Policy</Link>
                  &nbsp; and &nbsp;
                  <Link className="underline font-bold text-blue-theme" to={'/terms-and-conditions'}>Terms and Conditions</Link>
                </p>
              </Trans>
            </div>

          </form>
      }
    </div>
  );
};

export default StepOneRep;