import React from 'react';

const Loader = () => {
  return (
    <div className="sk-wave">
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
      <div className="sk-wave-rect"></div>
    </div>
  );
}

export default Loader;
