import React from 'react';
import clsx from 'clsx';

/**
 * @typedef {object} Props
 * @prop {(() => void)=} onClick
 * @prop {string=} to
 * @prop {string} title
 * @prop {boolean=} fullWidth
 * @prop {boolean=} transparent
 * @prop {boolean=} isTextSecondary
 * @prop {('left' | 'center' | 'right')=} position
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement}
 */
const UnButton = ({
  disabled = false,
  type = 'button',
  title,
  onClick,
  position = 'center',
  fullWidth = true,
  transparent = false,
  isTextSecondary = false,
}) => {
    const onClickProp = typeof onClick === 'function' ? {onClick} : {};
    return (
        <button
          disabled={disabled}
          type={type}
          {...onClickProp}
          className={clsx(
            {'opacity-50': disabled},
            'flex font-bold py-4 rounded-tl-3xl rounded-br-3xl px-10',
            fullWidth && 'w-full',
            position === 'left' && 'self-start',
            position === 'right' && 'self-end',
            position === 'center' && 'self-center',
            (!transparent) ? 'bg-yellow-theme ' : 'bg-transparent',
          )}
        >
            <span className={
                clsx(
                    'mx-auto',
                    (isTextSecondary) ? 'font-normal opacity-60' : 'font-bold'
                )
            }>{title}</span>
        </button>
    );
};

export default UnButton;
