import React from 'react';
import UnButton from "../../components/UnButton";
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
// import { HashLink } from 'react-router-hash-link';


const TermsAndConditions = () => {
  const {goBack} = useHistory();

  return (
    <>
  <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6">Nutzungsbedingungen</h1>
  <ul className="md:pl-8 list-disc">
  <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-1">Einleitung</HashLink></li>
  <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-2">Zweck der Plattform
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-3">Registrierung bei uns
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-4">Zugang zur Plattform
      </HashLink>
    </li>
    
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-5">Was Sie tun dürfen
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-6">Was Sie nicht tun dürfen
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-7"> Geistige Eigentumsrechte
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-8">Plattformfunktionen und -inhalte
      </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-9">Ihre personenbezogenen Daten
    </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-11">Allgemeines </HashLink>
    </li>
    <li className="text-black-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-black-theme" smooth to="#section-12">Kontaktaufnahme
    </HashLink>
    </li>
  </ul>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">&nbsp;</p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-1">Einleitung</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Dies sind die Nutzungsbedingungen für die Oncodemia-Plattform auf&nbsp;<a className="underline font-bold text-black-theme" href="www.oncodemia.com">www.oncodemia.com</a> (die "Plattform") und die separate „Registrierungswebseite zum
    Oncodemia-Apotheker auf&nbsp;<a className="underline font-bold text-black-theme" href="https://registrierung.unifyhealth.app/">https://registrierung.unifyhealth.app</a> (die "Registrierungswebseite"). Die Plattform und die
    Registrierungswebseite werden von oder im Namen von Accord Healthcare
    Limited („wir“, „uns“ oder „unser“) betrieben. Wir sind eine in England
    eingetragene Gesellschaft mit beschränkter Haftung. Unsere Firmennummer
    lautet 04596349, unser Geschäftssitz ist Sage House, 319 Pinner Road, North
    Harrow, Middlesex, HA1 4HF. Unsere Umsatzsteuer-Identifikationsnummer lautet
    GB 894350693.
    <br />
    <br />
    Ihre Nutzung der Plattform und der Registrierungswebseite unterliegt diesen
    Nutzungsbedingungen. Durch die Nutzung der Plattform erklären Sie sich damit
    einverstanden, an diese gebunden zu sein.
    <br />
    <br />
    Wir behalten uns das Recht vor, diese Nutzungsbedingungen mit Wirkung für
    die Zukunft zu ändern. Änderungen dieser Nutzungsbedingungen werden Ihnen
    über die angegeben E-Mail-Adresse mitgeteilt. Sie werden ein Teil dieser
    Nutzungsbedingungen, wenn Sie diesen Änderungen zustimmen. Stimmen Sie nicht
    zu, können wir die Vereinbarung jederzeit kündigen. Die jeweils aktuelle
    Version können Sie auf der Plattform und der Registrierungswebseite abrufen.
    Diese Nutzungsbedingungen wurden zuletzt am 07.04.2022 aktualisiert.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-2">SEHR WICHTIG: ZWECK DER PLATTFORM</h1>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6">Die Plattform ist kein Medizinprodukt</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Die Plattform ist kein Medizinprodukt und darf nicht als solches verwendet
    werden. Die Plattform bietet Informationen, die lediglich dazu dienen
    sollen, Patienten bei der Behandlung ihrer Krebserkrankung und der damit
    zusammenhängenden Symptome zu unterstützen. Daher dient sie ausschließlich
    der Orientierung und darf nicht als diagnostisches, medizinisches oder
    therapeutisches Hilfsmittel verwendet werden.
    <br />
    <br />
    Alle Informationen auf der Plattform dienen als Orientierungshilfe und
    Beratung. Sie entsprechen der klinischen Praxis des auf Krebsbehandlung
    spezialisierten Londoner Krankenhauses Royal Marsden Hospital NHS Foundation
    Trust. Sie sollen nicht als Empfehlung für die Behandlung einer bestimmten
    Person angesehen werden. Es liegt in Ihrer Verantwortung, sicherzustellen,
    dass Sie Ihre Verpflichtungen gegenüber den Patienten, Ihre Verpflichtungen
    gemäß den Vorgaben und Richtlinien während der Registrierung bei der
    deutschen Apothekerkammer und alle anderen Ausbildungs- oder
    Berufsverpflichtungen einhalten.
    <br />
    <br />
    Sie dürfen die Plattform niemals als Medizinprodukt nach außen darstellen
    und niemanden dazu ermutigen, den medizinischen Rat eines Arztes oder einer
    anderen medizinischen Fachkraft zu ignorieren, weil Sie etwas auf der
    Plattform erfahren oder gesehen haben. Wenn Sie unsicher sind, wie Sie über
    die Gesundheit der Person beraten können, sollten Sie diese Person darauf
    hinweisen, sich an ihren Arzt, das Krankenhausteam oder andere medizinische
    Fachkräfte zu wenden.
    <br />
    <br />
    Sie sind sich bewusst, dass die Autoren der Materialien und Inhalte auf der
    Plattform die verfügbaren Informationen nach bestem Wissen und Gewissen so
    formuliert haben, dass sie korrekt und auf dem neuesten Stand sind.
    <br />
    <br />
    Sofern in diesen Nutzungsbedingungen nicht ausdrücklich anders festgelegt,
    geben wir keine Zusicherungen oder Gewährleistungen in Bezug auf die
    Genauigkeit, Vollständigkeit, Aktualität, Korrektheit, Zuverlässigkeit,
    Integrität, Qualität, Zweckmäßigkeit oder Originalität von Materialien oder
    Inhalten, die auf oder über die Plattform verfügbar sind, und schließen
    hiermit, soweit gesetzlich zulässig, alle stillschweigenden Garantien,
    Bedingungen und sonstigen Bestimmungen jeglicher Art aus.
    <br />
    <br />
    Wir sind nicht verantwortlich für Entscheidungen oder Empfehlungen von Ihnen
    oder von Apothekern oder anderen medizinischen Fachkräften.
    <br />
    <br />
    Die Plattform ist nicht für die direkte Nutzung durch Patienten bestimmt.
    Durch die Nutzung der Plattform erklären und garantieren Sie, dass Sie über
    eine angemessene Berufshaftpflichtversicherung mit ausreichender Deckung
    verfügen, um Ihre Nutzung der Plattform abzudecken.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-3">Registrierung bei uns</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Die Plattform und die Registrierungswebseite sind nur für Personen bestimmt,
    die:
  </p>
  <ul className="md:pl-8 list-disc">
    <li className="text-black-theme tracking-wide leading-normal">mindestens 18 Jahre sind und</li>
    <li className="text-black-theme tracking-wide leading-normal">
        qualifizierte Apotheker gültiger Apotheken Meldenummer (bei der deutschen
        Apothekerkammer) sind.
      </li>
  </ul>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6">
    Durch die Nutzung der Plattform und der Registrierungswebseite bestätigen
    Sie, dass Sie diese Anforderungen erfüllen.
    <br />
    <br />
    Bitte beachten Sie, dass zusätzliche Berechtigungskriterien für einen
    bestimmten Dienst oder eine bestimmte Funktion gelten können, sofern dies
    auf der Plattform und der Registrierungswebseite angegeben ist. Es liegt in
    Ihrer Verantwortung, sicherzustellen, dass Sie alle Mindestkriterien für die
    entsprechende Berechtigung erfüllen, bevor Sie einen solchen Dienst oder
    eine solche Funktion nutzen. Damit bestätigen Sie, dass Sie alle
    Mindestkriterien für die Berechtigung erfüllen.
    <br />
    <br />
    Wir können von Zeit zu Zeit den Zugriff auf bestimmte Funktionen, Teile oder
    Inhalte der Plattform oder der gesamten Plattform auf Benutzer beschränken,
    die sich bei uns registriert haben. Sie müssen sicherstellen, dass alle von
    Ihnen bei der Registrierung gemachten Angaben korrekt sind. Wenn Sie sich
    für eine Anmelde-ID (wie Benutzername und Passwort oder eine andere Kennung)
    als Teil unserer Sicherheitsverfahren entscheiden oder diese erhalten,
    müssen Sie diese Informationen vertraulich behandeln und dürfen sie nicht an
    Dritte weitergeben. Sie sind für alle Aktivitäten verantwortlich, die unter
    Ihrer Anmelde-ID stattfinden, und müssen uns unverzüglich über jede
    unbefugte Nutzung oder andere Sicherheitsverletzung informieren, von der Sie
    Kenntnis erhalten. Wir behalten uns das Recht vor, jede Anmelde-ID jederzeit
    zu deaktivieren, wenn Sie unserer Meinung nach gegen eine der Bestimmungen
    dieser Nutzungsbedingungen verstoßen haben oder wenn sich Ihre Angaben zum
    Zwecke der Registrierung als Benutzer als falsch erweisen.
    <br />
    <br />
    Bei der Registrierung müssen Sie korrekte, wahre, vollständige und aktuelle
    Kontaktdaten, einschließlich Name, Postanschrift, Telefonnummer und
    E-Mail-Adresse, angeben. Sie sind für die Informationen verantwortlich, die
    Sie uns zur Verfügung stellen. Sie verpflichten sich, Ihre
    Kontoinformationen im Falle von Änderungen dieser Informationen unverzüglich
    online zu aktualisieren. Wir behalten uns das Recht vor, nach eigenem
    Ermessen Überprüfungen vorzunehmen, um Ihre Identität zu bestätigen und Ihre
    Qualifikationen und die Erfüllung der Berechtigungskriterien festzustellen.
    Indem Sie diesen Bedingungen zustimmen, erklären Sie sich damit
    einverstanden.
    <br />
    <br />
    Durch die Registrierung und Nutzung der Plattform kommt ein Nutzungsvertrag
    mit Ihnen zustande.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mt-6 mb-6" id="section-4">Zugang zur Plattform und Registrierungswebseite</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Zur Nutzung der Plattform und Registrierungswebseite haben Sie
    sicherzustellen, dass Ihre Geräte (Computer, Laptop, Netbook, Tablet oder
    andere Mobilgeräte) alle erforderlichen technischen Spezifikationen erfüllen
    sowie mit der Plattform und der Registrierungswebseite kompatibel sind.
    <br />
    <br />
    Es besteht keine Verpflichtung zur Gewährleistung einer ununterbrochenen,
    durchgängigen oder fehlerfreien Funktionsfähigkeit der Plattform und der
    Registrierungswebseite. Es kann vorkommen, dass bestimmte Funktionen, Teile
    oder Inhalte der Plattform und Registrierungswebseite oder der gesamten
    Plattform und Registrierungswebseite (ob planmäßig oder unplanmäßig) nicht
    verfügbar sind oder von uns nach unserem alleinigen Ermessen aufgrund von
    einschließlich, aber nicht beschränkt auf rechtlicher Anforderungen,
    IT-technischer Probleme geändert, ausgesetzt oder entfernt werden. Wir
    werden Sie über die entsprechenden Änderungen informieren.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-5">Was Sie tun dürfen</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Sie dürfen die Plattform und die Registrierungswebseite nur für den
    gewerblichen Gebrauch und nur in Übereinstimmung mit diesen
    Nutzungsbedingungen nutzen. Sie können Inhalte von der Plattform und der
    Registrierungswebseite auf einem Computerbildschirm abrufen und anzeigen,
    einzelne Seiten drucken und kopieren und, vorbehaltlich des nächsten
    Abschnitts, diese Seiten in elektronischer Form speichern. Für bestimmte
    Funktionen, Teile oder Inhalte der Plattform und der Registrierungswebseite
    können zusätzliche Bedingungen gelten, die, auf dem Bildschirm angezeigt
    werden oder über einen Link zugänglich sind.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-6">Was Sie nicht tun dürfen</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Sofern nicht ausdrücklich in diesen Nutzungsbedingungen oder einer anderen
    Vereinbarung mit uns festgelegt, ist es Ihnen untersagt:
 
  </p>
  <ul className="md:pl-8 list-disc">
  <li className="text-black-theme tracking-wide leading-normal">
      Inhalte der Plattform oder Registrierungswebseite auf einen Server oder
      ein anderes Speichergerät, das mit einem Netzwerk verbunden ist,
      auszulesen („scrapen“) oder zu speichern oder eine elektronische Datenbank
      zu erstellen, in der alle Inhalte der Plattform oder
      Registrierungswebseite systematisch heruntergeladen und gespeichert
      werden;
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      die Schulung oder einen Teil des Schulungsinhalts einzeln zu verwerten,
      der Plattform oder der Registrierungswebseite in eine andere Website, ein
      Lernmanagement- oder Tracking-System zu integrieren;
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      Inhalte der Plattform oder Registrierungswebseite zu entfernen oder zu
      ändern oder zu versuchen, die Sicherheit zu umgehen oder den
      ordnungsgemäßen Betrieb der Plattform oder der Server, auf denen sie
      gehostet wird, zu beeinträchtigen; oder
    </li>
    <li className="text-black-theme tracking-wide leading-normal">
      ohne unsere vorherige schriftliche Zustimmung Links zu der Plattform oder
      Registrierungswebseite auf einer anderen Website einzurichten, auch wenn
      Sie einen Link auf einer von Ihnen betriebenen Website aus einrichten
      können, mit Ausnahme, der Link ist nicht irreführend oder trügerisch und
      gibt den Zielort in angemessener Weise an, Sie erwecken nicht den
      Eindruck, dass wir Sie, Ihre Website oder von Ihnen angebotene Produkte
      oder Dienstleistungen unterstützen, Sie verlinken zur Homepage der
      Plattform oder Registrierungswebseite (Sie benutzen diese nicht in Frames
      und replizieren diese auch nicht) und die verlinkte Website enthält keine
      Inhalte, die rechtswidrig, bedrohend, beleidigend, verleumderisch,
      diffamierend, pornografisch, obszön, vulgär, anstößig, unanständig sind
      oder die Rechte an geistigem Eigentum oder andere Rechte Dritter
      verletzen.
    </li>
  </ul>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6">
    Sie dürfen die Plattform oder Registrierungswebseite und alles, was durch
    die Plattform oder Registrierungswebseite verfügbar ist, nur für rechtmäßige
    Zwecke (unter Einhaltung aller geltenden Gesetze und Vorschriften) auf
    verantwortungsvolle Weise und nicht in einer Weise nutzen, die unserem Namen
    oder Ruf oder dem eines unserer verbundenen Unternehmen schaden könnte.
    <br />
    <br />
    Alle Ihnen im Rahmen dieser Nutzungsbedingungen eingeräumten Rechte
    erlöschen sofort, wenn Sie gegen eine dieser Bedingungen verstoßen.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-7">Geistige Eigentumsrechte</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Jegliche Rechte am geistigen Eigentum an den gesamten Inhalten der Plattform
    und der Registrierungswebseite (einschließlich Text, Grafiken, Software,
    Fotos und anderen Bildern, Videos, Ton, Marken und Logos) stehen uns oder
    unseren Lizenzgebern zu. Sofern hier nicht ausdrücklich etwas anderes
    bestimmt ist, erhalten Sie durch diese Nutzungsbedingungen keine Rechte an
    geistigem Eigentum, das uns oder unseren Lizenzgebern gehört, und Sie
    erkennen an, dass Sie durch das Herunterladen von Inhalten von der Plattform
    oder der Registrierungswebseite keine Eigentumsrechte erwerben. Wenn Sie
    Seiten von der Plattform oder Registrierungswebseite ausdrucken, kopieren
    oder speichern (nur im Rahmen dieser Nutzungsbedingungen), müssen Sie
    sicherstellen, dass alle Urheberrechts-, Marken- oder sonstigen Hinweise auf
    geistige Eigentumsrechte, die im ursprünglichen Inhalt enthalten sind,
    wiedergegeben werden.
 
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-8">Funktionen und Inhalte der Plattform</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Sie sind damit einverstanden, auf die Plattform sowie die
    Registrierungswebseite zuzugreifen und die dem Nutzungsumfang zum Zeitpunkt
    der Registrierung enthaltenen Bestandteile auf Ihr eigenes Risiko zu nutzen,
    da sie kostenlos zum Herunterladen zur Verfügung gestellt werden.
    <br />
    <br />
    Sofern die Plattform oder Registrierungswebseite Inhalte zur Verfügung
    stellt, erkennen Sie an, dass diese Inhalte jederzeit aktualisiert werden
    können. Wir behalten uns das Recht vor, die Bereitstellung und/oder
    Aktualisierung von Inhalten der Plattform oder Anwendungswebsite mit oder
    ohne Benachrichtigung an Sie einzustellen, wenn wir dies aus Sicherheits-,
    rechtlichen oder anderen Gründen tun müssen.
    <br />
    <br />
    Wir bemühen uns, dass die von der Plattform zur Verfügung gestellten Inhalte
    aus Informationen bestehen, von denen wir überzeugt sind, dass die Quelle
    korrekt ist. Dennoch ist Ihnen bewusst, dass wir für die Richtigkeit der
    Quellen nicht verantwortlich sind.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-9">Ihre personenbezogenen Daten</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Die in Verbindung mit der Plattform und Registrierungswebseite gesammelten,
    verarbeiteten und genutzten personenbezogenen Daten werden in
    Übereinstimmung mit unserer &nbsp;<a className="underline font-bold text-black-theme" href="/datenschutz">Datenschutz und Cookie Richtlinie</a>&nbsp; verarbeitet. Die Plattform und die Registrierungswebseite können
    gelegentlich Links zu externen Websites Dritter enthalten. Wir sind nicht
    für den Inhalt dieser Seiten oder für von ihnen bereitgestellte Inhalte
    verantwortlich und garantieren nicht, dass diese dauerhaft verfügbar sind.
    Die Tatsache, dass wir Links zu solchen externen Websites aufnehmen,
    bedeutet nicht, dass wir deren Betreiber oder Anbieter unterstützen oder mit
    ihnen in Verbindung stehen.
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-11">Allgemeines</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Unsere Haftung ist wie folgt begrenzt:</p>
  <ul className="md:pl-8 list-disc">
    <li>
      Im Falle von Vorsatz oder grober Fahrlässigkeit von uns, eines
      gesetzlichen Vertreters oder Erfüllungsgehilfen von uns haften wir nach
      den gesetzlichen Bestimmungen. Gleiches gilt im Falle der Verletzung von
      Leben, Körper oder Gesundheit sowie im Falle der Übernahme einer Garantie
      durch uns oder der Haftung nach dem Produkthaftungsgesetz.
    </li>
    <li>
      Für Sach- und Vermögensschäden, die von uns, einem gesetzlichen Vertreter
      oder Erfüllungsgehilfen von uns leicht fahrlässig verursacht werden,
      haften wir nur bei Verletzung einer wesentlichen Vertragspflicht, jedoch
      der Höhe nach begrenzt auf den bei Vertragsschluss vorhersehbaren und
      vertragstypischen Schaden. Wesentliche Vertragspflichten sind solche,
      deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt
      erst ermöglichen und auf deren Einhaltung die Vertragsparteien regelmäßig
      vertrauen dürfen.
    </li>
    <li>Im Übrigen ist die Haftung von uns ausgeschlossen.</li>
    <li>
      Für den Verlust von Daten oder Programmen haftet wir insoweit nicht, als
      der Schaden darauf beruht, dass Sie es unterlassen haben, regelmäßige
      Datensicherungen durchzuführen und dadurch sicherzustellen, dass
      verlorengegangene Daten oder Programme mit vertretbarem Aufwand
      wiederhergestellt werden können.
    </li>
  </ul>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mt-6 mb-6">
    Die vorstehenden Haftungsausschlüsse bzw. -beschränkungen gelten auch im
    Hinblick auf die Haftung unserer Angestellten, Arbeitnehmer, Mitarbeiter,
    Vertreter und Erfüllungsgehilfen, insbesondere zugunsten der Anteilseigner,
    Mitarbeiter, Vertreter, Organe und ihrer Mitglieder im Hinblick auf deren
    persönliche Haftung.
    <br />
    <br />
    Diese Nutzungsbedingungen unterliegen dem deutschem Recht, mit der Ausnahme,
    dass wenn Sie ein Verbraucher und kein gewerblicher Benutzer sind und wenn
    Sie in einem anderen Land der Europäischen Union als in Deutschland leben,
    zusätzlich bestimmte zwingende Gesetze Ihres Landes Anwendung finden, sofern
    diese vorteilhafter für Sie sind als die Bestimmung des deutschen Rechts.
    <br />
    <br />
    Für sämtliche Streitigkeiten, die aus oder in Verbindung mit diesen
    Nutzungsbedingungen oder der Plattform und Registrierungswebseite entstehen,
    besteht ein Gerichtsstand in München.
    <br />
    <br />
    Wir sind jedoch in allen Fällen auch berechtigt, Klage an Ihrem allgemeinen
    Gerichtsstand zu erheben. Vorrangige gesetzliche Vorschriften, insbesondere
    zu ausschließlichen Zuständigkeiten, bleiben unberührt.
    <br />
    <br />
    Sollten einzelne Bestimmungen dieser Bedingungen unwirksam sein oder werden
    und/oder den gesetzlichen Regelungen widersprechen, so wird hierdurch die
    Wirksamkeit der Bedingungen im Übrigen nicht berührt. Die unwirksame
    Bestimmung wird von Ihnen und uns einvernehmlich durch eine solche
    Bestimmung ersetzt, welche dem wirtschaftlichen Zweck der unwirksamen
    Bestimmung in rechtswirksamer Weise am nächsten kommt. Die vorstehende
    Regelung gilt entsprechend bei Regelungslücken.
  
  </p>
  <h1 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6" id="section-12">Kontaktieren Sie uns</h1>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Wenn Sie Fragen zu diesen Bedingungen oder Probleme mit der Plattform und
    der Registrierungswebseite haben, wenden Sie sich bitte an die untenstehende
    Adresse.
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Oncodemia-Plattform E-Mail:&nbsp;
    <a className="underline font-bold text-black-theme" href="mailto:support@oncodemia.com">support@oncodemia.com</a>
    </p>
    <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Registrierungswebseite - E-Mail:&nbsp;
    <a className="underline font-bold text-black-theme" href="mailto:registrierung@unifyhealth.app">registrierung@unifyhealth.app</a>
  </p>
  <p className="flex flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Auf dem Postweg: Accord Healthcare Limited Sage House 319 Pinner Road North
    Harrow, Middlesex HA1 4HF.
  </p>
  <p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Zurück"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
  </div>
</>

  )
}

export default TermsAndConditions;
