import { SAVE_ENDPOINT, AREAMANAGER_SAVE_ENDPOINT, SALES_REP_SAVE_ENDPOINT } from "../../constants";
import sanitizeHtml from 'sanitize-html';
import { COUNTRY, LANGUAGE, SITE } from "./constant";

export const saveData = (data, pharmacistId) => {

  let options = {};
  let method = 'POST'; 

  if(COUNTRY+LANGUAGE === 'dede'){
      let formData = new FormData();
      data.willUpateGoogleMapsEntry = data.isChecked; //TODO: the backend uses in isChecked. refactor to willUpateGoogleMapsEntry
      // delete data.isChecked;
      delete data.file;
      // eslint-disable-next-line array-callback-return
      Object.keys(data).map( key => {
        formData.append(key, data[key]);
      });
      options = {
        method,
        body: formData
      }
  }else{
    options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }
  }

  let saveEndPoint = SAVE_ENDPOINT;

  if (LANGUAGE+COUNTRY+SITE === 'ititareamanager'){
    console.log('ititareamanager');
    options = {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
    saveEndPoint = AREAMANAGER_SAVE_ENDPOINT;

  }

  if (LANGUAGE+COUNTRY+SITE === 'ititrep'){
    console.log('ititrep');
    options = {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
    saveEndPoint = SALES_REP_SAVE_ENDPOINT;

  }else{
    console.log('not ititrep');
  }

  // pharmacistId ? 'PUT' : 'POST'
  // note: PUT request will amend this URL with the pharmacistId
  return fetch(saveEndPoint, options)
    .then((resp) => {
      console.log("HERE 1");
      if (resp.ok) {
        console.log("HERE 2");
        return resp.json();
      } else {
        console.log("HERE 3");
        throw resp.json();
      }
    })
}

export const stripHtml = (string) => sanitizeHtml(string).replace(/<\/?[^>]+(>|$)/g, "");
