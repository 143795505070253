import React from 'react';
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { COUNTRY, LANGUAGE } from '../../screens/Form/constant';

const Footer = ({ path }) => {
  const { t } = useTranslation();

  if(LANGUAGE === 'de' && COUNTRY === 'de'){
    return(
    //   <div className={'text-white pt-5 text-center'}>
    //     <a href={"https://files.oncodemia.com/de/terms/"} target={"_blank"} rel={"noreferrer"}> {t('footer.termsandConditions')}</a>
    //   {' | '}
    //     <a href={" https://files.oncodemia.com/de/privacy/"} target={"_blank"} rel={"noreferrer"}> {t('footer.privacyPolicy')}</a>
    //   {' | '}
    //     <a href={"https://files.oncodemia.com/de/impressum/"} target={"_blank"} rel={"noreferrer"}> {t('footer.Impressum')}</a>
    // </div>

  <div className={'text-white pt-5 text-center'}>
  <Link to={'/nutzungsbedingungen'}> {t('footer.termsandConditions')}</Link>
  {' | '}
  <Link to={'/datenschutz'}> {t('footer.privacyPolicy')}</Link>
  {' | '}
  <Link to={'/impressum'}> {t('footer.Impressum')}</Link>
  </div>
    )
  }

  let PpPath = '/privacy-policy';
  let TandCpath = '/terms-and-conditions';

  if(path = 'areamanager'){
    PpPath = '/areamanager/privacy-policy';
    TandCpath = '/areamanager/terms-and-conditions';
  }else if(path = 'rep'){
    PpPath = '/rep/privacy-policy';
    TandCpath = '/rep/terms-and-conditions';
  }

  return (
    <div className={'text-white pt-5 text-center'}>
      <Link to={TandCpath}> {t('footer.termsandConditions')}</Link>
      {' | '}
      <Link to={PpPath}> {t('footer.privacyPolicy')}</Link>
      { (LANGUAGE === 'en' && COUNTRY === 'uk') &&
        <h1 className="text-xs md:text-sm text-center tracking-relax mx-auto text-white mt-2">
          UK-04227 July 2022
        </h1>
      }
    </div>
  )
}

export default Footer
