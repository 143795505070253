/* eslint-disable no-useless-escape*/
import React, { useState, useEffect } from "react";
// import defaultAva from "../../assets/images/defaultava.png";
import UnTextArea from "../UnTextArea";
import UnInput from "../UnInput";
import UnDropDown from "../UnDropDown";

import { useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import Map from "./Map";
import {useForm} from "react-hook-form";
import UnButton from "../UnButton";
import PlaceDetails from "./PlaceDetails";
import ImageUpload from "./ImageUpload";
import Loader from "../Loader";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { COUNTRY, LANGUAGE } from '../../screens/Form/constant';
// import ImageInputWithCrop from "./ImageInputWithCrop";

import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {Link } from "react-router-dom";

const libraries = ['places'];

const StepOne = ({onSubmit = () => {}, data}) => {

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState('');
  const reRef = useRef();

  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
    defaultValues: data
  });

  const { t } = useTranslation();

  const validateHuman = async () => {
    const token = await reRef.current.getValue();
    setToken(token);
  };

  // const pharmacistId = query.get('pharmacistId');
  // const queryForRedirect = pharmacistId ? '?pharmacistId=' + pharmacistId : '';

  // useEffect(() => {
  //   if (!data.firstName) {
  //     history.push('/form/1' + queryForRedirect)
  //   } 
  // }, [data, history, queryForRedirect, t])

  const check1 = watch('check1');
  const check2 = watch('check2');
  const check3 = watch('check3');

  useEffect(() => {
    setDisabled( !(token && check1 && check2 && check3 ) )
  }, [token,  check1, check2, check3 ])

  // const submit = ({check1, check2, check3 }) => {
  //   const data = {
  //     recaptchaToken: token,
  //     agreementCheck1:check1, 
  //     agreementCheck2:check2, 
  //     agreementCheck3:check3,
  //   }
  //   onSubmit(data);
  // }

  const PRIVACY_EMAIL = 'mailto:registrati@unifyhealth.app';

  const BODY_OF_REGISTRATION_OPTIONS = (() => {
      switch(LANGUAGE+COUNTRY){
        case 'enuk':
          return [{ label: 'UK (GPhC)', value: 'UK (GPhC)' }];
        case 'itit':
          return [{ label: 'Italia', value: 'Italia (AIFA)'}];
        default:
          return [{ label: 'UK (GPhC)', value: 'UK (GPhC)' }];
      }
    })();

  let nameRegex;
  switch(LANGUAGE+COUNTRY){
    case 'enuk':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ]+$/i;
      break;
    case 'itit':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
      break;
    case 'dede':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
      break;
    default:
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ]+$/i;
  }
  
  const placeIdInFormHook = getValues('placeId');
  const imageName = getValues('photo');
  const lat = getValues('lat');
  const lng = getValues('lng');
  const address = getValues('address');
  const place_id = getValues('placeId');

  useEffect(() => {
    setPlaceId(placeIdInFormHook)
  }, [placeIdInFormHook])

  const [placeId, setPlaceId] = useState('');
  const [pharmacyPopulated, SetPharmacyPopulated] = useState(false);

  if (loadError) {
    return "Error loading maps";
  }
  if (!isLoaded) {
    return <div className={"flex justify-center"}><Loader /></div>;
  }


  const addressSelect = ({place_id, lat, lng, address, services, whereYouWork }, isPharmacyExists = false) => {
    setValue('placeId', place_id);
    setValue('lat', lat);
    setValue('lng', lng);
    setValue('address', address);
    setPlaceId(place_id);
    if(isPharmacyExists){
      SetPharmacyPopulated(true);
      setValue('whereYouWork', whereYouWork);
      setValue('services', services);
    }else if(pharmacyPopulated){
      SetPharmacyPopulated(false);
      setValue('whereYouWork', '');
      setValue('services', '');
    }
  }

  const resetPlace = () => {
    setPlaceId('');
    setValue('placeId', '');
  }


  // const submit = ({check1, check2, check3 }) => {
  //   const data = {
  //     recaptchaToken: token,
  //     agreementCheck1:check1, 
  //     agreementCheck2:check2, 
  //     agreementCheck3:check3,
  //   }
  //   onSubmit(data);
  // }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type={'hidden'} {...register('placeId', { required: true })} />
      <input type={'hidden'} {...register('lat', { required: true })} />
      <input type={'hidden'} {...register('lng', { required: true })} />
      <input type={'hidden'} {...register('address', { required: true })} />

      <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-12">
        * Obbligatorio
      </p>

      <UnInput
        hasError={!!errors.whereYouWork}
        control={register('whereYouWork', {required: true})}
        label={t('step1.questionPharmacyName')}
        placeholder={t('step1.pharmacyNamePlaceHolder')}
        note={t('step1.pharmacyNameNote')}
      />

      <p className="font-bold text-lg mt-8 mb-4 text-blue-theme h">
        {t('step1.findPlaceId')}
      </p>
      {(!!errors.placeId && !placeId) && ( <div className={'pt-4 text-red-600'}> <p>{t('step1.required')}</p> </div> )}
      {placeId && <PlaceDetails data={getValues()} reset={resetPlace} />}
      {<Map onAddressSelect={addressSelect} isPlaceId={!!errors.placeId && !placeId} lat={lat} lng={lng} address={address} place_id={place_id} className="relative w-full" />}

      <ImageUpload setFormValue={setValue} imageName={imageName} />

      <UnTextArea
        control={register('services')}
        label={t('step1.questionServicesPharmacyProvide')}
        placeholder=""
        note={t('step1.servicesPharmacyProvideNote')}
      />

      <UnDropDown
        hasError={!!errors.haveYouCompleted}
        control={register('haveYouCompleted', { required: true})}
        labelWithLink={
          <div className="flex md:flex-wrap" style={{ whiteSpace: "pre-wrap", display: "flow-root" }}>
            <Trans i18nKey="step1.questionOncodemiaCertificate" > 
              <label htmlFor="" className="mb-4">
              Have you successfully completed the
              </label>
              <a className="underline font-bold text-blue-theme" href={t('step1.oncodemiaLink')}  target={"_blank"} rel={"noreferrer"}>
                Oncodemia training              
              </a>
              <label htmlFor="" className="mb-4">
                and downloaded a certificate?
              </label>
            </Trans>
          </div>
        }
        options={[
          {
            label: t('step1.yes'),
            value: 'yes',
          },
          {
            label: t('step1.no'),
            value: 'no',
          },
        ]}
      />

      <UnInput
        hasError={!!errors.email}
        control={register('email', {
          required: true,
          pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        })}
        label={t('step1.emailAddress')}
        placeholder={t('step1.emailPlaceHolder')}
      />

      <div className="flex flex-col md:flex-row justify-between">
        <UnInput
          hasError={!!errors.firstName}
          control={register('firstName', {required: true, pattern: nameRegex })}
          fullWidth={false} label={t('step1.firstName')} placeholder={t('step1.firstNamePlaceHolder')} />
        <UnInput
          hasError={!!errors.lastName}
          control={register("lastName", {required: true, pattern: nameRegex })}
          fullWidth={false}
          label={t('step1.lastName')}
          placeholder={t('step1.lastNamePlaceHolder')}
          styles="md:ml-4"
        />
      </div>

      <div className="flex flex-col md:flex-row justify-between">
        <UnDropDown
          hasError={!!errors.bodyOfRegistration}
          control={register('bodyOfRegistration', {required: true})}
          label={t('step1.registrationCountry')}
          options={BODY_OF_REGISTRATION_OPTIONS}
          fullWidth={false}
        />

        <UnInput
          hasError={!!errors.professionalRegistrationNumber}
          control={register('professionalRegistrationNumber', {required: true, pattern: /^\d{3,7}$/})}
          fullWidth={false}
          label={t('step1.registrationNumber')}
          placeholder={t('step1.registrationNumberPlaceHolder')}
          styles="md:ml-4"
          note={t('step1.registrationNumberNote')}
        />
      </div>

      {/*<ImageInputWithCrop setPreview={setValue} />*/}
      
      <UnTextArea
        hasError={!!errors.anythingElseAboutYou}
        control={register('anythingElseAboutYou')}
        label={t('step1.questionAnythingToKnowAbout')}
        placeholder=""
        note={t('step1.anythingToKnowAboutNote')}
      />

      {/* <div className={'flex flex-col'}>
        <UnButton
          title={t('registration.next')}
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </div> */}

      <div className="flex flex-col mt-8">
        <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal">
          {t('step3.nandatoryConfirmations.heading')}
        </p>
        <ul className="mt-6 md:pl-4">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('check1', {required: true})} id={'check1'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'check1'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.one')}</label>
            {!!errors.check1 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('check2', {required: true})} id={'check2'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'check2'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.two')}</label>
            {!!errors.check2 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('check3', {required: true})} id={'check3'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'check3'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.three')}</label>
            {!!errors.check3 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
        </ul>
        {/* <p className="opacity-40 text-xs mt-4 tracking-normal leading-normal">
          Ciascuna delle conferme di cui sopra deve essere spuntata. Sono tutte obbligatorie e non sarà possibile inviare il modulo se non sono state spuntate tutte
        </p> */}
      </div>
      <div className="flex justify-center mt-14">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
          size="normal"
          ref={reRef}
          onChange={validateHuman}
        />
      </div>
      <div className={'flex flex-row items-center justify-end mt-10'}>
        {/* <UnButton
          title={t('registration.previous')}
          fullWidth={false}
          onClick={() => {
            //history.goBack()
          }}
          transparent={true}
          isTextSecondary={true}
        /> */}

        <UnButton
          disabled={disabled}
          title={t('registration.submit')}
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </div>
      <div className="flex flex-col mt-8">
        <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal">
          {t('step3.notes.heading')}
        </p>
        <ul className="mt-6 md:pl-8 list-disc flow-root">
          <Trans i18nKey="step3.notes.one">
            <li className="text-blue-theme tracking-wide leading-normal mb-4">
              Our simple online application form takes about 5 minutes to complete. Every application is hand-reviewed on a rolling basis, and you can typically expect a decision within 4 weeks. We receive many applications each week, so please bear with us until your application is confirmed and Champion profile is live. If you have not received confirmation of your Champion status within 6 weeks of completion of submission of this form, please contact <a className="underline font-bold text-blue-theme" target={"_blank"} rel="noreferrer" href={PRIVACY_EMAIL}>privacy@unifyhealth.app</a> and a member of our team will be happy to assist you
            </li>
          </Trans>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.notes.two')}</li>
        </ul>
        <Trans i18nKey="step3.moreInforLink">
          <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">
            For information about how we use your information see our &nbsp;
            <Link className="underline font-bold text-blue-theme" to={'/privacy-policy'}>Privacy and Cookies Policy</Link>
            &nbsp; and &nbsp;
            <Link className="underline font-bold text-blue-theme" to={'/terms-and-conditions'}>Terms and Conditions</Link>
          </p>
        </Trans>
      </div>

    </form>
  );
};

export default StepOne;
