import React, { useEffect }  from "react";
import { Helmet } from "react-helmet";
import divider from "../assets/images/div.svg";
import dividerTop from "../assets/images/div2.svg";
import logo from "../assets/images/icon.svg";
import UnThankYou from "../components/UnThankYou/indexAreaManager";
import AreaManagerIT from "./AreaManagerIT";
import { Route } from "react-router-dom";
import PrivacyPolicyIT from "./PrivacyPolicyIT";
import TermsAndConditionsIT from "./TermsAndConditionsIT";
import Footer from "../components/Footer";
import PharmacyDataLoader from "../components/PharmacyDataLoaderRedirect";
import ViewAreaManager from "../components/AreaManager/ViewAreaManager";
import AddRep from "../components/AreaManager/AddRep";
import StepOneITAreaManager from "../components/StepOne/indexITAreaManager";
import AreaManagerITList from "./AreaManagerList";

const ItAreaManager = () => {
  
  useEffect(() => {
    document.title = "Unify Health Italia Area Manager";
    document.description = "Italia";
  }, []);

  return (
    <div className={'flex flex-col bg-blue-theme justify-center items-center min-h-screen h-full py-20'}>
      <Helmet>
        <title>Unify Health Italia Area Manager</title>
        <meta name="description" content="Italia" />
      </Helmet>

      <div className="hidden md:fixed bottom-0 left-1 md:left-16">
        <img src={divider} alt="" />
      </div>

      <div className="hidden md:fixed md:flex top-0 right-16">
        <img src={dividerTop} alt="" />
       
      </div>

      <img src={logo} alt="" className="mx-auto -mt-10 mb-10" />

      <Route exact path={'/areamanager/mainadmin'} component={AreaManagerITList} />
      <Route exact path={'/areamanager/home/:areaManagerId'} component={AreaManagerIT} />
      <Route exact path={'/areamanager/view'} component={ViewAreaManager} />
      <Route exact path={'/areamanager/add/rep/:areaManagerId'} component={AddRep} />
      <Route exact path={'/areamanager/pharmacy/edit/:id'}><PharmacyDataLoader /></Route>
      <Route exact path={'/areamanager/form/:areaManagerId'}><StepOneITAreaManager /></Route>
      <Route exact path={'/areamanager/thank-you'}><UnThankYou /></Route>
      <Route exact path={'/areamanager/privacy-policy'}><PrivacyPolicyIT /></Route>
      <Route exact path={'/areamanager/terms-and-conditions'}><TermsAndConditionsIT /></Route>
      <Footer path="areamanager" />
    </div>
  );
};

export default ItAreaManager;
