/* eslint-disable no-useless-escape*/
import React, { useState, useEffect } from "react";
// import defaultAva from "../../assets/images/defaultava.png";
import UnTextArea from "../UnTextArea";
import UnInput from "../UnInput";
import UnDropDown from "../UnDropDown";

import { useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import Map from "./Map";
import {useForm} from "react-hook-form";
import UnButton from "../UnButton";
import PlaceDetails from "./PlaceDetails";
import ImageUpload from "./ImageUpload";
import Loader from "../Loader";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import clsx from "clsx";
import { COUNTRY, LANGUAGE } from '../../screens/Form/constant';
import { ERROR_TEXT_CLASS } from "../../constants";
// import ImageInputWithCrop from "./ImageInputWithCrop";

const libraries = ['places'];

const StepOne = ({onSubmit = () => {}, data}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const { register, handleSubmit, formState: { errors }, setValue, getValues, clearErrors } = useForm({
    defaultValues: data
  });

  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const BODY_OF_REGISTRATION_OPTIONS = (() => {
      switch(LANGUAGE+COUNTRY){
        case 'enuk':
          return [{ label: 'UK (GPhC)', value: 'UK (GPhC)' }];
        case 'itit':
          return [{ label: 'Italia', value: 'Italia (AIFA)'}];
        case 'dede':
            return [
                    { 
                      label: 'Apothekenkammer Berlin', 
                      value: 'Apothekenkammer Berlin',
                    },
                    { 
                      label: 'Apothekenkammer Bremen', 
                      value: 'Apothekenkammer Bremen',
                    },
                    { 
                      label: 'Apothekenkammerdes Saarlandes', 
                      value: 'Apothekenkammerdes Saarlandes',
                    },
                    { 
                      label: 'Apothekenkammer Hamburg', 
                      value: 'Apothekenkammer Hamburg',
                    },
                    { 
                      label: 'Apothekenkammer Mecklenburg-Vorpommern', 
                      value: 'Apothekenkammer Mecklenburg-Vorpommern',
                    },
                    { 
                      label: 'Apothekenkammer Niedersachsen', 
                      value: 'Apothekenkammer Niedersachsen',
                    },
                    { 
                      label: 'Apothekenkammer Nordrhein', 
                      value: 'Apothekenkammer Nordrhein',
                    },
                    { 
                      label: 'Apothekenkammer Schleswig-Holstein', 
                      value: 'Apothekenkammer Schleswig-Holstein',
                    },
                    { 
                      label: 'Apothekenkammer Westfalen-Lippe', 
                      value: 'Apothekenkammer Westfalen-Lippe',
                    },
                    { 
                      label: 'Bayerische Landesapothekenkammer', 
                      value: 'Bayerische Landesapothekenkammer',
                    },
                    { 
                      label: 'Landesapothekenkammer Baden-Wüttenberg', 
                      value: 'Landesapothekenkammer Baden-Wüttenberg',
                    },
                    { 
                      label: 'Landesapothekenkammer Brandenburg', 
                      value: 'Landesapothekenkammer Brandenburg',
                    },
                    { 
                      label: 'Landesapothekenkammer Hessen', 
                      value: 'Landesapothekenkammer Hessen',
                    },
                    { 
                      label: 'Landesapothekenkammer Rheinland-Pfalz', 
                      value: 'Landesapothekenkammer Rheinland-Pfalz',
                    },
                    { 
                      label: 'Landesapothekenkammer Thüringen', 
                      value: 'Landesapothekenkammer Thüringen',
                    },
                    { 
                      label: 'Sächsische Landesapothekenkammer', 
                      value: 'Sächsische Landesapothekenkammer',
                    }
                  ];
        default:
          return [{ label: 'UK (GPhC)', value: 'UK (GPhC)' }];
      }
    })();

  let nameRegex;
  switch(LANGUAGE+COUNTRY){
    case 'enuk':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ]+$/i;
      break;
    case 'itit':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
      break;
    case 'dede':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
      break;
    default:
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ]+$/i;
  }
  
  const placeIdInFormHook = getValues('placeId');
  const imageName = getValues('photo');
  const lat = getValues('lat');
  const lng = getValues('lng');
  const address = getValues('address');
  const place_id = getValues('placeId');

  useEffect(() => {
    setPlaceId(placeIdInFormHook)
  }, [placeIdInFormHook])

  const [placeId, setPlaceId] = useState('');
  const [pharmacyPopulated, SetPharmacyPopulated] = useState(false);
  const [error, setError] = useState(null);

  if (loadError) {
    return "Error loading maps";
  }
  if (!isLoaded) {
    return <div className={"flex justify-center"}><Loader /></div>;
  }


  const addressSelect = ({place_id, lat, lng, address, services, whereYouWork }, isPharmacyExists = false) => {
    setValue('placeId', place_id);
    setValue('lat', lat);
    setValue('lng', lng);
    setValue('address', address);
    setPlaceId(place_id);
    if(isPharmacyExists){
      SetPharmacyPopulated(true);
      setValue('whereYouWork', whereYouWork);
      setValue('services', services);
    }else if(pharmacyPopulated){
      SetPharmacyPopulated(false);
      setValue('whereYouWork', '');
      setValue('services', '');
    }
  }

  const resetPlace = () => {
    setPlaceId('');
    setValue('placeId', '');
  }

  const handleImageUpload = (event) => {
    setError(null);
    const files = event.target.files;
    const file = files[0];
    if (!file || (!file.type.includes('pdf') && !file.type.includes('jpeg') && !file.type.includes('png') && !file.type.includes('heic')) ) {
      return setError(t('step1.photoUploadWrongFormat'));
    }
    const fileSize = ((event.target.files[0].size/1024)/1024).toFixed(4); // MB
    if (fileSize > 5) {
      return setError(t('step1.photoUploadError3'));
    }
    clearErrors('file');
    setValue('files',file)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type={'hidden'} {...register('placeId', { required: true })} />
      <input type={'hidden'} {...register('lat', { required: true })} />
      <input type={'hidden'} {...register('lng', { required: true })} />
      <input type={'hidden'} {...register('address', { required: true })} />

      <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-12">
        * Obligatorisches Feld
      </p>

      <UnDropDown
        hasError={!!errors.haveYouCompleted}
        control={register('haveYouCompleted', { required: true})}
        labelWithLink={
          <div className="flex md:flex-wrap" style={{whiteSpace: "pre-wrap", display: "flow-root" ,'word-wrap': 'break-word'}}>
            <Trans i18nKey="step1.questionOncodemiaCertificate" > 
              <label htmlFor="" className="mb-4">
              Have you successfully completed the
              </label>
              <a className="underline font-bold text-blue-theme" href={t('step1.oncodemiaLink')}  target={"_blank"} rel={"noreferrer"}>
                Oncodemia training              
              </a>
              <label htmlFor="" className="mb-4">
                and downloaded a certificate?
              </label>
            </Trans>
          </div>
        }
        options={[
          {
            label: t('step1.yes'),
            value: 'yes',
          },
          {
            label: t('step1.no'),
            value: 'no',
          },
        ]}
      />

      <UnInput
        hasError={!!errors.email}
        control={register('email', {
          required: true,
          pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        })}
        label={t('step1.emailAddress')}
        placeholder={t('step1.emailPlaceHolder')}
        note={t('step1.emailNote')}
      />

      <div className="flex flex-col md:flex-row justify-between">
        <UnInput
          hasError={!!errors.firstName}
          control={register('firstName', {required: true, pattern: nameRegex })}
          fullWidth={false} label={t('step1.firstName')} placeholder={t('step1.firstNamePlaceHolder')} />
        <UnInput
          hasError={!!errors.lastName}
          control={register("lastName", {required: true, pattern: nameRegex })}
          fullWidth={false}
          label={t('step1.lastName')}
          placeholder={t('step1.lastNamePlaceHolder')}
          styles="md:ml-4"
        />
      </div>

      {/* <div className="flex flex-col md:flex-row justify-between">
        <UnDropDown
          hasError={!!errors.region}
          control={register('region', {required: true})}
          label={t('step1.registrationCountry')}
          options={BODY_OF_REGISTRATION_OPTIONS}
          fullWidth={false}
        />
      </div> */}

      <div className={clsx("flex flex-col mt-4 mb-6 w-full")}>
        <label htmlFor={'registrationNumber'} className="mb-2">
          {t('step1.registrationNumber')}
        </label>
        <p className="opacity-40 text-xs tracking-normal mb-4 leading-normal w:full md:w-3/4">
          Nach dem Absenden des Formulars wird diese Datei zur Überprüfung der Urkunde an ein Mitglied des Unify Health-Teams gesendet. Gleich nach der Überprüfung der Urkunde wird diese Datei gelöscht und nicht länger gespeichert.
          Akzeptierte Dateiformate: jpg, png, pdf. Maximale Dateigröße: 5MB
        </p>
        <input type="file" id="fileUpload" accept=".pdf, .jpg, .jpeg, .png, .heic" {...register('file', {required: true})} onChange={handleImageUpload} />
        {(error || errors.file) && (<p className={clsx(ERROR_TEXT_CLASS, 'pt-2')}>{error ? error : t('step1.required')}</p>)}
      </div>

      <ImageUpload setFormValue={setValue} imageName={imageName} />

      <UnInput
        hasError={!!errors.whereYouWork}
        control={register('whereYouWork', {required: true})}
        label={t('step1.questionPharmacyName')}
        placeholder={t('step1.pharmacyNamePlaceHolder')}
        note={t('step1.pharmacyNameNote')}
      />

      <p className="font-bold text-lg mt-8 mb-4 text-blue-theme h">
        {t('step1.findPlaceId')}
      </p>
      {(!!errors.placeId && !placeId) && ( <div className={'pt-4 text-red-600'}> <p>{t('step1.required')}</p> </div> )}
      {placeId && <PlaceDetails data={getValues()} reset={resetPlace} />}
      {<Map onAddressSelect={addressSelect} isPlaceId={!!errors.placeId && !placeId} lat={lat} lng={lng} address={address} place_id={place_id} className="relative w-full" />}

      <UnTextArea
        control={register('services')}
        label={t('step1.questionServicesPharmacyProvide')}
        placeholder=""
        note={t('step1.servicesPharmacyProvideNote')}
      />

      {/*<ImageInputWithCrop setPreview={setValue} />*/}
      
      {/* <UnTextArea
        hasError={!!errors.anythingElseAboutYou}
        control={register('anythingElseAboutYou')}
        label={t('step1.questionAnythingToKnowAbout')}
        placeholder=""
        note={t('step1.anythingToKnowAboutNote')}
      /> */}

      <div className={'flex flex-col mt-10'}>
        <UnButton
          title={t('registration.next')}
          type={'submit'}
          fullWidth={false}
          position="right"
          disabled={error}
        />
      </div>

    </form>
  );
};

export default StepOne;
