export const isProduction = (window.location.host === 'apply.unifyhealth.app' || window.location.host === 'registrati.unifyhealth.app' || window.location.host === 'registrierung.unifyhealth.app');
// TODO - add german site
export let API_DOMAIN = isProduction ? 'https://cms.unifyhealth.app': 'https://cms-dev.unifyhealth.app';
// API_DOMAIN = ((window.location.host).indexOf('localhost') !== -1) ? 'https://unifycmslocaldeve.ddev.site:8443' : API_DOMAIN;

if ((window.location.host).indexOf('localhost') !== -1) {
    process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = 0
}

export const SAVE_ENDPOINT = API_DOMAIN + '/api/save-pharmacist';
export const GET_ENDPOINT = API_DOMAIN + '/api/get-pharmacist?id=##id##';
export const IMAGE_UPLOAD_ENDPOINT = API_DOMAIN + '/api/save-temp-pharmacist-image';
export const IMAGE_BASE_URL = API_DOMAIN + '/sites/default/files/';
export const CHECKPHARMACY_ENDPOINT = API_DOMAIN + '/api/check_pharmacy';

export const AREAMANAGER_ADD_REPS_ENDPOINT = API_DOMAIN + '/api/areamanager-add-rep';
export const AREAMANAGER_GET_REPS_ENDPOINT = API_DOMAIN + '/api/areamanager-get-reps?id=##id##';
export const AREAMANAGER_GET_PHARMACIES_ENDPOINT = API_DOMAIN + '/api/areamanager-get-pharmacies?id=##id##';
export const AREAMANAGER_SAVE_ENDPOINT = API_DOMAIN + '/api/areamanager-create-pharmacy';
export const AREAMANAGER_LIST = API_DOMAIN + '/api/mainadmin-get-areamanagers';

export const SALES_REP_GET_PHARMACIES_ENDPOINT = API_DOMAIN + '/api/rep-get-pharmacies';
export const SALES_REP_SAVE_ENDPOINT = API_DOMAIN + '/api/rep-update-pharmacy';
export const SALES_REP_GET_PHARMACY_ENDPOINT = API_DOMAIN + '/api/rep-get-pharmacy?id=##id##';
export const GET_PHARMACY_LIST_ENDPOINT = API_DOMAIN + '/api/v1/register/rep/pharmacies?assignedreptoken=##id##';

export const ERROR_CLASS_NAME = 'border-red-600';
export const ERROR_TEXT_CLASS = 'text-red-600';
