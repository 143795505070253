import React from 'react';
import UnButton from "../../components/UnButton";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../App.css';

const BecomeAMember = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12 h-3/6 overflow-y-scroll">
        <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto">
        Gentile Farmacista: la compilazione di
questo modulo permette ai pazienti
oncologici di trovarti!
        </h1>
        <ul className="mt-6 md:pl-8 list-decimal mb-6">
          <li className="text-blue-theme tracking-wide leading-normal mb-6">
          Compilando questo modulo puoi presentare domanda affinché la
farmacia dove lavori possa essere visualizzata sull’ App Unify
Health come:
          </li>
          <ul className="mt-6 md:pl-8 no-disc mb-6" style={{alignItems:"center",justifyContent:"center"}}>
            <li className="text-blue-theme tracking-wide leading-normal mb-6 gold-pin">
              <span style={{top:'-5px', position:'relative'}}>
              farmacie Unify health, dopo aver completato il corso online
Oncodemia e aderito al Network Unify Health tramite
contratto dedicato
              </span>
            </li>
            {/* <li className="text-blue-theme tracking-wide leading-normal mb-6 gold-pin">
              <span style={{top:'-5px', position:'relative'}}>
                Farmacie Unify Salute, dopo aver completato il corso FAD Oncodemia e aderito al Network Unify Salute tramite contratto dedicato
              </span>
            </li> */}
          </ul>
          <li className="text-blue-theme tracking-wide leading-normal">
          I pazienti oncologici possono localizzare le farmacie Unify health
a loro più vicine grazie all’<b>app mobile Unify Health</b>, che
visualizzerà e renderà disponibile ai pazienti il profilo della tua
farmacia.
          </li>
        </ul>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4">
        Se hai raggiunto questa pagina dovresti aver completato un percorso di
formazione relativo all’assistenza ai pazienti in oncologia: Oncodemia.
        </p>
        {/* <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4">
          Oncodemia è un corso FAD ECM, organizzato dal Provider ECM IdeA-Z Project in progress, segreteria organizzativa BOLD AD, con la sponsorizzazione non condizionante di Accord Healthcare.
        </p> */}
      </div>
      <div style={{marginTop:40}}>
        <UnButton
          title={t('becomeMember.button.continue')}
          fullWidth={false}
          position="center"
          onClick={() => history.push('/form')}
        />
      </div>
    </>

  );
};

export default BecomeAMember;