import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import ErrorMessage from "../Form/ErrorMessage";
import { useTranslation } from 'react-i18next';
import UnButton from "../../components/UnButton";
import { GET_PHARMACY_LIST_ENDPOINT } from '../../constants';
import '../../App.css';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const RepIT = () => {
  const params = useParams()
  const history = useHistory();
  const [data, setDataPharmacy] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const repListId = params.repListId ? (params.repListId * 1) : null;

  useEffect(() => {
    fetchPharmacyData();
  }, [repListId])

  const fetchPharmacyData = async() =>{
    try {
      setHasError(false);
      setIsLoading(true);
      const response = await fetch(GET_PHARMACY_LIST_ENDPOINT.replace('##id##', repListId));
      const result = await response.json();
      if(result){
        setDataPharmacy(result);
      }else{
        setHasError(true);
        setErrorMessage(t('rep.NopharmcyList'));
      }
      setIsLoading(false)
    } catch (error) {
      setHasError(true);
      setIsLoading(false);
    }
  }

  const screenHandler = () => {
    if(isLoading){
      return(
        <div className={'flex mx-auto'}>
          <Loader />
        </div>
      )
    }else if(hasError){
        return(
          <ErrorMessage errorMessage={errorMessage} />
        )
    }else{
      if(data.length == 0){
        return(
          <h1 className="text-xl text-blue-theme tracking-relax mx-auto mt-10">
            {t('rep.NopharmcyList')}
          </h1>
        )
      }
      return (
        <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-12/12 md:w-12/12 h-3/6 overflow-y-scroll">
          <ul className="mt-6 md:pl-8 list-decimal mb-6">
            {
              data.map((details)=>{
                const { fullAddress, pharmacyName, id} = details;
                return(
                  <li className="text-blue-theme tracking-wide leading-normal mb-6">
                  <div style={{display: 'flex',flexDirection:'row', flex: 1.0, backgroundColor: 'transparent'}}>
                    <div style={{flex: 0.8,marginRight:10}}>{pharmacyName}, {fullAddress}</div>
                    <div style={{flex: 0.2}}>
                      <UnButton
                        title={t('rep.button.edit')}
                        fullWidth={false}
                        position="center"
                        onClick={() => history.push('/rep/form?pharmacyId=' + id)}
                      />
                    </div>
                  </div>
                </li>
                )
              })
            }
          </ul>
        </div>        
      );
    }
  }

  return (
    <div className="flex flex-col py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
      <h1 className="text-xl text-blue-theme font-bold tracking-relax mx-auto">
        {t('rep.pharmcyList')}
      </h1>
      {screenHandler()}
    </div>
  )
}

export default RepIT;
