import React from 'react';
import { useTranslation } from 'react-i18next';

const PlaceDetails = ({data, reset}) => {
  const { t } = useTranslation();
  return (
    <div className={'bg-gray-300 rounded p-5'}>
      <p>
        {t('step1.placeId')}: <b>{data.placeId}</b><br />
        {t('step1.selectedAddress')}: <b>{data.address}</b>
      </p>
    </div>
  )
}

export default PlaceDetails;
