import React, { useEffect } from 'react';
import {useHistory, useParams} from "react-router-dom";
import { COUNTRY, LANGUAGE, SITE } from '../../screens/Form/constant';
import Loader from "../Loader";

const PharmacyDataLoaderRedirect = () => {
  const {id} = useParams();
  const history = useHistory();

  useEffect(() => {
      switch (LANGUAGE+COUNTRY+SITE) {
        case 'enukdefault':
          history.push(`/form?pharmacistId=${id}`)
          break;
        case 'ititdefault':
          history.push(`/form?pharmacistId=${id}`)
          break;
        case 'ititareamanager':
          history.push(`/areamanager/form?pharmacistId=${id}`)
          break;
        case 'ititrep':
          history.push(`/rep/form?pharmacyId=${id}`)
          break;
        default:
          history.push(`/form?pharmacistId=${id}`)
          break;
      }
  }, [history, id])

  return <Loader />;
};

export default PharmacyDataLoaderRedirect;
