import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LANGUAGE } from "../screens/Form/constant";
import en from './languages/en.json';
import it from './languages/it.json';
import de from './languages/de.json';

const resources = {
  en:{translation:en},
  it:{translation:it},
  de:{translation:de},
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: LANGUAGE, // language to use
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

