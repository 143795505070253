import React, { useState, useEffect } from 'react';
import UnButton from "../../components/UnButton";
import {useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../App.css';
import Loader from '../../components/Loader';
import { AREAMANAGER_LIST } from '../../constants';
import ErrorMessage from '../Form/ErrorMessage';

const AreaManagerITList = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [isDataLoading, setIsLoading] = useState(false);
  const [areaManagerData, setareaManagerData] = useState([]);
  const [areaManagerDataError, setHasError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
      getAreaManagerList();
    }, []);
     
  const getAreaManagerList = async()=>{
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await fetch(AREAMANAGER_LIST);
      const result = await response.json();
      if(result?.data){
        setareaManagerData(result.data);
      }else{
        setHasError(true);
        setErrorMessage(t('areaManager.NoareaManager'));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      setErrorMessage(t('areaManager.networkError'));
    }
  }

  const screenHandler = () => {
    if(isDataLoading){
      return(
        <div className={'flex mx-auto'}>
          <Loader />
        </div>
      )
    }else if(areaManagerDataError){
        return(
          <ErrorMessage errorMessage={errorMessage} />
        )
    }else{
      if(areaManagerData.length == 0){
        return(
          <h1 className="text-xl text-blue-theme tracking-relax mx-auto mt-10">
            {t('areaManager.NoareaManager')}
          </h1>
        )
      }
      return (
          <ul className="mt-6 md:pl-8 list-decimal mb-6">
            { areaManagerData.map( armnrData =>{
                const { id, email, firstName, lastName } = armnrData;
                return(
                  <li className="text-blue-theme tracking-wide leading-normal mb-6" key={id}>
                    <div style={{display: 'flex',flexDirection:'row', flex: 1, backgroundColor: 'transparent'}}>
                      <div style={{flex: 0.8, justifyContent:'center',alignItems:'center'}}>
                        <h1>{firstName + ' ' + lastName}</h1>
                        <p>{email}</p>
                        <p>Id: {id}</p>
                      </div>
                      <div style={{flex: 0.2, marginLeft:6}}>
                        <UnButton
                          title={t('areaManager.button.view')}
                          fullWidth={false}
                          position="center"
                          onClick={() => history.push('/areamanager/home/' + id )}
                        />
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        );
      }
    }

  return (
    <>
      <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-10/12 md:w-8/12 h-3/6 overflow-y-scroll">
        <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto">
        {t('areaManager.areaManagerList')}
        </h1>
        {screenHandler()}
      </div>
    </>
  );
};

export default AreaManagerITList;
