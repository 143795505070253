import React from 'react';
import UnButton from "../../components/UnButton";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
//import { Trans } from 'react-i18next';
import { COUNTRY, LANGUAGE } from '../Form/constant';
import community1 from "../../assets/images/community1.svg";
import community2 from "../../assets/images/community2.svg";
import community3 from "../../assets/images/community3.svg";
import community4 from "../../assets/images/community4.svg";
import community5 from "../../assets/images/community5.svg";
import community6 from "../../assets/images/community6.svg";
import community7 from "../../assets/images/community7.svg";
import community8 from "../../assets/images/community8.svg";
import community9 from "../../assets/images/community9.svg";
import community10 from "../../assets/images/community10.svg";


const BecomeAMember = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const onContinue = () =>{
    switch (LANGUAGE+COUNTRY) {
      case 'enuk':
        history.push('/form');
        break;
      default:
        history.push('/form/1');
        break;
    }
  }

  const communityData = [
    {
      id: 1,
      description: 'I will take time to listen to patient concerns, treat them with respect and empathy and ensure confidentiality is maintained',
      image : community1,
    },
    {
      id: 2,
      description: 'Where optimizing medicines is appropriate, I will take a patient-centred approach in doing so, with the aim of helping improve outcomes',
      image : community2,
    },
    {
      id: 3,
      description: 'I will signpost patients to local support groups and other community services where relevant',
      image : community3,
    },
    {
      id: 4,
      description: 'I will encourage and support medicines concordance and will provide the most up-to-date information and advice on OTC and prescribed medicines when requested',
      image : community4,
    },
    {
      id: 5,
      description: 'I will provide advice to patients on quality of life issues and, where appropriate, how to improve their self-care',
      image : community5,
    },
    {
      id: 6,
      description: 'I will promote and engage in cancer awareness and preventative campaigns',
      image : community6,
    },
    {
      id: 7,
      description: 'I will provide support and advice to carers and families affected by cancer, if requested',
      image : community7,
    },
    {
      id: 8,
      description: 'I will always seek to extend my understanding of cancer and will undertake ongoing learning in this evolving field',
      image : community8,
    },
    {
      id: 9,
      description: 'I will counsel patients on the safe handling, storage, and disposal of anti-cancer medications, and if requested, will support them in the safe disposal of those medications',
      image : community9,
    },
    {
      id: 10,
      description: 'I will refer patients to other healthcare professionals and healthcare organizations, as appropriate',
      image : community10,
    },
  ]

  return (
    <>
      <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12 h-3/6">
        <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
          {t('becomeMember.heading')}
        </h1>
        {/* <Trans i18nKey="becomeMember.whoAreChamps"> */}
          <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4">
          <strong>Community Cancer Champions</strong> are community pharmacists that have committed to a Charter that champions helping people with cancer in the community.
          </p>
          {/* </Trans>
          <Trans i18nKey="becomeMember.howToBecomeChamps"> */}
          <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4">
           Becoming a Community Cancer Champion means making yourself available to cancer patients in your community by sharing your professional and pharmacy details. Cancer patients can find you
and seek out your advice through the <a className="underline font-bold text-blue-theme" href={"http://unifyhealth.app"} target={"_blank"} rel={"noreferrer"}>Unify Health</a> patient app, where they can also log
symptoms to share with you.
          </p>
        {/* </Trans> */}
        <p className="text-blue-theme text-sm md:text-base tracking-wide opacity-70">
         {t('becomeMember.stepsToBecomeChap.heading')}
        </p>
        <ul className="mt-6 md:pl-8 list-decimal">
          {/* <Trans i18nKey="becomeMember.stepsToBecomeChap.one"> */}
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
          Register and complete <a className="underline font-bold text-blue-theme" href="https://www.oncodemia.com/login/index.php" target="_blank" rel="noreferrer">Oncodemia</a> course, which includes content exploring what the Community Cancer Champion Charter means to your day-to-day pharmacy work
            </li>
            
          {/* </Trans> */}
          {/* <Trans i18nKey="becomeMember.stepsToBecomeChap.two"> */}
          <li className="text-blue-theme tracking-wide leading-normal">
           {/* {t('becomeMember.stepsToBecomeChap.two')} */}
             

              Complete this application form including details of your professional registrations and work location, and sign the Charter commitments
           
           
          </li>
            {/* </Trans> */}
        </ul>


        <div className="flex flex-col mt-6 -mx-10 -mb-10 px-10 py-6 bg-yellow-2-theme">
          <div className="">
            <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto">
              Community Cancer Champion Charter Commitments
            </h1>
          </div>
            <div className="flex flex-col">
              <div className="flex flex-wrap">
                { communityData.map( data => {
                    return(
                      <div className="flex flex-col w-2/4 lg:w-1/5 mt-6 items-center lg:items-start" key={data.id}>
                        <div className="ml-0 lg:ml-2 mr-4">
                          <div className="h-8 w-8 lg:h-10 lg:w-10 bg-yellow-4-theme rounded-full flex justify-center items-center">
                            <h1 className="text-base xl:text-lg text-blue-theme font-bold">
                              {data.id}
                            </h1>
                          </div>
                          <div className="bg-yellow-3-theme flex justify-center items-center rounded-full h-20 w-20 xl:h-24 xl:w-24">
                            <img src={data.image} alt="" className="h-8 w-8 lg:h-10 lg:w-10" />
                          </div>
                        </div>
                        <div className="mt-4 mr-4">
                          <p className="text-blue-theme text-xs md:text-sm tracking-wide text-left leading-tight">
                            {data.description}
                          </p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            <div>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:40}}>
        <UnButton
          title={t('becomeMember.button.continue')}
          fullWidth={false}
          position="center"
          onClick={onContinue}
        />
      </div>
    </>

  );
};

export default BecomeAMember;