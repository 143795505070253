import React from 'react';
import '../../../App.css';
import { useHistory } from "react-router-dom";
import UnButton from '../../UnButton';
import { useTranslation } from 'react-i18next';

const ViewAreaManager = (props) => {

  const history = useHistory();
  const { t } = useTranslation();

  const viewData = props?.location?.state?.phrData ? props?.location?.state?.phrData : {};

  const { callHistory, currentCallFlag, pharmacyName, id } = viewData;

  const goBack = () => {
    history.goBack()
  }

  function capitalizeEachWord(string){
    const str_arr = string.split(' ')

    for(var i = 0; i < str_arr.length; i++){
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1)
    }
    return str_arr.join(' ')
}


  return (
    <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12 h-3/6 overflow-y-scroll">
      <p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Back"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
      <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto">
        {pharmacyName}
      </h1>
      <p className="flex flex-wrap font-bold text-blue-theme text-xl md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4 mt-12">
      {t('areaManager.callFlagStatus')} {currentCallFlag ? capitalizeEachWord(currentCallFlag.replace(/_/g, ' ')) : null}
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4 font-bold">
       {t('areaManager.callFlagHistry')}
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4" style={{
          whiteSpace: 'pre-line'
      }}>
        {/* {callHistory.replace(/<br \/>/g, '')} */}
      </p>
    </div>
  );
};

export default ViewAreaManager;
