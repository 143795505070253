import React, { useEffect } from "react";
import clsx from "clsx";
import {useForm} from "react-hook-form";
import UnButton from "../UnButton";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import {ERROR_CLASS_NAME} from "../../constants";
import {useQuery} from "../../screens/Form";

const StepTwo = ({data, onSubmit = () => {}}) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory()
  const query = useQuery();

  const { t } = useTranslation();

  const pharmacistId = query.get('pharmacistId');
  const queryForRedirect = pharmacistId ? '?pharmacistId=' + pharmacistId : '';
  useEffect(() => {
    if (!data.firstName) {
      history.push('/form/1' + queryForRedirect)
    }
  }, [data, history, queryForRedirect])
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Trans i18nKey="step2.PharmacyGoogleListUpToDate">
        <p className="text-blue-theme break-words flow-root">
          Your pharmacy’s listing on Unify Health will be supplemented with further information from your Pharmacy's Google profile, such as opening times, exterior photos, number of pharmacists in the pharmacy etc. You should check this information is up to date at
        </p>
        <a className="underline font-bold text-blue-theme" style={{'word-wrap': 'break-word'}} href={"https://business.google.com/create"} target={"_blank"} rel={"noreferrer"}>https://business.google.com/create.</a>
      </Trans>
      <div className={clsx(
        'flex items-center mr-8 pt-2 pb-2',
        {[ERROR_CLASS_NAME]:errors.isChecked, 'border-b' : errors.isChecked}
      )}>
        <input
          id={'agree'}
          type="checkbox"
          className={'radio border-yellow-theme border-2'}
          { ...register('isChecked', {required: true}) }
        />
        <label htmlFor={'agree'} className="text-blue-theme ml-4">{t('step2.isPharmacyGoogleListUpToDate')}</label>
      </div>

      <div className={'flex flex-row items-center justify-end mt-10'}>
        <UnButton
          title={t('registration.previous')}
          fullWidth={false}
          onClick={() => history.goBack()}
          transparent={true}
          isTextSecondary={true}
        />
        <UnButton
          title={t('registration.next')}
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </div>

    </form>
  );
};

export default StepTwo;
