/* eslint-disable no-useless-escape*/
import React, { useState, useEffect, useRef } from "react";
// import defaultAva from "../../assets/images/defaultava.png";
import UnTextArea from "../UnTextArea";
import UnInput from "../UnInput";
import UnDropDown from "../UnDropDown";

import { useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import Map from "./Map";
import {useForm} from "react-hook-form";
import UnButton from "../UnButton";
import PlaceDetails from "./PlaceDetails";
import ImageUploadWithCamera from "./ImageUploadWithCamera";
import Loader from "../Loader";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { COUNTRY, LANGUAGE } from '../../screens/Form/constant';
import clsx from "clsx";
import {ERROR_CLASS_NAME} from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";

const libraries = ['places'];



const StepOne = ({onSubmit = () => {}, data}) => {

  const { t } = useTranslation();

  const whereDidYouFindOutAboutUs = [
    { label: t('step1.email'), value: 'Email' },
    { label: t('step1.socialMedia'), value: 'Social media' },
    { label: t('step1.numarkMember'), value: 'Numark member' },
    { label: t('step1.accordRepresentative'), value: 'Accord representative' },
    { label: t('step1.colleague'), value: 'Colleague' },
    { label: t('step1.other'), value: 'Other' },
  ];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState('');
  const reRef = useRef();

  const { register, handleSubmit, formState: { errors }, setValue, getValues, watch } = useForm({
    defaultValues: data
  });

  const agreementCheck1 = watch('agreementCheck1');
  const agreementCheck2 = watch('agreementCheck2');
  const agreementCheck3 = watch('agreementCheck3');
  const agreementCheck4 = watch('agreementCheck4');
  const agreementCheck5 = watch('agreementCheck5');
  const haveYouCompleted = watch('haveYouCompleted') === 'yes1';

  let nameRegex;
  switch(LANGUAGE+COUNTRY){
    case 'enuk':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
      break;
    case 'itit':
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
      break;
    default:
      nameRegex = /^[A-Za-zàèéìòóùÀÈÉÌÒÓÙ ]+$/i;
  }
  
  const placeIdInFormHook = getValues('placeId');
  const imageName = getValues('photo');
  const lat = getValues('lat');
  const lng = getValues('lng');
  const address = getValues('address');
  const place_id = getValues('placeId');

  useEffect(() => {
    setPlaceId(placeIdInFormHook)
  }, [placeIdInFormHook])

  const validateHuman = async () => {
    const token = await reRef.current.getValue();
    setToken(token);
  };

  useEffect(() => {
    setDisabled( !(token && agreementCheck1 && agreementCheck2 && agreementCheck3 && agreementCheck4 && agreementCheck5 ) )
  }, [token, agreementCheck1, agreementCheck2, agreementCheck3, agreementCheck4, agreementCheck5])

  const [placeId, setPlaceId] = useState('');
  const [pharmacyPopulated, SetPharmacyPopulated] = useState(false);

  if (loadError) {
    return "Error loading maps";
  }
  if (!isLoaded) {
    return <div className={"flex justify-center"}><Loader /></div>;
  }

  const addressSelect = ({place_id, lat, lng, address, services, whereYouWork }, isPharmacyExists = false) => {
    setValue('placeId', place_id);
    setValue('lat', lat);
    setValue('lng', lng);
    setValue('address', address);
    setPlaceId(place_id);
    if(isPharmacyExists){
      SetPharmacyPopulated(true);
      setValue('whereYouWork', whereYouWork);
      setValue('services', services);
    }else if(pharmacyPopulated){
      SetPharmacyPopulated(false);
      setValue('whereYouWork', '');
      setValue('services', '');
    }
  }

  const resetPlace = () => {
    setPlaceId('');
    setValue('placeId', '');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type={'hidden'} {...register('placeId', { required: true })} />
      <input type={'hidden'} {...register('lat', { required: true })} />
      <input type={'hidden'} {...register('lng', { required: true })} />
      <input type={'hidden'} {...register('address', { required: true })} />

      <UnInput
        hasError={!!errors.email}
        control={register('email', {
          required: true,
          pattern: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        })}
        label={t('step1.emailAddress')}
        placeholder={t('step1.emailPlaceHolder')}
        note={t('step1.emailNote')}
      />

      <div className="flex flex-col md:flex-row justify-between">
        <UnInput
          hasError={!!errors.firstName}
          control={register('firstName', {required: true, pattern: nameRegex })}
          fullWidth={false} 
          label={t('step1.firstName')} 
          placeholder={t('step1.firstNamePlaceHolder')} />
        <UnInput
          hasError={!!errors.lastName}
          control={register("lastName", {required: true, pattern: nameRegex })}
          fullWidth={false}
          label={t('step1.lastName')}
          placeholder={t('step1.lastNamePlaceHolder')}
          styles="md:ml-4"
        />
      </div>

      <div className="flex flex-col md:flex-row justify-between">
        <UnInput
          hasError={!!errors.professionalRegistrationNumber}
          control={register('professionalRegistrationNumber', {required: true, pattern: /^\d{3,7}$/})}
          fullWidth={false}
          label={t('step1.registrationNumber')}
          placeholder={t('step1.registrationNumberPlaceHolder')}
          note={t('step1.registrationNumberNote')}
        />
        <UnDropDown
          hasError={!!errors.areYouLocum}
          control={register('areYouLocum', {required: true})}
          label={t('step1.isLocum')}
          styles="md:ml-4"
          options={[
            {
              label: t('step1.yes'),
              value: "yes",
            },
            {
              label: t('step1.no'),
              value: "no",
            },
          ]}
          onChange={(e) => alert(e.target.value)}
          fullWidth={false}
        />
      </div>

      <UnDropDown
        hasError={!!errors.whereDidYouFindOutAboutUs}
        control={register('whereDidYouFindOutAboutUs', { required: true})}
        label={t('step1.whereDidYouFindOutAboutUs')}
        options={whereDidYouFindOutAboutUs}
        styles="mt-4"
      />

      <ImageUploadWithCamera setFormValue={setValue} imageName={imageName} />

      <p className="font-bold text-lg mt-8 mb-4 text-blue-theme h">
        {t('step1.findPlaceId')}
      </p>
      {(!!errors.placeId && !placeId) && ( <div className={'pt-4 text-red-600'}> <p>{t('step1.required')}</p> </div> )}

      {placeId && <PlaceDetails data={getValues()} reset={resetPlace} />}
      {<Map onAddressSelect={addressSelect} isPlaceId={!!errors.placeId && !placeId} lat={lat} lng={lng} address={address} place_id={place_id} className="relative w-full" />}

      <UnInput
        hasError={!!errors.whereYouWork}
        control={register('whereYouWork', {required: true})}
        label={t('step1.questionPharmacyName')}
        placeholder={t('step1.pharmacyNamePlaceHolder')}
        note={t('step1.pharmacyNameNote')}
      />

      <Trans i18nKey="step2.PharmacyGoogleListUpToDate">
        <p className="text-blue-theme break-words flow-root">
          Your pharmacy’s listing on Unify Health will be supplemented with further information from your Pharmacy's Google profile, such as opening times, exterior photos, number of pharmacists in the pharmacy etc. You should check this information is up to date at
        </p>
        <a className="underline font-bold text-blue-theme" style={{'word-wrap': 'break-word'}} href={"https://business.google.com/create"} target={"_blank"} rel={"noreferrer"}>https://business.google.com/create.</a>
      </Trans>
      <div className={clsx(
        'flex items-center mr-8 pt-2 pb-2',
        {[ERROR_CLASS_NAME]:errors.isChecked, 'border-b' : errors.isChecked}
      )}>
        <input
          id={'agree'}
          type="checkbox"
          className={'radio border-yellow-theme border-2'}
          { ...register('isChecked', {required: true}) }
        />
        <label htmlFor={'agree'} className="text-blue-theme ml-4">{t('step2.isPharmacyGoogleListUpToDate')}</label>
      </div>

      <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal mt-8">
          {t('step3.nandatoryConfirmations.heading')}
        </p>
        <ul className="mt-6 mb-10 md:pl-4">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('agreementCheck1', {required: true})} id={'agreementCheck1'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'agreementCheck1'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.one')}</label>
            {!!errors.agreementCheck1 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">
              <input {...register('agreementCheck2', {required: true})} id={'agreementCheck2'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
              <label htmlFor={'agreementCheck2'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.two')}</label>
              {!!errors.agreementCheck2 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
            </li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">
                <input {...register('agreementCheck3', {required: true})} id={'agreementCheck3'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
                <label htmlFor={'agreementCheck3'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.three')}</label>
                {!!errors.agreementCheck3 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
              </li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('agreementCheck4', {required: true})} id={'agreementCheck4'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'agreementCheck4'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.four')}</label>
            {!!errors.agreementCheck4 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('agreementCheck5', {required: true})} id={'agreementCheck5'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'agreementCheck5'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.five')}</label>
            {!!errors.agreementCheck5 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
        </ul>

      <UnDropDown
        hasError={!!errors.haveYouCompleted}
        control={register('haveYouCompleted', { required: true})}
        label={t('step1.questionPostGraduate')}
        options={[
          {
            label: t('step1.yes1'),
            value: 'yes1',
          },
          {
            label: t('step1.yes2'),
            value: 'yes2',
          },
          {
            label: t('step1.no'),
            value: 'no',
          },
        ]}
        styles="mt-4"
      />

      { haveYouCompleted && 
        <UnInput
          hasError={!!errors.additionalTrainingNotes}
          control={register('additionalTrainingNotes', { required: haveYouCompleted })}
          label={t('step1.additionalTrainingLabel')}
          placeholder={''}
          note={t('step1.additionalTrainingNote')}
        />
      }

      <UnTextArea
        hasError={!!errors.anythingElseAboutYou}
        control={register('anythingElseAboutYou')}
        label={t('step1.questionAnythingToKnowAbout')}
        placeholder=""
        note={t('step1.anythingToKnowAboutNote')}
      />

      <UnTextArea
        control={register('services')}
        label={t('step1.questionServicesPharmacyProvide')}
        placeholder=""
        note={t('step1.servicesPharmacyProvideNote')}
      />

      <div className="flex justify-center mt-14">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
          size="normal"
          ref={reRef}
          onChange={validateHuman}
        />
      </div>

      <div className={'flex flex-col'}>
        <UnButton
          disabled={disabled}
          title={t('registration.submit')}
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </div>

    </form>
  );
};

export default StepOne;
