import React from 'react';
import UnButton from "../../components/UnButton";
import {useHistory} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const PrivacyPolicy = () => {
  const {goBack} = useHistory();
  return (
    <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
      <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">INFORMATIVA SULLA PRIVACY E SUI COOKIE</h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">La presente è l’informativa sulla privacy e sui cookie per la registrazione dei farmacisti qualificabili “Oncospecialist” o “Unify Salute” sul sito &nbsp;<a className="underline font-bold text-blue-theme" href={"https://registrati.unifyhealth.app"} target={"_blank"} rel={"noreferrer"}>registrati.unifyhealth.app</a> (“<b>Application Site</b>”). L’Application Site è gestita da o per conto di Accord Healthcare Limited (“<b>noi</b>”, “<b>ci</b>” o “<b>nostro/a</b>”).</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">La presente informativa stabilisce come raccogliamo e utilizziamo i dati personali dell’utente in relazione alla sua registrazione sull’Application Site. L’informativa disciplina:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#InformationCollectAboutYou">I dati che raccogliamo e come li usiamo</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#CookiesAndOtherTech">I cookie e le altre tecnologie</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#Communications">Aggiornamenti promozionali e comunicazioni</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#WhoWeGiveInfo">A chi trasmettiamo i dati</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#WhereWeStoreInfo">Dove conserviamo e trasferiamo i dati</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#HowWeProtectInfo">Come proteggiamo i dati</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#ChildSafety">La sicurezza dei minori</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#HowLongWeKeepInfo">Per quanto tempo conserviamo i dati</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#YourRights">I diritti dell’utente</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#ChangesToPolicy">Le modifiche alla presente policy</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><HashLink className="underline font-bold text-blue-theme" smooth to="#ContactUs">Come contattarci</HashLink></li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Ci impegniamo a proteggere e rispettare la privacy dell’utente. Raccomandiamo di leggere attentamente quanto segue per comprendere le nostre policy e le procedure riguardanti il trattamento dei dati personali dell’utente.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Accettando i nostri servizi e registrandosi all’Application Site, l’utente dichiara di aver letto e compreso la presente informativa sulla privacy.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Ai fini della normativa sulla protezione dei dati personali in vigore nel Regno Unito nei paesi dello Spazio Economico Europeo (“<b>Legge sulla Protezione dei Dati Personali</b>”), il titolare del trattamento è: Accord Healthcare Limited, con sede in Sage House, 319 Pinner Road, North Harrow, Middlesex, HA1 4HF (Regno Unito) e il nostro Rappresentante Europeo è, con sede legale in.</p>
      <h1 id="InformationCollectAboutYou" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        I dati che raccogliamo e come li usiamo
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Raccoglieremo e tratteremo i seguenti dati personali dell’utente:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal font-bold mb-4">Dati forniti dall’utente</li>
          <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">Si tratta dei dati che l’utente inserisce direttamente quando compila il form nell’Application Site o interagendo con noi tramite email, telefono o altro dispositivo. Ciò include i dati forniti dall’utente quando:</p>
          <ul className="mt-6 md:pl-8 list-disc">
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Si registra all’Application Site per essere designato farmacista “Oncospecialist” o “Unify Salute” (“<b>Application</b>”) per apparire tra le farmacie geolocalizzate nell’App Unify Health, al fine di essere localizzato dai pazienti oncologici che si trovano nella sua zona.</li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Invia domande o segnala un problema riscontrato con l’Application Site.</li>
            </ul>
        </ul>
        <p className="flex flex-wrap text-blue-theme italic text-sm md:text-base tracking-wide leading-normal">Per saperne di più</p>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4"><b>Dati raccolti tramite l’utilizzo dell’Application Site da parte dell’utente </b></li>
          <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">Ogni volta che l’utente farà accesso all’Application Site verranno raccolti automaticamente i relativi dati di utilizzo. Ciò include:</p>
          <ul className="mt-6 md:pl-8 list-disc">
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Informazioni tecniche – associate al device e al browser dell’utente.</li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Informazioni di sessione – raccolte dall’interazione dell’utente con l’Application Site.</li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Dati sulla posizione.</li>
            </ul>
        </ul>
        <p className="flex flex-wrap text-blue-theme italic text-sm md:text-base tracking-wide leading-normal">Per saperne di più</p>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal font-bold mb-4">Informazioni che riceviamo da altre fonti</li>
          <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">Si tratti di informazioni che riceviamo sul profilo dell’utente o sulla sua farmacia, in particolare:</p>
          <ul className="mt-6 md:pl-8 list-disc">
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Informazioni del profilo professionale – in merito alla farmacia dell’utente per integrare il suo profilo durante la fase di registrazione.</li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Se l’utente o la sua farmacia utilizzano altri siti o applicazioni da noi realizzate o altri servizi da noi offerti.</li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">Da terze parti con cui collaboriamo.</li>
            </ul>
        </ul>
        <p className="flex flex-wrap text-blue-theme italic text-sm md:text-base tracking-wide leading-normal">Per saperne di più – Puoi trovare, alla fine di questo documento, un elenco completo dei dati che processiamo, le finalità del trattamento e la base giuridica del trattamento.</p>
      </p>
      <h1 id="CookiesAndOtherTech" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Cookie e altre tecnologie [Sezione da eliminare qualora non si applichino cookie all’Application Site]
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’Application Site utilizza i cookie e/o tecnologie simili per raccogliere e conservare determinati dati. Questi dati coinvolgono generalmente frammenti di informazioni o codici trasferiti da un sito web o a cui si accede dal disco fisso del computer o dal dispositivo mobile dell’utente per memorizzare e talvolta tracciare informazioni sull’utente. I cookie e tecnologie simili permettono di ricordare l’utente quando utilizza il computer o il dispositivo per interagire con i siti web e i servizi online e possono essere utilizzati per gestire una serie di funzioni e contenuti, oltre a memorizzare le ricerche e presentare contenuti personalizzati.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’Application Site utilizza i cookie e tecnologie simili per distinguere lo specifico utente del sito dagli altri. Questo ci aiuta a fornire all’utente un’esperienza personalizzata quando naviga nel sito e ci permette anche di migliorare i nostri contenuti e servizi.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">La maggior parte dei browser accetta i cookie e tecnologie simili in modo automatico; tuttavia, se l’utente lo desidera, potrà modificare le impostazioni del browser per impedirlo e a tal fine l’utente visualizzerà una schermata di aiuto o le istruzioni necessarie a spiegargli come farlo. Di seguito forniremo all’utente le informazioni su come disabilitare i cookie. Tuttavia, in tal caso, l’utente potrebbe non essere in grado di trarre pieno vantaggio dalle funzioni del sito web.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Un certo numero di cookie e tecnologie simili da noi utilizzati dura solo per il periodo in cui è attiva la sessione del sito e scade quando si esce dal sito. Altri, invece, sono utilizzati per ricordarsi dell’utente quando torna sull’Application Site e durano più a lungo.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Utilizziamo questi cookie e altre tecnologie in quanto necessari a dare esecuzione ad un contratto di cui l’utente è parte, o sulla base dei nostri legittimi interessi (se abbiamo ritenuto che sui nostri legittimi interessi non prevalgano i diritti dell’utente) e, in alcuni casi, quando richiesto per legge oppure quando l’utente abbia acconsentito al loro utilizzo.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal italic mb-6">Per saperne di più</p>
      <h1 id="Communications" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Aggiornamenti promozionali e Comunicazioni
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Laddove consentito nel nostro legittimo interesse o previo consenso dell’utente o quando richiesto per legge, utilizzeremo i dati personali dell’utente per effettuare analisi di mercato o per fornire all’utente comunicazioni promozionali e aggiornamenti via email. L’utente può fare riferimento alla sezione “<b>I tuoi diritti</b>” della presente informativa per modificare le preferenze di contatto e/o promozionali.</p>
      <h1 id="WhoWeGiveInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        A chi trasmettiamo i dati
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’Application Site permette ai farmacisti che hanno terminato un training appropriato o altro programma appropriato di formazione post-laurea di registrarsi sull’Application Site per rendersi disponibili e visibili ai pazienti oncologici nella loro area, condividendo dettagli sulla farmacia e informazioni professionali. I pazienti accederanno alle informazioni di Google maps relative alla geo-localizzazione della farmacia nella quale lavora il farmacista che si è registrato con successo e a tutte le altre informazioni condivise con l’applicazione.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Potremmo rendere disponibili le informazioni personali dell’utente a:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Soggetti facenti parte del nostro gruppo, ovvero le nostre controllate, la nostra capogruppo e le sue controllate, che contribuiscono al trattamento dei dati personali ai sensi della presente informativa. Per avere una lista completa di queste società, si prega di cliccare sul seguente indirizzo web: <a className="underline font-bold text-blue-theme" href={"https://www.accord-healthcare.com/about"} target={"_blank"} rel={"noreferrer"}>https://www.accord-healthcare.com/about</a>. Se uno di questi soggetti intenderà utilizzare i dati personali dell’utente per finalità proprie, le informazioni saranno trasferite per tali finalità solo previo consenso dell’utente.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Terze parti selezionate come i fornitori che attraverso i loro servizi ci consentono di assicurare il corretto svolgimento del servizio da noi reso.</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Enti e autorità pubbliche (amministrative o giudiziarie) nei casi in cui trovi applicazione un obbligo di legge.</li>
        </ul>
        <p className="flex flex-wrap text-blue-theme italic text-sm md:text-base tracking-wide leading-normal">Per saperne di più</p>
      </p>
      <h1 id="WhereWeStoreInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Dove conserviamo e trasferiamo i dati
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">L’Application Site e i dati associati che raccogliamo dagli utenti sono conservati all’interno di un server sicuro situato in Europa. Occasionalmente possiamo utilizzare anche altri fornitori di servizi o collaborare con le nostre altre consociate del gruppo alle quali possono essere traferiti i dati e così conservati in una destinazione anche al di fuori dell’Area Economica Europea “EEA” e che potrebbero per questo essere soggetti ad una diversa Legge sulla Protezione dei Dati Personali.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Qualora trasferissimo i dati personali dell’utente al di fuori della EEA, adotteremmo tutte le misure necessarie a garantire che i dati siano soggetti ad appropriati livelli di salvaguardia, ad esempio affidandoci ad un meccanismo di adeguatezza legale riconosciuto, e ad assicurare che i dati siano trattati in modo sicuro in conformità alla presente informativa sulla privacy e alla Legge sulla Protezione dei Dati Personali applicabile.</p>
      <p className="flex flex-wrap text-blue-theme italic text-sm md:text-base tracking-wide leading-normal mb-6">Per saperne di più</p>
      <h1 id="ChildSafety" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        La sicurezza dei minori
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-6">Proteggere l’incolumità dei minori quando utilizzano Internet e i servizi online è importante per noi. L’Application Site è destinato all’uso esclusivo da parte di persone che abbiano compiuto almeno 18 anni. Utilizzando il sito, l’utente conferma di soddisfare tale requisito. Qualora, attraverso una notifica inviata all’indirizzo <a className="underline font-bold text-blue-theme" href={"mailto:privacy@oncodemia.com"}>privacy@oncodemia.com</a>, accertassimo che alcuna delle informazioni dai noi raccolte sia stata fornita da un minore, provvederemmo a chiuderne l’account.</p>
      <h1 id="HowWeProtectInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Come proteggiamo i dati
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">I dati forniti dall’utente vengono conservati su server sicuri. Chiediamo all’utente di non condividere con terzi la propria password.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Sfortunatamente, la trasmissione di informazioni su Internet non è completamente sicura. Faremo del nostro meglio per proteggere i dati personali dell’utente, compreso il ricorso alla crittografia dei dati in transito e alla crittografia dei dati a riposo per i sistemi non attivi (inclusi i backup). Una volta ricevuti i dati dell’utente, utilizzeremo procedure rigorose e misure di sicurezza per cercare di impedirne accessi non autorizzati.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Talvolta l’Application Site potrebbe contenere link a siti esterni. Non siamo responsabili per le politiche sulla privacy o per i contenuti di tali siti.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">In nessun modo l’Application Site utilizza dati personali dell’utente per prendere decisioni automatizzate che possano produrre conseguenze legali o un impatto significativo sull’utente.</p>
      <h1 id="HowLongWeKeepInfo" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Per quanto tempo conserviamo i dati
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Conserviamo i dati personali per tutto il tempo in cui l’utente utilizza un account con noi o per il tempo necessario a gestire l’Application dell’utente e in ogni caso, oltre tale periodo, per il tempo necessario a gestire e risolvere eventuali problemi o situazioni legali.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Possiamo anche conservare dati aggregati oltre tale periodo per scopi di ricerca e per aiutarci a sviluppare e migliorare i nostri servizi. L’utente non può essere identificato dai dati aggregati conservati o utilizzati per tali scopi.</p>
      <h1 id="YourRights" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Diritti dell’utente
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">L’utente, in determinate circostanze, ha diritto:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">ad ottenere copia dei propri dati personali da noi detenuti;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">a richiedere la correzione o la cancellazione dei propri dati personali da noi detenuti;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">a richiedere una limitazione del trattamento dei dati personali (per esempio mentre verifichiamo o indaghiamo sui suoi dubbi in merito a tali informazioni);</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4 flow-root">ad opporsi ad ulteriori trattamenti dei dati ed alle attività di marketing e di profilazione di marketing (come citato nella sezione “Aggiornamenti promozionali e Comunicazioni”), cliccando sul link “unsubscribe” o “disiscriviti” alla fine di tutte le comunicazioni di marketing e promozionali ricevute, o inviandoci una mail in relazione all’Application Site al seguente indirizzo: <a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app.</a> per richiedere che i dati personali forniti siano trasmessi a terzi parti;</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">a non essere soggetto a decisioni che producano effetti legali che lo riguardino o che parimenti abbiano un impatto significativo su di lui e che siano adottate solo sulla base di un trattamento automatizzato dei dati, inclusa la profilazione (si noti che, come detto sopra, l’Application Site non utilizza informazioni personali per prendere decisioni automatizzate).</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente ha il diritto di revocare il consenso:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">quando il trattamento dei dati personali è basato sul consenso, l’utente in qualsiasi momento ha diritto di ritirare senza pregiudizio il suo consenso. L’utente può anche modificare le sue preferenze di marketing in qualsiasi momento come indicato nella sezione “Aggiornamenti promozionali e Comunicazioni”;</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Quando riceviamo da un utente la richiesta di esercitare uno dei diritti sopra descritti, rispondiamo generalmente entro un mese. Questo termine potrebbe essere esteso fino a un massimo di ulteriori due mesi nei casi in cui le richieste pervenute siano particolarmente complesse o numerose nonché nei casi in cui tale estensione sia consentita dalla Legge sulla Protezione dei Dati Personali. In tali casi, informeremo l’utente di ogni eventuale estensione e del motivo della stessa entro un mese.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente può esercitare i diritti sopra citati contattandoci a:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Application Site: <a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app</a></p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Qualora non riuscissimo a soddisfare eventuali richieste o dubbi, l’utente potrà rivolgersi all’autorità locale per la protezione dei dati personali competente nella sua nazione. Maggiori dettagli di contatto delle autorità locali competenti sono disponibili al seguente link: <a className="underline font-bold text-blue-theme" href={"https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"}>Data Protection Authorities - European Commission (europa.eu)</a></p>
      <h1 id="ContactUs" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Le modifiche alla presente policy
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Qualsiasi modifica che apporteremo in futuro alla presente informativa sulla privacy sarà pubblicata su questa pagina e, in relazione a modifiche sostanziali, sarà comunicata all’utente via email. La presente informativa sulla privacy è stata aggiornata l'ultima volta nel 2021.</p>
      <h1 id="ContactUs" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Come contattarci
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Domande, commenti e richieste riguardanti la presente informativa sulla privacy sono ben accetti e potranno essere indirizzati all’attenzione del nostro Rappresentante Europeo:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root"><b>Application Site: </b><a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app</a></p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6"><b>Indirizzo di posta:&nbsp;</b>Accord Healthcare Limited Sage House 319 Pinner Road North Harrow, Middlesex HA1 4HF</p>
      <h1 id="ContactUs" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Scopo e base giuridica del trattamento dei dati personali
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">La presente tabella disciplina:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">
        <ul className="mt-6 md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">Quali dati personali trattiamo</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">A che scopo usiamo i dati personali trattati</li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">La base giuridica del trattamento dei dati personali</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Forma parte integrante della nostra informativa sulla privacy, di cui si raccomanda la lettura.</p>
      <table className="hidden md:block md:flex-wrap table-fixed mb-6 w-full border-separate">
        <thead>
          <tr>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-left">Scopo/Attività</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-left">Tipologia di dati personali</th>
            <th className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal text-left">Base giuridica del trattamento, incluse basi di legittimo interesse</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Registrare il nuovo utente e gestire il profilo di Application</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identità <br/>(b) Contatti <br/>(c) Profilo professionale</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Dare esecuzione a un contratto di cui l’utente è parte.</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Gestire la nostra relazione con l’utente, incluso: (a)	Comunicare eventuali modifiche dei termini e delle condizioni della nostra informativa sulla privacy. (b)	Chiedere all’utente di fornire feedback o partecipare ad una survey</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identità <br/>(b) Contatti <br/>(c) Profilo professionale <br/>(d) Marketing e comunicazioni</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Dare esecuzione a un contratto di cui l’utente è parte. <br/>(b) Necessario per adempiere ad obblighi di legge. <br/>(c) Necessario per i nostri legittimi interessi (per mantenere aggiornati i nostri archivi e per studiare come i farmacisti interagiscono con i nostri servizi).</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Gestire l’Application dell’utente e rendere il suo profilo disponibile e visibile ai pazienti oncologici della sua zona</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identità <br/>(b) Contatti <br/>(c) Profilo professionale</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Necessario per i nostri legittimi interessi (con lo scopo di permettere ai farmacisti di fornire informazioni, supporto e risorse ai pazienti oncologici)</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Amministrare e proteggere la nostra attività e l’Application Site (sono inclusi la risoluzione dei problemi, l'analisi dei dati, i test, la manutenzione del sistema, il supporto, il reporting e l'hosting dei dati)</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identità <br/>(b) Contatti <br/>(c) Informazioni tecniche</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Necessario per i nostri legittimi interessi (per la gestione della nostra attività, la fornitura di servizi amministrativi e IT, la sicurezza della rete, per impedire le frodi e nel contesto di una riorganizzazione aziendale o di ristrutturazione del gruppo). <br/>(b) Necessario per adempiere ad obblighi di legge.</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Permettere all’utente di partecipare ad un sondaggio o ad una ricerca, quando decida di farlo</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identità <br/>(b) Contatti <br/>(c) Profilo <br/>(d) Utilizzo <br/>(e) Marketing e comunicazioni</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Necessario per i nostri legittimi interessi (per fornire funzioni interattive).</td>
          </tr>
          <tr>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">Fornire raccomandazioni e consigli in merito a prodotti e servizi ai quali l’utente potrebbe essere interessato</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Identità <br/>(b) Contatti <br/>(c) Informazioni tecniche <br/>(d) Utilizzo <br/>(e) Profilo professionali</td>
            <td className="text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal">(a) Necessario per i nostri legittimi interessi (per sviluppare i nostri prodotti / servizi e far crescere il nostro business).</td>
          </tr>
        </tbody>
      </table>
      <div className="block md:hidden">
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Registrare il nuovo utente e gestire il profilo di Application
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identità (b) Contatti (c) Profilo professionale</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Dare esecuzione a un contratto di cui l’utente è parte</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Gestire la nostra relazione con l’utente, incluso: (a)  Comunicare eventuali modifiche dei termini e delle condizioni della nostra informativa sulla privacy. (b) Chiedere all’utente di fornire feedback o partecipare ad una survey
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identità (b) Contatti (c) Profilo professionale (d) Marketing e comunicazioni</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Dare esecuzione a un contratto di cui l’utente è parte. (b) Necessario per adempiere ad obblighi di legge. (c) Necessario per i nostri legittimi interessi (per mantenere aggiornati i nostri archivi e per studiare come i farmacisti interagiscono con i nostri servizi).</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Gestire l’Application dell’utente e rendere il suo profilo disponibile e visibile ai pazienti oncologici della sua zona
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identità (b) Contatti (c) Profilo professionale</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Necessario per i nostri legittimi interessi (con lo scopo di permettere ai farmacisti di fornire informazioni, supporto e risorse ai pazienti oncologici)</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Amministrare e proteggere la nostra attività e l’Application Site (sono inclusi la risoluzione dei problemi, l'analisi dei dati, i test, la manutenzione del sistema, il supporto, il reporting e l'hosting dei dati) 
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identità (b) Contatti (c) Informazioni tecniche</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Necessario per i nostri legittimi interessi (per la gestione della nostra attività, la fornitura di servizi amministrativi e IT, la sicurezza della rete, per impedire le frodi e nel contesto di una riorganizzazione aziendale o di ristrutturazione del gruppo). (b) Necessario per adempiere ad obblighi di legge.</p>
        <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Permettere all’utente di partecipare ad un sondaggio o ad una ricerca, quando decida di farlo
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identità (b) Contatti (c) Profilo (d) Utilizzo (e) Marketing e comunicazioni</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Necessario per i nostri legittimi interessi (per fornire funzioni interattive).</p>
       <h1 className="text-md text-sm text-blue-theme tracking-relax mx-auto mb-6">
          Fornire raccomandazioni e consigli in merito a prodotti e servizi ai quali l’utente potrebbe essere interessato
        </h1>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a) Identità (b) Contatti (c) Informazioni tecniche (d) Utilizzo (e) Profilo professionali</p>
        <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">(a)	Necessario per i nostri legittimi interessi (per sviluppare i nostri prodotti / servizi e far crescere il nostro business).</p>
      </div>
      <p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Indietro"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
    </div>
  )
}

export default PrivacyPolicy;
