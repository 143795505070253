import React, {useEffect} from 'react';
import usePlacesAutoComplete from "use-places-autocomplete";
import {Combobox, ComboboxInput, ComboboxOption, ComboboxPopover} from "@reach/combobox";
import Geocode from "react-geocode";
import { useTranslation } from 'react-i18next';
import { COUNTRY, LANGUAGE } from '../../screens/Form/constant';

const GOOGLE_MAPS_API_KEY_UK="AIzaSyCjs01Kq0yVaekJlASAYYKJ71HuUKj4t_M";
const GOOGLE_MAPS_API_KEY_IT="AIzaSyCZNNPqN-McPo-d-vyJIDbbsbfzAhTQo6o";
const GOOGLE_MAPS_API_KEY_DE="AIzaSyA8FsHwnrVLPU_ulsBxkJOZDa8DV1HmeLY";


let currentApiKey;
switch(LANGUAGE+COUNTRY){
  case 'enuk':
    currentApiKey = GOOGLE_MAPS_API_KEY_UK;
    break;
  case 'itit':
    currentApiKey = GOOGLE_MAPS_API_KEY_IT;
    break;
  case 'dede':
    currentApiKey = GOOGLE_MAPS_API_KEY_DE;
    break;
  default:
    currentApiKey = GOOGLE_MAPS_API_KEY_UK;
}
  

Geocode.setApiKey(currentApiKey);

//console.log("API KEY", currentApiKey);


const MapSearch = ({setCenter, isPlaceId, onAddressSelect = () => {}, setMarker = () => {}, lat, lng, address, place_id}) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutoComplete();

  const { t } = useTranslation();
  useEffect(()=>{
    if(!value && !!lat && !!lng){
      setValue(address,false);
      clearSuggestions();
      setCenter({
        lat:parseFloat(lat),
        lng:parseFloat(lng),
      });
      setMarker({
        place_id,
        address,
        lat:parseFloat(lat),
        lng:parseFloat(lng)
      });
    }
  },[lat,lng]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectAddress = function (address) {
    setValue(address)
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({
          lat,
          lng,

        })
        if (response.results[0]) {
          const {place_id, formatted_address: address, geometry: {location: {lat, lng}}} = response.results[0];
          onAddressSelect({
            place_id,
            address,
            lat,
            lng
          });

        }
        clearSuggestions();
      },
      (error) => {

      }
    );
  }
  return (
    <div className="search">
      <Combobox onSelect={selectAddress}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder={t('step1.enterLocation')}
          style={ !!isPlaceId ? { borderColor:'red', borderWidth: '1px'} : {}}
        />
        <ComboboxPopover>
          {status === "OK" &&
          data.map(({ place_id, description }) => (
            <ComboboxOption key={place_id} value={description} />
          ))}
        </ComboboxPopover>
      </Combobox>
    </div>
  );

}

export default MapSearch;

