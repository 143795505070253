import React, { useRef, useState, useEffect } from "react";
import UnRadioButton from "../UnRadioButton";
import UnInput from "../UnInput";
import ReCAPTCHA from "react-google-recaptcha";
import UnButton from "../UnButton";
import {useForm} from "react-hook-form";
import {useHistory, Link } from "react-router-dom";
import {useQuery} from "../../screens/Form";
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const radioOptions = (t) => ([{
  label: (t('step3.myEmployer')),
  value: 'MY EMPLOYER',
  id: 'my-employer-radio',
}, {
  label: (t('step3.socialMedia')),
  value: 'SOCIAL MEDIA',
  id: 'social-media-radio',
}, {
  label: (t('step3.recommendation')),
  value: 'RECOMMENDATION',
  id: 'recommendation-radio',
}, {
  label: (t('step3.wordOfMouth')),
  value: 'WORD OF MOUTH',
  id: 'word-of-mouth-radio'
}]);

const StepTheree = ({
  data,
  onSubmit = () => {},
}) => {
  const history = useHistory();
  const query = useQuery();
  const { t } = useTranslation();

  const { handleSubmit, register, watch, formState: { errors }, setValue, getValues } = useForm({
    defaultValues: {
      howDidYouFind: data.howDidYouFindAboutTheProgram,
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
    }
  });
  const [token, setToken] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [howDidYou, setHowDidYouFind] = useState('');
  const [other, setOther] = useState('');
  const reRef = useRef();

  const validateHuman = async () => {
    const token = await reRef.current.getValue();
    setToken(token);
  };

  const pharmacistId = query.get('pharmacistId');
  const queryForRedirect = pharmacistId ? '?pharmacistId=' + pharmacistId : '';
  useEffect(() => {
    if (!data.firstName) {
      history.push('/form/1' + queryForRedirect)
    } else {
      //setLoginToken(pharmacistId);
      setHowDidYouFind(data.howDidYouFindAboutTheProgram || '');
      if (data.howDidYouFindAboutTheProgram) {
        const found = radioOptions(t).find((option) => option.value === data.howDidYouFindAboutTheProgram);
        if (!found) {
          setOther(data.howDidYouFindAboutTheProgram)
        }
      }
    }
  }, [data, history, queryForRedirect, t])

  const howDidYouFindValueInHookForm = getValues('howDidYouFind');
  const check1 = watch('check1');
  const check2 = watch('check2');
  const check3 = watch('check3');
  const check4 = watch('check4');
  const check5 = watch('check5');
  useEffect(() => {
    setDisabled( !(token && howDidYouFindValueInHookForm && check1 && check2 && check3 && check4 && check5 ) )
  }, [token, howDidYouFindValueInHookForm, check1, check2, check3, check4, check5])

  const submit = ({howDidYouFind, check1, check2, check3, check4, check5}) => {
    const data = {
      howDidYouFindAboutTheProgram: howDidYouFind,
      recaptchaToken: token,
      agreementCheck1:check1, 
      agreementCheck2:check2, 
      agreementCheck3:check3, 
      agreementCheck4:check4, 
      agreementCheck5:check5,
    }
    onSubmit(data);
  }
  const onRadioChange = (e) => {
    setValue('howDidYouFind', e.target?.value);
    setHowDidYouFind(e.target.value);
    setOther('')
  }
  const onOtherChange = (e) => {
    setValue('howDidYouFind', e.target?.value);
    setHowDidYouFind(e.target?.value);
    setOther(e.target?.value)
  }

  const PRIVACY_EMAIL = 'mailto:privacy@unifyhealth.app';

  return (
    <form onSubmit={handleSubmit(submit)}>
      <p className="text-blue-theme">
        {t('step3.howDidYouFindProgramme')}
      </p>
      <input {...register('howDidYouFind', {required: true})} type={'hidden'} />
      <div className="flex flex-col my-8">
        {radioOptions(t).map((option) => {
          return (
            <UnRadioButton
              key={option.id}
              checked={howDidYou === option.value}
              name={'radio'}
              onChange={onRadioChange}
              id={option.id}
              label={option.label}
              value={option.value}
              styles="mb-4"
            />
          )
        })}
      </div>
      <UnInput
        onChange={onOtherChange}
        value={other}
        id={'how-did-you-find-other'}
        label={t('step3.other')}
        placeholder={''}
      />
      <p className="opacity-40 text-xs mt-4 tracking-normal leading-normal">
        {t('step3.otherNote')}
      </p>
      <div className="flex flex-col mt-8">
            <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mb-6 text-left">
              {t('step3.communityChartherHeading')}
            </h1>
            <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">
              {t('step3.communityChartherBody')}
            </p>
            <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal">
            {t('step3.commitments.heading')}
            </p>
            <ul className="mt-6 md:pl-8 list-decimal">
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.one')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.two')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.three')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.four')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.five')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.six')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.seven')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.eight')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.nine')}</li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.commitments.ten')}</li>
            </ul>
        <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal">
          {t('step3.nandatoryConfirmations.heading')}
        </p>
        <ul className="mt-6 md:pl-4">
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('check1', {required: true})} id={'check1'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'check1'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.one')}</label>
            {!!errors.check1 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">
              <input {...register('check2', {required: true})} id={'check2'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
              <label htmlFor={'check2'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.two')}</label>
              {!!errors.check2 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
            </li>
              <li className="text-blue-theme tracking-wide leading-normal mb-4">
                <input {...register('check3', {required: true})} id={'check3'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
                <label htmlFor={'check3'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.three')}</label>
                {!!errors.check3 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
              </li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('check4', {required: true})} id={'check4'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'check4'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.four')}</label>
            {!!errors.check4 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
            <input {...register('check5', {required: true})} id={'check5'} type="checkbox" className="checked:bg-blue-600 checked:border-transparent mr-4"></input>
            <label htmlFor={'check5'} className="text-blue-theme ml-4">{t('step3.nandatoryConfirmations.five')}</label>
            {!!errors.check5 && ( <div className={'pt-4 text-red-600'}> <p>{t('step3.nandatoryConfirmations.required')}</p> </div> )}
          </li>
        </ul>
        {/* <p className="opacity-40 text-xs mt-4 tracking-normal leading-normal">
        Each of the above confirmations to have a tick box. All are mandatory and form will not submit without all being ticked
        </p> */}
      </div>
      <div className="flex justify-center mt-14">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
          size="normal"
          ref={reRef}
          onChange={validateHuman}
        />
      </div>
      {!!errors.howDidYouFind && (
        <div className={'pt-4 text-red-600'}>
          <p>{t('step3.commitments.warning')}</p>
        </div>
      )}
      <div className={'flex flex-row items-center justify-end mt-10'}>
        <UnButton
          title={t('registration.previous')}
          fullWidth={false}
          onClick={() => history.goBack()}
          transparent={true}
          isTextSecondary={true}
        />

        <UnButton
          disabled={disabled}
          title={t('registration.submit')}
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </div>
      <div className="flex flex-col mt-8">
        <p className="text-blue-theme font-bold text-sm md:text-base tracking-wide leading-normal">
          {t('step3.notes.heading')}
        </p>
        <ul className="mt-6 md:pl-8 list-disc flow-root">
          <Trans i18nKey="step3.notes.one">
            <li className="text-blue-theme tracking-wide leading-normal mb-4">
              Our simple online application form takes about 5 minutes to complete. Every application is hand-reviewed on a rolling basis, and you can typically expect a decision within 4 weeks. We receive many applications each week, so please bear with us until your application is confirmed and Champion profile is live. If you have not received confirmation of your Champion status within 6 weeks of completion of submission of this form, please contact <a className="underline font-bold text-blue-theme" target={"_blank"} rel="noreferrer" href={PRIVACY_EMAIL}>privacy@unifyhealth.app</a> and a member of our team will be happy to assist you
            </li>
          </Trans>
          <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.notes.two')}</li>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.notes.three')}</li>
            <Trans i18nKey="step3.notes.four">
              <li className="text-blue-theme tracking-wide leading-normal mb-4">Becoming a Community Cancer Champion includes an ongoing commitment to learning about cancer, which is a rapidly evolving area of medicine. None of us can ever know everything there is to know on cancer. It is important to seek and undertake opportunities to expand your knowledge base. If you believe that you are already deserving of Community Cancer Champion status but do not want to undertake the free Oncodemia course explaining the use of the Charter commitments, please contact <a className="underline font-bold text-blue-theme" target={"_blank"} rel="noreferrer" href={PRIVACY_EMAIL}>privacy@unifyhealth.app</a> and an alternative application process will be made available to you. Please note that in the absence of completing the Oncodemia course, written submissions with supporting evidence will be required from the applicant to substantiate their understanding and ability to apply each of the 10 Charter commitments in their work as a pharmacist. The individual’s application will then be reviewed by a convened panel.</li>
            </Trans>
            <li className="text-blue-theme tracking-wide leading-normal mb-4">{t('step3.notes.five')}</li>
        </ul>
        <Trans i18nKey="step3.moreInforLink">
          <p className="text-blue-theme text-sm md:text-base tracking-wide leading-normal mb-6">
            For information about how we use your information see our &nbsp;
            <Link className="underline font-bold text-blue-theme" to={'/privacy-policy'}>Privacy and Cookies Policy</Link>
            &nbsp; and &nbsp;
            <Link className="underline font-bold text-blue-theme" to={'/terms-and-conditions'}>Terms and Conditions</Link>
          </p>
        </Trans>
      </div>
    </form>
  );
};

export default StepTheree;
