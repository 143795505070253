import React, { useEffect }  from "react";
import { Helmet } from "react-helmet";
import divider from "../assets/images/div.svg";
import dividerTop from "../assets/images/div2.svg";
import logo from "../assets/images/icon.svg";
import UnThankYou from "../components/UnThankYou/indexRep";
import RepIT from "./RepIT";
import { Route } from "react-router-dom";
import PrivacyPolicyIT from "./PrivacyPolicyIT";
import TermsAndConditionsIT from "./TermsAndConditionsIT";
import Footer from "../components/Footer";
import StepOneITRep from '../components/StepOne/indexITRep';

const ItRep = () => {

  useEffect(() => {
    document.title = "Unify Health Italia Rep";
    document.description = "Italia";
  }, []);

  return (
    <div className={'flex flex-col bg-blue-theme justify-center items-center min-h-screen h-full py-20'}>
      <Helmet>
        <title>Unify Health Italia Rep</title>
        <meta name="description" content="Italia" />
      </Helmet>

      <div className="hidden md:fixed bottom-0 left-1 md:left-16">
        <img src={divider} alt="" />
      </div>

      <div className="hidden md:fixed md:flex top-0 right-16">
        <img src={dividerTop} alt="" />
       
      </div>

      <img src={logo} alt="" className="mx-auto -mt-10 mb-10" />

      <Route exact path={'/rep/home/:repListId'} component={RepIT} />
      <Route exact path={'/rep/form'}><StepOneITRep /></Route>
      <Route exact path={'/rep/thank-you'}><UnThankYou /></Route>
      <Route exact path={'/rep/privacy-policy'}><PrivacyPolicyIT /></Route>
      <Route exact path={'/rep/terms-and-conditions'}><TermsAndConditionsIT /></Route>
      <Footer path="rep" />
    </div>
  );
};

export default ItRep;
