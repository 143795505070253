import React from "react";
import clsx from "clsx";
import {ERROR_CLASS_NAME} from "../../constants";

/**
 * @typedef {object} Props
 * @prop {string} label
 * @prop {string} placeholder
 * @prop {(() => void)=} onChangeText
 * @prop {boolean=} fullWidth
 * @prop {string} note
 * @prop {string=} styles
 * @prop {number=} maxLength
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement}
 */

const index = ({
  control = {},
  hasError = false,
  label,
  placeholder,
  onChange,
  fullWidth = true,
  note,
  styles,
  maxLength = 250,
}) => {
  return (
    <div
      className={clsx(
        "flex flex-col mt-4 mb-6",
        fullWidth ? "w-full" : "w-full md:w-1/2",
        styles
      )}
    >
      <label htmlFor="" className="mb-4">
        {label}
      </label>
      <textarea
        className={clsx(
          'focus:outline-none border-b pb-2',
          { [ERROR_CLASS_NAME]: hasError }
        )}
        onChange={onChange}
        rows={5}
        placeholder={placeholder}
        maxLength={maxLength}
        {...control}
      />
      {note && (
        <p className="opacity-40 text-xs mt-4 tracking-normal leading-normal">
          {note}
        </p>
      )}
    </div>
  );
};

export default index;
