import React, { useState, useEffect } from 'react';
import UnButton from "../../components/UnButton";
import {useHistory, useParams} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import '../../App.css';
import Loader from '../../../src/components/Loader';
import { API_DOMAIN, AREAMANAGER_GET_PHARMACIES_ENDPOINT } from '../../../src/constants';
import ErrorMessage from '../Form/ErrorMessage';

const AreaManagerIT = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams()
  const { goBack } = useHistory();

  const [isDataLoading, setIsLoading] = useState(false);
  const [pharmcsData, setPharmcsData] = useState([]);
  const [pharmcsDataError, setHasError] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const areaManagerId = params.areaManagerId ? (params.areaManagerId * 1) : null;
  
  const getPharData = async() =>{
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await fetch(AREAMANAGER_GET_PHARMACIES_ENDPOINT.replace('##id##', areaManagerId));
      const result = await response.json();
      if(result?.data){
        setPharmcsData(result.data);
      }else{
        setHasError(true);
        setErrorMessage(t('areaManager.noPharFound'));
      }
      setIsLoading(false);
    } catch (error) {
      setHasError(true);
      setErrorMessage(t('areaManager.networkError'));
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getPharData();
    }, [areaManagerId])

  if(isDataLoading){
    return(
      <div className={'flex mx-auto'}>
        <Loader />
      </div>
    )
  }

  const exportCsv = () => {
    window.location.href = API_DOMAIN + '/api/areamanager-accord-csv'
  }

  const addRep = () => {
    if (areaManagerId !== null) {
        console.log('areaManagerId', areaManagerId);
        history.push('/areamanager/add/rep/' + areaManagerId);
    }
  }

  const screenHandler = () => {
    if(isDataLoading){
      return(
        <div className={'flex mx-auto'}>
          <Loader />
        </div>
      )
    }else if(pharmcsDataError){
        return(
          <ErrorMessage errorMessage={errorMessage} />
        )
    }else{
      if(pharmcsData.length == 0){
        return(
          <h1 className="text-xl text-blue-theme tracking-relax mx-auto mt-10">
            {t('areaManager.noPharFound')}
          </h1>
        )
      }
      return (
          <ul className="mt-6 md:pl-8 list-decimal mb-6">
            {
              pharmcsData.map( phrData =>{
                const { id, pharmacyName, fullAddress } = phrData;
                return(
                  <li className="text-blue-theme tracking-wide leading-normal mb-6" key={id}>
                    <div style={{display: 'flex',flexDirection:'row', flex: 1.0, backgroundColor: 'transparent'}}>
                      <div style={{flex: 0.8}}>{pharmacyName}, {fullAddress}</div>
                      <div style={{flex: 0.2}}>
                        <UnButton
                          title={t('areaManager.button.view')}
                          fullWidth={false}
                          position="center"
                          onClick={() => history.push('/areamanager/view', {phrData})}
                        />
                      </div>
                    </div>
                  </li>
                )
              })
            }       
          </ul>
        );
      }
    }

  return (
    <>
      <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12 h-3/6 overflow-y-scroll">
        <UnButton
          onClick={goBack}
          title="Back"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
        <div style={{alignItems:'center',justifyContent:'center',display:'flex',flex:1,flexDirection:'column',marginBottom:30}}>
          <div style={{flex: 0.2,marginTop:40}}>
            <UnButton
              title={t('areaManager.button.add')}
              fullWidth={false}
              position="center"
              onClick={() => history.push('/areamanager/form/' + areaManagerId)}
            />
          </div>
          <div style={{flex: 0.2,marginTop:40}}>
            <UnButton
              title={t('areaManager.button.addRep')}
              fullWidth={false}
              position="center"
              onClick={() => addRep()}
            />
          </div>
          <div style={{flex: 0.2,marginTop:40}}>
            <UnButton
              title={t('areaManager.button.export')}
              fullWidth={false}
              position="center"
              onClick={() => exportCsv()}
            />
          </div>
        </div>

        <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mt-10 mb-10">
          {t('areaManager.PharmacyList')}
        </h1>
        
        {screenHandler()}
      </div>
      
    </>

  );
};

export default AreaManagerIT;
