import React from "react";
import clsx from "clsx";
import {ERROR_CLASS_NAME} from "../../constants";

/**
 * @typedef {object} Props
 * @prop {string} label
 * @prop {string} placeholder
 * @prop {(() => void)=} onChangeText
 * @prop {boolean=} fullWidth
 * @prop {string} note
 * @prop {string=} styles
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement}
 */

const index = ({ id, control= {}, value='', hasError = false, label, placeholder, onChange, fullWidth = true , note, styles}) => {
  const _id = id || Math.random().toString(36);
  const valueProp = (Object.keys(control).length) ? {} : {value: value}
  return (
    <div className={clsx("flex flex-col mt-4 mb-6", fullWidth ? "w-full" : "w-full md:w-1/2", styles)}>
      <label htmlFor={_id} className="mb-4">
        {label}
      </label>
      <input
        id={_id}
        {...valueProp}
        type="text"
        className={clsx('focus:outline-none border-b pb-2', {[ERROR_CLASS_NAME]: hasError})}
        placeholder={placeholder}
        onChange={onChange}
        {...control}
      />{/* leave {...control} on last place to overwrite onChange by useForm() */}
      {
          note &&
          <p className="opacity-40 text-xs mt-4 tracking-normal leading-normal">{note}</p>
      }
    </div>
  );
};

export default index;
