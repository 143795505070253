import React from 'react';
import UnButton from "../../components/UnButton";
import { useHistory, Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const TermsAndConditions = () => {
  const {goBack} = useHistory();

  return (
    <>
    <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
     <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Termini di utilizzo</h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#introduction">Introduzione</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#registering_with_us">Registrarsi all’Application Site</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#access_registration_site">Accesso all’Application Site</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#user_authorized">Cosa l’utente è autorizzato a fare</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#user_notauthorized">Cosa l’utente non è autorizzato a fare</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#third_party_services">Diritti di proprietà intellettuale</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#feature_content">Caratteristiche e contenuto dell’Application Site</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#user_content">Informazioni personali dell’utente</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#external_links">Link esterni</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#general">Generale</HashLink></li>
          <li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#contacting_us">Contatti</HashLink></li>
        </ul>
        </p>
      <h1 id="introduction" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Introduzione
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">Le presenti condizioni e termini di utilizzo si applicano per la registrazione e la gestione dell’applicazione Unify Health <a className="underline font-bold text-blue-theme"  href={"https://registrati.unifyhealth.app"} target={"_blank"} rel={"noreferrer"} >https://registrati.unifyhealth.app</a> (”<b>Application Site</b>”). L’Application Site è gestito da o per conto di Accord Healthcare Limited (“<b>noi</b>”, “<b>ci</b>” o “<b>nostro/a</b>”). Accord Healthcare Limited è una società a responsabilità limitata, registrata in Inghilterra. Il numero di registrazione dell’impresa è 04596349 e la sede legale si trova in Sage House, 319 Pinner Road, North Harrow, Middlesex, HA1 4HF (Regno Unito). Il numero di partita IVA è GB 894350693.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utilizzo dell’Application Site è soggetto alle seguenti condizioni e termini e, registrandosi all’Application Site, l’utente accetta di essere vincolato da tali condizioni e termini di utilizzo.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Ci riserviamo il diritto di modificare periodicamente tali termini e condizioni. L’ultimo aggiornamento alle presenti condizioni è stato effettuato il</p>
      <h1 id="registering_with_us" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Registrarsi all’Application Site
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’Application Site è destinato all’uso esclusivo da parte di persone:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="md:pl-8">
          <li className="text-blue-theme tracking-wide leading-normal">- di almeno 18 anni di età e</li>
          <li className="text-blue-theme tracking-wide leading-normal">- farmacisti qualificati “Oncospecialist” o “Unify Salute”</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Registrandosi all’Application Site, l’utente dichiara di possedere tali requisiti.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Si prega di notare che per l’accesso a particolari servizi o funzioni, dove indicato dall’Application Site, potrebbero essere richiesti ulteriori requisiti di idoneità. È responsabilità dell’utente assicurarsi di soddisfare tutti i requisiti minimi di idoneità indicati prima di utilizzare alcuno di questi servizi o funzioni. Procedendo in questo modo, l’utente conferma di rispettare tutti i requisiti minimi di idoneità.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente deve garantire che le informazioni fornite per la registrazione siano accurate. Qualora l’utente scelga, o gli venga fornito, un codice identificativo di accesso (come, ad esempio, usurname e password, o altro dettaglio identificativo) nell’ambito delle nostre procedure di sicurezza, l’utente è tenuto a trattare tali informazioni come riservate e a non rivelarle a nessuno. L’utente è responsabile di tutte le attività eseguite dopo l’accesso con tale codice identificativo ed è tenuto a informarci immediatamente di qualsiasi utilizzo non autorizzato o di altra violazione delle procedure di sicurezza di cui venga a conoscenza. Ci riserviamo il diritto di disattivare qualsiasi codice identificativo, in qualsiasi momento, qualora a nostro parere l’utente non abbia rispettato una qualsiasi delle disposizioni delle presenti condizioni e termini di utilizzo, o qualora i dati forniti ai fini della registrazione come utente si rivelino falsi.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Per registrarsi l’utente dovrà fornire accurate, complete ed aggiornate informazioni di contatto, incluso nome, indirizzo postale, numero di telefono e indirizzo email. L’utente è responsabile delle informazioni che ci ha fornito. In caso di modifiche, dovrà prontamente aggiornare le informazioni del suo account. Ci riserviamo il diritto, a nostra discrezione, di realizzare controlli con l’obiettivo di confermare l’identità dell’utente e verificarne le qualifiche e il rispetto dei criteri di idoneità. Accettando questi termini, l’utente ci autorizza a eseguire tali controlli e verifiche.</p>
      <h1 id="access_registration_site" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Accesso all’Application Site
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">È responsabilità dell’utente assicurarsi che i suoi strumenti (computer, laptop, netbook, tablet o altro dispositivo mobile) dispongano di tutti i requisiti tecnici necessari a consentire l’accesso e l’utilizzo dell’Application Site e che siano compatibili con lo stesso.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Non possiamo garantire l’operabilità continua, ininterrotta o senza errori dell’Application Site. Talora potrà verificarsi che alcune funzionalità, parti o contenuti dell’Application Site, o l’intero Application Site, diventino non disponibili (e ciò sia se preventivamente programmato che se non programmato) o che possano essere modificati, sospesi o cancellati, a nostra discrezione e senza nessuna notifica all’utente. L’utente accetta che non saremo responsabili nei suoi confronti o verso terze parti per eventuali indisponibilità, modifiche, sospensioni e cancellazioni dell’Application Site o di qualsiasi funzionalità, parti e contenuti dell’Application Site.</p>
      <h1 id="user_authorized" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Cosa l’utente è autorizzato a fare
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente è autorizzato a utilizzare l’Application Site solo per scopi di registrazione e geo-localizzazione, in conformità alle presenti condizioni e termini di utilizzo. L’utente è autorizzato a recuperare e visualizzare contenuti dall’Application Site su uno schermo del computer, a stampare e copiare pagine singole e, in relazione alla sezione seguente, conservare queste pagine in formato elettronico. A determinate funzionalità, parti o contenuti dell’Application Site potrebbero altresì applicarsi condizioni aggiuntive che, ove applicate, saranno visualizzate sullo schermo o rese accessibili tramite un link.</p>
      <h1 id="user_notauthorized" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Cosa l’utente non è autorizzato a fare
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Ad eccezione di quanto espressamente stabilito nelle presenti condizioni e termini di utilizzo o in qualsiasi altro accordo con noi, l’utente non è autorizzato a:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal">“raschiare” o salvare i contenuti dell’Application Site su un server o altri device di memorizzazione connessi ad un network o a realizzare un database elettronico scaricando sistematicamente e salvando tutti i contenuti dell’Application Site;</li>
          <li className="text-blue-theme tracking-wide leading-normal">rimuovere o modificare qualsiasi contenuto dell’Application Site o provare ad aggirare la sicurezza o ad interferire con il corretto funzionamento del sito o dei server su cui risiede; o </li>
          <li className="text-blue-theme tracking-wide leading-normal">creare link all’Application Site da altri siti web, senza nostro previo consenso scritto, sebbene sia consentito all’utente di creare un link da un sito web gestito dall’utente medesimo, a condizione che il link non sia ingannevole ed ambiguo e che indichi chiaramente la sua destinazione, l’utente non può considerare approvato da noi il suo operato, il suo sito web o altri prodotti o servizi da lui offerti e che l’utente colleghi (e non inquadri o replichi) alla home page dell’Application Site; il sito web collegato non deve avere contenuti che siano contro la legge, abusivi, intimidatori, diffamatori, pornografici, osceni, volgari, indecenti, offensivi o che violino i diritti di proprietà intellettuale o altri diritti di terze parti.</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente deve usare l’Application Site e qualsiasi contenuto disponibile nel sito per scopi legittimi (in linea con tutte le leggi e regolamentazioni applicabili), in maniera responsabile e non in modo che possa danneggiare il nostro nome o la nostra reputazione o quella di qualsiasi delle nostre affiliate.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Tutti i diritti concessi all’utente ai sensi delle presenti condizioni e termini di utilizzo termineranno immediatamente nell’eventualità di una violazione da parte dell’utente di una qualsiasi delle condizioni e termini di utilizzo.</p>
      <h1 id="third_party_services" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Diritti di proprietà intellettuale      
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Tutti i diritti di proprietà intellettuale presenti in qualsiasi contenuto dell’Application Site (inclusi testo, grafica, software, fotografie e altre immagini, video, suoni, marchi commerciali e loghi) sono di nostra proprietà o di proprietà dei nostri licenzianti. Salvo quanto espressamente indicato in questa sede, nessuna delle presenti condizioni e termini di utilizzo dà all’utente alcun diritto in relazione a qualsiasi diritto di proprietà intellettuale posseduto da noi o dai nostri licenzianti, e l’utente riconosce di non acquisire alcun diritto di proprietà scaricando contenuti dall’Application Site.<br/>Nel caso in cui l’utente stampi, copi o memorizzi contenuti dell’Application Site (solo se permesso dalle presenti condizioni e termini di utilizzo), deve assicurarsi di riprodurre tutti gli avvisi di copyright, marchi registrati o altri diritti di proprietà intellettuali come nei documenti originali.</p>
      <h1 id="feature_content" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Caratteristiche e contenuti dell’Application Site
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente accetta che l’accesso e l’utilizzo dell’Application Site, poiché sono resi disponibili per il download gratuito, siano forniti “nello stato in cui si trovano” e “secondo disponibilità” e a rischio esclusivo dell’utente.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Ove l’Application Site renda disponibili dei contenuti, l’utente riconosce che questi contenuti potrebbero essere aggiornati in qualsiasi momento. Ci riserviamo il diritto di cessare di fornire e/o aggiornare contenuti dell’Application Site, con o senza preavviso all’utente, se lo riteniamo necessario per ragioni di sicurezza, motivi legali o per qualsiasi altra ragione.</p>
      <h1 id="user_content" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Informazioni personali dell’utente
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">L’utilizzo dei dati personali forniti dall’utente e inviati all’Application Site o tramite l’Application Site  è disciplinato dalla nostra <Link to={'/privacy-policy'}><span className="text-blue-theme font-bold">Privacy and Cookies Policy</span></Link>, considerando che le informazioni personali dell’utente saranno accessibili al pubblico (inclusi i pazienti).</p>
      <h1 id="external_links" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Link esterni
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’Application Site potrebbe periodicamente includere link a siti esterni, che potrebbero contenere link a offerte e promozioni di terzi, e che includiamo al fine di fornire all’utente l’accesso a informazioni, prodotti o servizi che potrebbe ritenere utili o interessanti. Non rispondiamo del contenuto di tali siti o di alcun servizio fornito dagli stessi, né garantiamo che tali servizi siano disponibili in via continuativa. Il fatto che includiamo i link a tali siti esterni non implica alcun avallo o alcuna associazione con i loro operatori o promotori.</p>
      <h1 id="general" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Informazioni generali
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Nulla di quanto previsto nelle presenti condizioni e termini di utilizzo limiterà o escluderà la nostra responsabilità nei confronti dell’utente in caso di:</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
        <ul className="md:pl-8 list-disc">
          <li className="text-blue-theme tracking-wide leading-normal">decesso o lesioni personali causate da nostra negligenza o colpa;</li>
          <li className="text-blue-theme tracking-wide leading-normal">travisamento fraudolento;</li>
          <li className="text-blue-theme tracking-wide leading-normal">grave negligenza e/o dolo che, per legge, non possa essere limitata o esclusa; ovvero</li>
          <li className="text-blue-theme tracking-wide leading-normal">eventuali altre responsabilità che, per legge, non possano essere limitate o escluse.</li>
        </ul>
      </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Fermo restando quanto sopra, se l’utente è un consumatore e non un utente commerciale, in nessun caso saremo responsabili nei suoi confronti  per eventuali perdite commerciali che subisca e, se è un professionista, in nessun caso saremo responsabili nei suoi confronti per eventuali perdite indirette o consequenziali, o per qualsiasi perdita di profitto, reddito, contratti, dati, avviamento o altre perdite simili, e qualora si ravvisi una nostra responsabilità per le perdite subite dall’utente, essa sarà strettamente limitata a perdite ragionevolmente prevedibili e non supererà, in totale, la somma pagata dall’utente per l’Application Site. </p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Le presenti condizioni e termini di utilizzo saranno regolate dalla legge inglese, ad eccezione del caso in cui l’utente (se consumatore e non professionista) risieda in un paese dell’Unione Europea diverso dall’Inghilterra, e si applichino alcune leggi obbligatorie nella sua nazione che prevedono benefici e forme di tutela dell’utente in aggiunta o in alternativa alle disposizioni del diritto inglese.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">L’utente accetta che qualsiasi controversia che dovesse sorgere tra noi e l’utente in merito alle presenti condizioni e termini di utilizzo o all’Application Site sarà trattata solo dai tribunali inglesi, ad eccezione del caso in cui l’utente (se consumatore e non professionista) risieda in un paese (inclusi, a tali scopi, Scozia e Irlanda del Nord) dell’Unione Europea diverso dall’Inghilterra, nel qual caso l’utente potrà scegliere se intentare un’azione legale nel suo paese di residenza o in Inghilterra; tuttavia,  in tal caso, qualora noi decidessimo di procedere con azioni legali, queste dovranno essere condotte nel paese di residenza dell’utente.</p>
      <h1 id="contacting_us" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        Contatti
      </h1>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">L’utente è pregato di inoltrare eventuali domande in merito alle presenti condizioni e termini di utilizzo o eventuali reclami concernenti l’Application Site agli indirizzi indicati di seguito.<br/>Application Site - e-mail: <a className="underline font-bold text-blue-theme" href={"mailto:registrati@unifyhealth.app"}>registrati@unifyhealth.app</a>.</p>
      <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Posta:  Accord Healthcare Limited Sage House 319 Pinner Road North Harrow, Middlesex HA1 4HF (Regno Unito).</p>
      <p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Indietro"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
    </div>
    </>
  )
}

export default TermsAndConditions;
