import React, { useEffect }  from "react";
import { Helmet } from "react-helmet";
import divider from "../assets/images/div.svg";
import dividerTop from "../assets/images/div2.svg";
import logo from "../assets/images/icon.svg";
import UnThankYou from "../components/UnThankYou";
import BecaomeAMemberIT from "./BecaomeAMemberIT";
import { Route } from "react-router-dom";
import Form from "./Form";
import PrivacyPolicyIT from "./PrivacyPolicyIT";
import TermsAndConditionsIT from "./TermsAndConditionsIT";
import Footer from "../components/Footer";
import PharmacyDataLoader from "../components/PharmacyDataLoaderRedirect";


const It = () => {
  useEffect(() => {
    document.title = "Unify Health Italia";
    document.description = "Italia";
  }, []);
  return (
    <div className={'flex flex-col bg-blue-theme justify-center items-center min-h-screen h-full py-20'}>
      <Helmet>
        <title>Unify Health Italia</title>
        <meta name="description" content="Italia" />
      </Helmet>

      <div className="hidden md:fixed bottom-0 left-1 md:left-16">
        <img src={divider} alt="" />
      </div>

      <div className="hidden md:fixed md:flex top-0 right-16">
        <img src={dividerTop} alt="" />
      </div>

      <img src={logo} alt="" className="mx-auto -mt-10 mb-10" />

      <Route exact path={'/'} component={BecaomeAMemberIT} />
      <Route exact path={'/pharmacist/edit/:id'}><PharmacyDataLoader /></Route>
      <Route exact path={'/form'} ><Form /></Route>
      <Route exact path={'/thank-you'}><UnThankYou /></Route>
      <Route exact path={'/privacy-policy'}><PrivacyPolicyIT /></Route>
      <Route exact path={'/terms-and-conditions'}><TermsAndConditionsIT /></Route>
      <Footer />
    </div>
  );
};

export default It;
