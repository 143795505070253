/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import defaultAva from "../../assets/images/defaultava.png";
// import ImageInputWithCrop from "./ImageInputWithCrop";
import {ERROR_TEXT_CLASS, IMAGE_UPLOAD_ENDPOINT, IMAGE_BASE_URL} from "../../constants";
import Loader from "../Loader";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';


const ImageUpload = ({ setFormValue, imageName = '' }) => {

  const initialImageValue = (imageName !== '') ? IMAGE_BASE_URL + '' + imageName : defaultAva;

  const [src, setSrc] = useState(initialImageValue);
  //const [photo, setPhoto] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  // polyfil may not be needed for ie 11 if babel configured

  // if (FileReader.prototype.readAsBinaryString === undefined) {
  //   FileReader.prototype.readAsBinaryString = function (fileData) {
  //       let binary = "";
  //       let pt = this;
  //       var reader = new FileReader();
  //       reader.onload = function (e) {
  //           var bytes = new Uint8Array(reader.result);
  //           var length = bytes.byteLength;
  //           for (var i = 0; i < length; i++) {
  //               binary += String.fromCharCode(bytes[i]);
  //           }
  //           //pt.result  - readonly so assign content to another property
  //           pt.content = binary;
  //           pt.onload(); // thanks to @Denis comment
  //       }
  //       reader.readAsArrayBuffer(fileData);
  //   }
  // }

  const handleImageUpload = (event) => {
    setError(null);
    const files = event.target.files
    const file = files[0];
    if (!file || (!file.type.includes('jpeg') && !file.type.includes('png'))) {
      return setError(t('step1.photoUploadWrongFormat'))
    }
    setIsLoading(true);
    //setSrc(URL.createObjectURL(file));

    const reader = new FileReader();

    const fileSize = ((event.target.files[0].size/1024)/1024).toFixed(4); // MB

    if (fileSize > 5) {
      setIsLoading(false);
      return setError(t('step1.photoUploadError3'));
    }
  
    reader.addEventListener("load", function () {
      // convert image file to base64 string
      const rawImage = reader.result;
      const raw = JSON.stringify({"data": rawImage});

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(IMAGE_UPLOAD_ENDPOINT, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'ok') {
          //setPhoto(data.filename);
          setFormValue('photo',data.filename);
          setFormValue('photoId',data.target_id);
          let baseImageUrl = 'https://cms.unifyhealth.app/sites/default/files/';
          if (location) {
            if (location.href) {
              if ((location.href).indexOf('-dev') !== -1) {
                baseImageUrl = 'https://cms-dev.unifyhealth.app/sites/default/files/';
              } else if ((location.href).indexOf('local') !== -1) {
                baseImageUrl = 'https://unifycmslocaldeve.ddev.site:8443';
              } else {
                console.log("not dev");
              }
            }
          }
          const fullImageUrl = '' + baseImageUrl + '' + data.filename;
          console.log(fullImageUrl);
          setSrc(fullImageUrl);
          //const formData = new FormData();
          //formData.append('photo', data.filename);
        } else {
          setError(t('step1.photoUploadError1'))
        }
      })
      .catch(error => {
        setError(t('step1.photoUploadError2'))
        console.error(error)
      })
      .finally(() => setIsLoading(false))
      
    }, false);
  
    if (file) {
      reader.readAsDataURL(file);
      console.log('readAsDataURL');
    } else {
      console.log('no file');
    }
  
    // const formData = new FormData()
    // formData.append('photo', file)
    // fetch(IMAGE_UPLOAD_ENDPOINT, {
    //   method: 'POST',
    //   body: formData
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log(data)
    //   })
    //   .catch(error => {
    //     setError('Error happened! Please try again!')
    //     console.error(error)
    //   })
    //   .finally(() => setIsLoading(false))
  }

  return (
    <div className="flex flex-col my-6">
      <label htmlFor="">{t('step1.photoUpload')}</label>
      <div className="flex flex-col md:flex-col lg:flex-row justify-between md:mt-6">
        <img src={src} className="w-44 h-44" alt="" id={'preview'} />
        <div className="lg:ml-6 md:mt-4">
          <p className="text-xs text-blue-theme opacity-40 tracking-normal leading-normal">
            {t('step1.photoUploadNote')}
          </p>
          <input type="file" id="fileUpload" onChange={handleImageUpload} accept=".jpg, .jpeg, .png" className='md:mt-4'/>
          {isLoading && <Loader />}
          {error && (<p className={clsx(ERROR_TEXT_CLASS, 'pt-2')}>{error}</p>)}
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;