import clsx from "clsx";
import React from "react";

/**
 * @typedef {object} Props
 * @prop {string} label
 * @prop {boolean=} checked
 * @prop {(e:React.FormEvent<HTMLInputElement>) => void)=} onChange
 * @prop {string} name
 * @prop {string=} styles
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement}
 */

const index = ({ control = {}, id, label, value, onChange, checked, name, styles }) => {
  if (!id) {
    throw new Error('no id passed to ' + label + ' UnRadioButton')
  }
  return (
    <div className={clsx('flex items-center mr-8',styles)}>
      <input
        id={id}
        name={name}
        type="radio"
        className="radio border-yellow-theme border-2"
        onChange={onChange}
        value={value}
        label={label}
        checked={checked}
        {...control}
      />{/* leave {...control} on last place to overwrite onChange by useForm() */}
      <label htmlFor={id} className="font-bold text-blue-theme ml-4">{label}</label>
    </div>
  );
};

export default index;
