import React from "react";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import {ERROR_CLASS_NAME} from "../../constants";

/**
 * @typedef {object} OptionType
 * @prop {string} label
 * @prop {number} value
 */

/**
 * @typedef {object} Props
 * @prop {string} label
 * @prop {(e:React.FormEvent<HTMLInputElement>) => void)=} onChange
 * @prop {Array<OptionType>} options
 * @prop {boolean=} fullWidth
 * @prop {string=} styles
 */

/**
 * @param {Props} props
 * @returns {React.ReactElement}
 */

const Index = ({ control = {}, hasError = false, label, labelWithLink, options, onChange = () => {}, fullWidth = true, styles }) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "flex flex-col mt-4 mb-6",
        fullWidth ? "w-full" : "w-full md:w-full lg:w-1/2",
        styles
      )}
    >
      { labelWithLink 
        ? labelWithLink
        : <label htmlFor="" className="mb-4">
            {label}
          </label>
      }
      <select
        defaultValue={''}
        className={clsx({[ERROR_CLASS_NAME]:hasError}, 'focus:outline-none border-b pb-2')}
        onChange={onChange}
        {...control}
      >{/* leave {...control} on last place to overwrite onChange by useForm() */}

        <option value={''}>
         {t('step1.chooseAnswer')}
        </option>
        {options.map((data) => (
          <option key={data.label + data.value} value={data.value}>
            {data.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Index;
