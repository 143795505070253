import React from 'react';
import UnButton from "../../components/UnButton";
import { useHistory } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


const TermsAndConditions = () => {
  const {goBack} = useHistory();

  return (
    <>


    <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
     <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Terms of Use</h1>
     <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    <ul className="md:pl-8 list-disc">
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#introduction">Introduction</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#purpose_of_platform">Purpose of the Platform</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#registering_with_us">Registering with us</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#access">Access to the Platform</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#what_you_are">What you are allowed to do</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#what_you_are_not">What you are not allowed to do</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#intellectual_property">Intellectual property rights</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#platform">Platform features and content</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#your_personal_information">Your personal information</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#external_links">External links</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#general">General</HashLink></li>
<li className="text-blue-theme tracking-wide leading-normal"><HashLink className="underline font-bold text-blue-theme" smooth to="#contacting_us">Contacting us</HashLink></li>
</ul>
</p>

<h1 id="introduction" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Introduction</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6 flow-root">
These are the terms and conditions of use for the Oncodemia Platform at &nbsp;<a className="underline font-bold text-blue-theme"  href={"https://www.oncodemia.com"} target={"_blank"} rel={"noreferrer"} >www.oncodemia.com</a> (the "Platform") and the separate Community Cancer Champion web application and charter at <a className="underline font-bold text-blue-theme" href={"https://apply.unifyhealth.app"}>apply.unifyhealth.app</a> (the "Application Site"). The Platform and Application Site are operated by or on behalf of Accord Healthcare Limited ('we', 'us' or 'our'). We are a limited company, registered in England. Our registered company number is 04596349, and our registered office is at Sage House, 319 Pinner Road, North Harrow, Middlesex, HA1 4HF. Our VAT registration number is GB 894350693.
</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
Your use of the Platform and Application Site will be subject to these terms and conditions and by using the Platform you agree to be bound by them.
</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We reserve the right to change these terms and conditions from time to time by changing them on the Platform and Application Site. These terms and conditions were last updated on 07.05.2021.</p>
 <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">VERY IMPORTANT: PURPOSE OF THE PLATFORM</h1>
 <h1 id="purpose_of_platform" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">The Platform is not a medical device</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform is not and must not be used as a medical device. The Platform provides information to help you to guide patients through management of their cancer and related symptoms for guidance purposes only and must not be used as a diagnostic, medical or therapeutic tool.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Any information on the Platform is designed as educational guidance and advice which reflects the clinical practices of the Royal Marsden Hospital NHS Foundation Trust. It should not be taken as a recommendation for treatment of any particular person. It is your responsibility to ensure you are compliant with your duty to patients, the terms of your GPhC registration and any other training or professional obligations.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You must never represent the Platform as being a medical device, and never encourage anyone to ignore medical advice from a doctor or other healthcare professional because of something you have learned or seen using the Platform. If you are uncertain on how to advise about the health of any individual, you should inform that individual to consult with their doctor, hospital care team or other healthcare professionals.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The authors of any materials and content on the Platform have formulated the information available with reasonable efforts to be accurate and up to date, however this cannot be guaranteed.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Except as expressly set out in these terms, we make or give no representation or warranty as to the accuracy, completeness, currency, correctness, reliability, integrity, quality, fitness for purpose or originality of any materials or content available on or through the Platform and, to the fullest extent permitted by law, all implied warranties, conditions and other terms of any kind are hereby excluded and we accept no liability for any loss or damage of any kind incurred as a result of you or anyone else using the Platform or relying on any of its content.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We are not responsible for any of your actions decisions or recommendations or those of any pharmacists or other medical professionals. To the extent permitted by law, we exclude all liability for any losses incurred in association with the information, actions, decisions and recommendations of pharmacists or other medical professionals.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform is not intended for use by patients directly and by using the Platform you represent and warrant that you have appropriate professional negligence insurance which is sufficient to cover your use of the Platform.</p>
 <h1 id="registering_with_us" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Registering with us</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform and Application Site are intended for use only by persons who are:</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
<ul className="md:pl-8 list-disc">
<li className="text-blue-theme tracking-wide leading-normal">at least 18 years of age and;</li>
<li className="text-blue-theme tracking-wide leading-normal">qualified pharmacists with a current and valid GPhC registration number.</li>
</ul>

</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">By using the Platform and Application Site, you confirm to us that you meet these requirements.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Please note that additional eligibility criteria may apply to a particular service or feature, where indicated on the Platform and Application Site. It is your responsibility to ensure you satisfy all of the minimum eligibility criteria set out before using any such service or feature. By doing so, you confirm to us that you do meet all of the minimum eligibility criteria.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We may, from time to time, restrict access to certain features, parts or content of the Platform, or the entire Platform, to users who have registered with us. You must ensure that any registration details you provide are accurate. If you choose, or you are provided with, a log-on ID (such as a username and password or other identifier) as part of our security procedures, you must treat such information as confidential and must not reveal it to anyone else. You are responsible for all activities that occur under your log-on ID and must notify us immediately of any unauthorised use or other security breach of which you become aware. We reserve the right to disable any log-on ID, at any time, if in our opinion you have failed to comply with any of the provisions of these terms and conditions or if any details you provide for the purposes of registering as a user prove to be false.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">To register, you must provide us with accurate, complete and up-to-date contact information, including name, postal address, telephone number and email address. You are responsible for the information you provide to us. You must promptly update your account information online in the event of any changes to this information. We reserve the right, in our discretion, to carry out checks for the purposes of confirming your identity and to establish your qualifications and satisfaction of the eligibility criteria. By agreeing to these terms, you agree to our doing so.</p>
 <h1 id="access" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Access to the Platform and Application Site</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">It is your responsibility to ensure your equipment (computer, laptop, netbook, tablet or other mobile device) meets all the necessary technical specifications to enable you to access and use the Platform and Application Site and is compatible with the Platform and Application Site.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">We cannot guarantee the continuous, uninterrupted or error-free operability of the Platform and Application Site. There may be times when certain features, parts or content of the Platform and Application Site, or the entire Platform and Application Site, become unavailable (whether on a scheduled or unscheduled basis) or are modified, suspended or withdrawn by us, in our sole discretion, without notice to you. You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of the Platform and Application Site, or any features, parts or content of the Platform and Application Site.</p>
 <h1 id="what_you_are" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">What you are allowed to do</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You may only use the Platform and Application Site for personal, non-commercial use and only in accordance with these terms and conditions. You may retrieve and display content from the Platform and Application Site on a computer screen, print and copy individual pages and, subject to the next section, store such pages in electronic form. Additional terms may also apply to certain features, parts or content of the Platform and Application Site, and, where they apply, will be displayed on-screen or accessible via a link.</p>
 <h1 id="what_you_are_not" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">What you are not allowed to do</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Except to the extent expressly set out in these terms and conditions or any other agreement with us, you are not allowed to:</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
<ul className="md:pl-8 list-disc">
<li className="text-blue-theme tracking-wide leading-normal">'scrape' content or store content of the Platform or Application Site on a server or other storage device connected to a network or create an electronic database by systematically downloading and storing all of the content of the Platform or Application Site;</li>
<li className="text-blue-theme tracking-wide leading-normal">dissemble the training, or integrate any part of the training content,Platform or Application Site into any other website, learning management or tracking system;</li>
<li className="text-blue-theme tracking-wide leading-normal">remove or change any content of the Platform or Application Site or attempt to circumvent security or interfere with the proper working of the Platform or the servers on which it is hosted; or</li>
<li className="text-blue-theme tracking-wide leading-normal">create links to the Platform or Application Site from any other website, without our prior written consent, although you may link from a website that is operated by you provided the link is not misleading or deceptive and fairly indicates its destination, you do not imply that we endorse you, your website, or any products or services you offer, you link to (and do not frame or replicate) the home page of the Platform or Application Site, and the linked website does not contain any content that is unlawful, threatening, abusive, defamatory, pornographic, obscene, vulgar, indecent, offensive or which infringes on the intellectual property rights or other rights of any third party.</li>
</ul>
</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You must only use the Platform or Application Site and anything available from the Platform or Application Site for lawful purposes (complying with all applicable laws and regulations), in a responsible manner, and not in a way that might damage our name or reputation or that of any of our affiliates.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">All rights granted to you under these terms and conditions will terminate immediately in the event that you are in breach of any of them.</p>
 <h1 id="intellectual_property" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Intellectual property rights</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">All intellectual property rights in any content of the Platform and Application Site (including text, graphics, software, photographs and other images, videos, sound, trade marks and logos) are owned by us or our licensors. Except as expressly set out here, nothing in these terms and conditions gives you any rights in respect of any intellectual property owned by us or our licensors and you acknowledge that you do not acquire any ownership rights by downloading content from the Platform or Application Site. In the event you print off, copy or store pages from the Platform or Application Site (only as permitted by these terms and conditions), you must ensure that any copyright, trade mark or other intellectual property right notices contained in the original content are reproduced.</p>


 <h1 id="platform" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Platform features and content</h1>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You agree accessing and use of the Platform and Application Site, since they are made available for download free of charge, are on an 'as is' and 'as available' basis and at your sole risk.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Where the Platform or Application Site makes content available, you acknowledge that such content may be updated at any time. We reserve the right to cease to provide and/or update content to the Platform or Application Site, with or without notice to you, if we need to do so for security, legal or any other reason.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Whilst we try to make sure that content made available by the Platform consists of information of which we are confident the source is correct, you acknowledge that the Platform may make content available which is derived from a number of sources, for which we are not responsible.</p>
 <h1 id="your_personal_information" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Your personal information</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Use of your personal information submitted to or via the Platform and Application Site is governed by our Privacy and Cookies Policy, noting that your personal information will be shared with the public (including patients).</p>
 <h1 id="external_links" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">External links</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">The Platform and Application Site may, from time to time, include links to external sites, which may include links to third party offers and promotions. We include these to provide you with access to information, products or services that you may find useful or interesting. We are not responsible for the content of these sites or for anything provided by them and do not guarantee that they will be continuously available. The fact that we include links to such external sites does not imply any endorsement of or association with their operators or promoters.</p>
 <h1 id="general" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">General</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Nothing in these terms and conditions shall limit or exclude our liability to you:</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
<ul className="md:pl-8 list-disc">
<li className="text-blue-theme tracking-wide leading-normal">for death or personal injury caused by our negligence;</li>
<li className="text-blue-theme tracking-wide leading-normal">for fraudulent misrepresentation;</li>
<li className="text-blue-theme tracking-wide leading-normal">for breach of any term implied by the Consumer Rights Act 2015 and which, by law, may not be limited or excluded; or</li>
<li className="text-blue-theme tracking-wide leading-normal">for any other liability that, by law, may not be limited or excluded.</li>
</ul>
</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Subject to this, if you are a consumer and not a business user, in no event shall we be liable to you for any business losses, and if you are a business user, in no event shall we be liable to you for any indirect or consequential losses, or for any loss of profit, revenue, contracts, data, goodwill or other similar losses, and any liability we do have for losses you suffer is strictly limited to losses that were reasonably foreseeable and shall not, in aggregate, exceed the sum you paid for the Platform or Application Site.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">These terms and conditions shall be governed by English law, except that (if you are a consumer and not a business user) and if you live in a country (which, for these purposes, includes Scotland or Northern Ireland) of the European Union other than England, there may be certain mandatory applicable laws of your country which apply for your benefit and protection in addition to or instead of certain provisions of English law.</p>

<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">You agree that any dispute between you and us regarding these terms and conditions or the Platform and Application Site will only be dealt with by the English courts, except that (if you are a consumer and not a business user) and if you live in a country (which, for these purposes, includes Scotland or Northern Ireland) of the European Union other than England, you can choose to bring legal proceedings either in your country or in England, but if we bring legal proceedings, we may only do so in your country.</p>
 <h1 id="contacting_us" className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">Contact us</h1>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Please submit any questions you have about these terms and conditions or any problems concerning the Platform and Application Site to the address below.</p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Oncodemia Platform Email: &nbsp;<a className="underline font-bold text-blue-theme" href={"mailto:support@oncodemia.com"}>support@oncodemia.com</a></p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Application Site - Email:  &nbsp;<a className="underline font-bold text-blue-theme" href={"mailto:privacy@unifyhealth.app"}>privacy@unifyhealth.app</a> </p>
<p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">Post: Accord Healthcare Limited Sage House 319 Pinner Road North Harrow, Middlesex HA1 4HF.</p>
<p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Back"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
    </div>
    </>

  )
}

export default TermsAndConditions;
