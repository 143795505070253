import React from 'react';
import { ERROR_TEXT_CLASS } from "../../constants";

const ErrorMessage = ({ errorMessage = ''}) => {
  let message = '';
  let subMessage = '';
  if (errorMessage === '') {
    message = 'Sorry this service is temporarily unavailable';
    subMessage = 'Please try again later';
  } else {
    message = errorMessage;
  }
  return (
    <div className='text-center mt-10'>
      <h1 className={ERROR_TEXT_CLASS + ' pb-4'}><b>{message}</b></h1>
      <p>{subMessage}</p>
    </div>
  )
}

export default ErrorMessage;
