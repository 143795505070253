import React from 'react';
import UnButton from "../../components/UnButton";
import { useHistory } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';


const ContactUs = () => {
  const {goBack} = useHistory();

  return (
    <>
    <div className="py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
    
    <h1 id="ContactUs" className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6">Impressum</h1>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Accord Healthcare Limited
    <br />
    <br />
    Sage House, 319 Pinner Road
    <br />
    North Harrow, Middlesex
    <br />
    HA1 4HF - London
    <br />
    United Kingdom
    <br />
    T:&nbsp;<a className="underline font-bold text-black-theme" href="tel:+44 (0) 208 863 1427">+44 (0) 208 863 1427</a>
    <br />
    F: +44 (0) 208 863 1427
    <br />
    E-Mail:&nbsp;
    <a className="underline font-bold text-black-theme" href="mailto:support@oncodemia.com">support@oncodemia.com</a>
    &nbsp;und&nbsp;
    <a className="underline font-bold text-black-theme" href="mailto:registrierung@unifyhealth.app">
      registrierung@unifyhealth.app
    </a>
    <br />
    Webseite:&nbsp;
    <a className="underline font-bold text-black-theme" href="https://www.accord-healthcare.com/">www.accord-healthcare.com</a>
    <br />
    <br />
    Geschäftsführung: Paul Tredwell, Stephen Ford, Binish Chudgar, Pradeep
    Bhagia
    <br />
    <br />
    Sitz der Gesellschaft: Sage House, 319 Pinner Road
    <br />
    North Harrow, Middlesex
    <br />
    HA1 4HF - London
    <br />
    Großbritannien
    <br />
    <br />
    Registergericht: Amtsgericht London, Großbritannien
    <br />
    <br />
    Handelsregister-Nummer: 4596349
    <br />
    <br />
    Inhaltlich Verantwortliche gem. §18 Abs. 2 MStV: Jean-Philippe Bitterlin,
    Accord Healthcare GmbH, Hansastraße 32, 80686 Munich, Germany
    <br />
    <br />
    Umsatzsteuer-Identifikationsnummer: GB 894350693
    <br />
    &nbsp;
  </p>
  <h2 className="text-md md:text-xl text-black-theme font-bold tracking-relax mx-auto mb-6">Inhalt der Internet-Seiten</h2>
  <p className="flex-wrap text-black-theme text-sm md:text-base tracking-wide opacity-70 leading-normal mb-6">
    Der gesamte Inhalt des Internet-Auftritts der Oncodemia und der Unify Health
    ist urheberrechtlich geschützt. Texte, Grafiken, Bilder dürfen ohne
    schriftliche Genehmigung keinesfalls weiterverwendet werden. Für die
    Richtigkeit der Angaben wird im Rahmen der gesetzlichen Vorschriften keine
    Haftung übernommen und wir weisen darauf hin, dass wir keinerlei Haftung für
    Schäden übernehmen werden, die auf die Verwendung unseres Internet-Auftritts
    zurück zu führen sind. Sollten Inhalte von www.oncodemia.de und
    www.registrierung.unifyhealth.app/ und nachgelagerten Seiten gegen geltende
    Rechtsvorschriften verstoßen, bitten wir um umgehende Benachrichtigung. Wir
    werden die Seite oder den betreffenden Inhalt dann schnellstmöglich
    entfernen.
    <br />
    <br />
    Online-Streitbeilegung und Alternative Streitbeilegung
    <br />
    <br />
    Wir sind weder bereit noch verpflichtet, an Streitbeilegungsverfahren vor
    einer Verbraucherschlichtungsstelle teilzunehmen. Die Europäische Kommission
    stellt jedoch eine&nbsp;
    &nbsp;<a className="underline font-bold text-black-theme" href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE">
      Plattform zur Online-Streitbeilegung
    </a>
    &nbsp;(OS-Plattform) bereit.
  </p>


      <p className={'flex flex-col mt-10'}>
        <UnButton
          onClick={goBack}
          title="Zurück"
          type={'submit'}
          fullWidth={false}
          position="right"
        />
      </p>
    </div>
    </>
  )
}

export default ContactUs;
