import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

//screen
import { COUNTRY, LANGUAGE, SITE } from './screens/Form/constant';
import Uk from './screens/Uk';
import It from './screens/It';
import De from './screens/De';
import ItAreaManager from './screens/ItAreaManager';
import ItRep from './screens/ItRep';

function App() {
  return (
    <Router>
      <Switch>
        { (COUNTRY === 'uk' && LANGUAGE === 'en' && SITE === 'default') && <Route path={'/'}><Uk /></Route> }
        { (COUNTRY === 'it' && LANGUAGE === 'it' && SITE === 'default') && <Route path={'/'}><It /></Route> }
        { (COUNTRY === 'de' && LANGUAGE === 'de' && SITE === 'default') && <Route path={'/'}><De /></Route> }
        { (COUNTRY === 'it' && LANGUAGE === 'it' && SITE === 'rep') && <Route path={'/rep'}><ItRep /></Route> }
        { (COUNTRY === 'it' && LANGUAGE === 'it' && SITE === 'areamanager') && <Route path={'/areamanager'}><ItAreaManager /></Route> }
      </Switch>
    </Router>
  );
}

export default App;
