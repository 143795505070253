/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import defaultAva from "../../assets/images/defaultava.png";
// import ImageInputWithCrop from "./ImageInputWithCrop";
import {ERROR_TEXT_CLASS, IMAGE_UPLOAD_ENDPOINT, IMAGE_BASE_URL} from "../../constants";
import Loader from "../Loader";
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import Webcam from "react-webcam";


const ImageUploadWithCamera = ({ setFormValue, imageName = '' }) => {

  const initialImageValue = (imageName !== '') ? IMAGE_BASE_URL + '' + imageName : defaultAva;

  const [src, setSrc] = useState(initialImageValue);
  //const [photo, setPhoto] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cameraError, setCameraError] = useState(null);
  const [facingMode, setFacingMode] = useState('user');
  const [showCamera, setShowCamera] = useState(false);
  const { t } = useTranslation();

  const [devices, setDevices] = React.useState([]);

  const handleDevices = React.useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );

  const webcamRef = React.useRef(null);
  const uploadPhotoRef = React.useRef(null);

  const handleCapture = async() => {
      setError(null);
      const imageSrc = webcamRef.current.getScreenshot();
      handleImagePost(imageSrc);
      setShowCamera(false);
    };


  // polyfil may not be needed for ie 11 if babel configured

  // if (FileReader.prototype.readAsBinaryString === undefined) {
  //   FileReader.prototype.readAsBinaryString = function (fileData) {
  //       let binary = "";
  //       let pt = this;
  //       var reader = new FileReader();
  //       reader.onload = function (e) {
  //           var bytes = new Uint8Array(reader.result);
  //           var length = bytes.byteLength;
  //           for (var i = 0; i < length; i++) {
  //               binary += String.fromCharCode(bytes[i]);
  //           }
  //           //pt.result  - readonly so assign content to another property
  //           pt.content = binary;
  //           pt.onload(); // thanks to @Denis comment
  //       }
  //       reader.readAsArrayBuffer(fileData);
  //   }
  // }

  const handleImageUpload = (event) => {
    setError(null);
    const files = event.target.files
    const file = files[0];
    if (!file || !file.type.includes('image')) {
      return setError(t('step1.photoUploadWrongFormat'));
    }
    const reader = new FileReader();
    reader.addEventListener("load", function () {
      // convert image file to base64 string
      const rawImage = reader.result;
      handleImagePost(rawImage);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      console.log('readAsDataURL');
    } else {
      console.log('no file');
    }
  }

  const handleImagePost = async(rawImage) =>{
    setIsLoading(true);
    const base64str = rawImage.split('base64,')[1]; //remove the image type metadata.
    const imageFile = Buffer.from(base64str, 'base64'); //encode image into bytes
    const fileSize = ((imageFile.length/1024)/1024).toFixed(4); // MB
    if (fileSize > 5) {
      setIsLoading(false);
      return setError(t('step1.photoUploadError3'));
    }
    try {
        const imageData = JSON.stringify({"data": rawImage}); 
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: imageData,
          redirect: 'follow'
        };
        const response = await fetch(IMAGE_UPLOAD_ENDPOINT,requestOptions);
        const result = await response.json();
        if(response.ok && result.status === 'ok'){
          setFormValue('photo',result.filename);
          setFormValue('photoId',result.target_id);
          let baseImageUrl = 'https://cms.unifyhealth.app/sites/default/files/';
          if (location) {
            if (location.href) {
              if ((location.href).indexOf('-dev') !== -1) {
                baseImageUrl = 'https://cms-dev.unifyhealth.app/sites/default/files/';
              } else if ((location.href).indexOf('local') !== -1) {
                baseImageUrl = 'https://unifycmslocaldeve.ddev.site:8443';
              } else {
                console.log("not dev");
              }
            }
          }
          const fullImageUrl = '' + baseImageUrl + '' + result.filename;
          setSrc(fullImageUrl);
        }else{
          setError(t('step1.photoUploadError1'))
        }
        setIsLoading(false);
    } catch (error) {
      console.log('error: ', error);
      setError(t('step1.photoUploadError2'))
      setIsLoading(false);
    }    

  }

  const handleRemovePhoto = () =>{
    setError(null);
    setCameraError(null);
    setShowCamera(false);
    setSrc(defaultAva);
  }

  const handleCameraMode = () =>{
    if(facingMode === 'user'){
      setFacingMode('environment');
    }
    else{
      setFacingMode('user');
    }
  }

  const handleCaptureCancel = () => {
    setFacingMode('user');
    setError(null);
    setCameraError(null);
    setShowCamera(false); 
  }

  return (
    <div className="flex flex-col">

        <div className="flex flex-col my-6 justify-start items-start">
          <label>{t('step1.photoUpload')}</label>
          <label className="text-sm text-blue-theme opacity-40 tracking-normal leading-normal">{'Optional'}</label>
        </div>

        <div className="flex flex-col lg:flex-row">
         
          <div className="flex flex-1 flex-col sm:flex-row">
           
            <div className="flex flex-1 justify-center	items-center">
              {
                showCamera 
                ? cameraError ? <p className={clsx(ERROR_TEXT_CLASS, 'pt-2')}>{cameraError}</p>
                : <Webcam
                    audio={false}
                    height={400}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={400}
                    videoConstraints={{
                      width: 400,
                      height: 400,
                      facingMode: facingMode,
                    }}
                    onUserMediaError={(error)=>{
                      setCameraError("Camera permission denied!");
                    }}
                  />
                : isLoading 
                  ? <div className="flex flex-1 justify-center	items-center"><Loader /></div>
                  : <img src={src} className="w-50 h-50 border-solid border-2" alt="" id={'preview'} />
              } 
            </div>

            <div className="flex flex-col flex-1 justify-start mx-6 mt-6 sm:mt-0">

              { 
                (devices.length > 1 && showCamera) && 
                <button disabled={isLoading} type='button' className={'border-solid border-2 border-light-blue-500 mb-4 py-0.5'} onClick={handleCameraMode}>
                  <span className="text-sm tracking-normal leading-normal">{ facingMode === 'user' ? 'Back' : 'Front' }</span>
                </button>
              }

              {
                !showCamera ?
                <button disabled={isLoading} type='button' className={'border-solid border-2 border-light-blue-500 py-1'} onClick={()=>{setShowCamera(true);}}>
                  <span className="text-sm tracking-normal leading-normal">{'Take Photo'}</span>
                </button>
                :
                <div className="flex justify-around">
                  <button disabled={isLoading || !!cameraError} type='button' className={'flex flex-1 justify-center border-solid border-2 border-light-blue-500 mr-4 py-1'} onClick={handleCapture}>
                    <span className="text-sm tracking-normal leading-normal">{'Capture'}</span>
                  </button>
                  <button disabled={isLoading} type='button' className={'flex flex-1 justify-center border-solid border-2 border-light-blue-500 py-1'} onClick={handleCaptureCancel}>
                    <span className="text-sm tracking-normal leading-normal">{'Cancel'}</span>
                  </button>
                </div>
              }

              <button disabled={isLoading} type='button' className={'border-solid border-2 border-light-blue-500 mt-4 py-0.5'} onClick={()=>{uploadPhotoRef.current.click();}}>
                <span className="text-sm tracking-normal leading-normal">{'Upload photo'}</span>
              </button>

              <input ref={uploadPhotoRef} className="hidden" type="file" id="fileUpload" onChange={handleImageUpload} />

              <button disabled={isLoading} type='button' className={'mt-4 flex justify-start'} onClick={handleRemovePhoto}>
                <span className="text-sm text-blue-theme opacity-40 tracking-normal leading-normal">{'Remove Photo'}</span>
              </button>
              
              { error && (<p className={clsx(ERROR_TEXT_CLASS, 'pt-2')}>{error}</p>)}

            </div>

          </div>

          <div className="flex flex-1 mt-4 lg:mt-0">
            <p className="text-xs text-blue-theme opacity-40 tracking-normal leading-normal">
              {t('step1.photoUploadNote')}
            </p>
          </div>

        </div>

    </div>
  );
}

export default ImageUploadWithCamera;
