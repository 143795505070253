import React, { useState, useEffect } from 'react';
import UnIndicator from "../../components/UnIndicator";
import { Route, useHistory, useParams, useLocation } from "react-router-dom";
import StepOneUk from "../../components/StepOne/index";
import StepOneIT from "../../components/StepOne/indexIT";
import StepOneITAreaManager from "../../components/StepOne/indexITAreaManager";
import StepOneDE from "../../components/StepOne/indexDE";
import StepTwo from "../../components/StepTwo";
import StepThree from "../../components/StepThree/index";
import StepThreeDE from "../../components/StepThree/indexDE";
import {saveData, stripHtml} from "./utils";
import Loader from "../../components/Loader";
import { GET_ENDPOINT } from "../../constants";
import ErrorMessage from "./ErrorMessage";
import { useTranslation } from 'react-i18next';
import { COUNTRY, LANGUAGE, SITE } from '../Form/constant';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const steps = [
  {
    value: 1,
    label: "Step 1",
  },
  {
    value: 2,
    label: "Step 2",
  },
  {
    value: 3,
    label: "Step 3",
  },
];

const Form = () => {
  const params = useParams()
  const history = useHistory();
  const query = useQuery();
  const pharmacistId = query.get('pharmacistId');
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPharmacy, setIsLoadingPharmacy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMesage] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const { t } = useTranslation();

  const pharmacyId = query.get('pharmacyId'); //params.pharmacyId ? (params.id * 1) : null;

  useEffect(() => {
    if (pharmacistId && !isDataLoaded) {
      setIsLoading(true);
      fetch(GET_ENDPOINT.replace('##id##', pharmacistId))
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.log('error - could not get pharmacy and pharmacist details from API')
            throw Error('Error happened at fetch');
          }
        })
        .then((json) => {
          setIsDataLoaded(true);
          if(COUNTRY === 'uk' && LANGUAGE === 'en'){
            if(json.data.additionalTrainingNotes){
              json.data.haveYouCompleted = 'yes1'
            }
            else if(json.data.haveYouCompleted === 'yes'){
              json.data.haveYouCompleted = 'yes2'
            }
          }
          setData(json.data || json);
        })
        .catch((err) => {setHasError(true); console.log('Was error',err)})
        .finally(() => setIsLoading(false));
    }
  }, [pharmacistId, isDataLoaded])

 
  const submit = async function(nextPage, formData = {}) {
    console.log('HERE')
    const newData = {
      ...data,
      ...formData,
      appStoreCountry: COUNTRY,
      formLanguage: LANGUAGE
    }
    if((pharmacistId && nextPage === 'last') || (pharmacistId && nextPage === 'uk') || (pharmacistId && nextPage === 'it')){
      newData.loginToken = pharmacistId;
    }
    setData(newData)
    if (nextPage === 'last' || nextPage === 'uk' || nextPage === 'it') {
      setIsLoading(true);
      newData.whereYouWork = stripHtml(newData.whereYouWork);
      newData.anythingElseAboutYou = stripHtml(newData.anythingElseAboutYou);
      newData.address = stripHtml(newData.address);
      newData.howDidYouFindAboutTheProgram = stripHtml(newData.howDidYouFindAboutTheProgram);
      newData.services = stripHtml(newData.services);
      if(COUNTRY === 'uk' && LANGUAGE === 'en'){
        if(newData.haveYouCompleted === 'yes1'){
          newData.haveYouCompleted = 'yes';
          newData.additionalTrainingNotes = stripHtml(newData.additionalTrainingNotes);
        }else if(newData.haveYouCompleted === 'yes2'){
          newData.haveYouCompleted = 'yes';
          delete newData.additionalTrainingNotes;
        }else if(newData.haveYouCompleted === 'no'){
          delete newData.additionalTrainingNotes;
        }
      }
      if(nextPage === 'uk') {
        delete newData.bodyOfRegistration;
        delete newData.howDidYouFindAboutTheProgram;
        delete newData.completedAdditionalTraining;
      }
      try {
        console.log('HERE 1aaaaa')
        const result = await saveData(newData, pharmacistId);
        setIsLoading(false);
        if (!result) {
          //setErrorMesage('ERROR 1: No results found 1');
          setHasError(true);
        } else {
          console.log('HERE 2a')
          if (result.status === 'failed') {
            console.log('HERE 2b')
            let message = (!result.message) ?  t('errorsGlobal.temporarilyUnavailableService') : result.message;
            if (message === 'errorEmailRegistered' || message === 'errorEmailRegistered2' ) {
              message =  t('step3.errorEmailIsAlreadyRegistered');
            } else if (message === 'errorRegisterantNumberRegistered' || message === 'errorRegisterantNumberRegistered2' ) {
              message =  t('step3.errorRegistrantIdIsAlreadyRegistered');
            } else {
              message =  t('errorsGlobal.temporarilyUnavailableService');
            }       
            setErrorMesage(message);
            setHasError(true);
          } else {
            console.log('HERE 2')
            if (SITE === 'areamanager') {
              history.push(`/areamanager/thank-you`);
            } else {
              history.push(`/thank-you`);
            }
          }
        }
      } catch (err) {
        console.log('HERE 3')
        setIsLoading(false);
        //setErrorMesage('ERROR 2: No results found 2', err);
        setHasError(true);
      }
    } else {
      console.log('HERE 4');
      const query = pharmacistId ? '?pharmacistId=' + pharmacistId : '';
      if (SITE === 'areamanager') {
        history.push( `/areamanager/form/${nextPage}${query}`)
      } else {
        if(nextPage === 'uk' || nextPage === 'it') {
          history.push( `/form/${query}`)
        }
        history.push( `/form/${nextPage}${query}`)
      }
    }
  };

  const routeHandler = () => {
    switch(LANGUAGE+COUNTRY+SITE){
      case 'enukdefault':
        return (
          <>
            <Route exact path={'/form'}><StepOneUk data={data} onSubmit={submit.bind(null, 'uk')} /></Route>
          </>
        );
      case 'ititdefault':
        return (
          <>
            <Route exact path={'/form'}><StepOneIT data={data} onSubmit={submit.bind(null, 'it')} /></Route>
          </>
        );
      case 'dededefault':
        return (
          <>
            <Route exact path={'/form/1'}><StepOneDE data={data} onSubmit={submit.bind(null, 2)} /></Route>
            <Route exact path={'/form/2'}><StepTwo data={data} onSubmit={submit.bind(null, 3)} /></Route>
            <Route exact path={'/form/3'}><StepThreeDE data={data} onSubmit={submit.bind(null, 'last')} /></Route>
          </>
        );
      default:
        return (
          <>
            <Route exact path={'/form/1'}><StepOneUk data={data} onSubmit={submit.bind(null, 2)} /></Route>
            <Route exact path={'/form/2'}><StepTwo data={data} onSubmit={submit.bind(null, 3)} /></Route>
            <Route exact path={'/form/3'}><StepThree data={data} onSubmit={submit.bind(null, 'last')} /></Route>
          </>
        );
      }
    }

  return (
    <div className="flex flex-col py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12">
      <h1 className="text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
        {t('registration.title')}
      </h1>
      {
        (COUNTRY+LANGUAGE !== 'uken' && COUNTRY+LANGUAGE !== 'itit') && 
        <UnIndicator
          steps={steps}
          activeStep={Number(params.step)}
        />
      }
      {isLoading && (
        <div className={'flex mx-auto'}>
          <Loader />
        </div>
      )}
       {isLoadingPharmacy && (
        <div className={'flex mx-auto'}>
          <Loader />
        </div>
      )}
      {hasError && <ErrorMessage errorMessage={errorMessage} />}
      {!hasError && !isLoading && routeHandler() }
    </div>

  )
}
export default Form;
