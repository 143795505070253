import React from 'react';
import UnButton from "../../components/UnButton";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
const BecomeAMember = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col md:mx-0 py-10 px-10 bg-white rounded-md w-10/12 md:w-7/12 h-3/6 overflow-y-scroll">
        <h1 className="text-md md:text-xl text-blue-theme font-bold tracking-relax mx-auto mb-6">
          {t('becomeMember.heading')}
        </h1>
        <ul className="mt-6 md:pl-8 list-decimal">
         
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
           {t('becomeMember.stepsToBecomeChap.one')}
          </li>
       
          <li className="text-blue-theme tracking-wide leading-normal mb-4">
           {t('becomeMember.stepsToBecomeChap.two')}
          </li>
       
        </ul>
        
        
          <p className="flex flex-wrap text-blue-theme text-sm md:text-base tracking-wide opacity-70 leading-normal flow-root mb-4">
          Wenn Sie diese Seite erreicht haben, sollten Sie die Oncodemia Weiterbildung abgeschlossen haben. Oncodemia vermittelt Apothekern wie Ihnen ein tieferes Verständnis für häufige Krebsarten, Krebsbehandlungen, 
          Krebssymptome und unterstützende Maßnahmen. So können Sie Krebspatienten praktische Ratschläge geben. Oncodemia ist eine von Accord Healthcare gesponserte Weiterbildung und wurde in Zusammenarbeit mit dem Royal Marsden NHS Foundation Trust, 
          einem weltweit führenden Krebszentrum, entwickelt. Die Oncodemia- Weiterbildung können Sie unter <a className="underline font-bold text-blue-theme" href={"http://www.oncodemia.de"} target={"_blank"} rel={"noreferrer"}>www.oncodemia.de</a> abrufen.
          </p>
         
        
      </div>
      <div style={{marginTop:40}}>
        <UnButton
          title={t('becomeMember.button.continue')}
          fullWidth={false}
          position="center"
          onClick={() => history.push('/form/1')}
        />
      </div>
    </>

  );
};

export default BecomeAMember;