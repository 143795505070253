import React from 'react';
import { useTranslation } from 'react-i18next';

const Index = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-10/12 md:w-4/12 mx-auto py-14 bg-white rounded-lg px-10">
                <p className="font-bold text-blue-theme text-lg text-center">{t('thankyouPage.thankyou')}</p>
                <p className="text-blue-theme mt-4 opacity-70 text-center">{t('thankyouPage.applicationSubmitted')}</p>
                <p className="text-blue-theme mt-4 opacity-70 text-center">{t('thankyouPage.applicationReview')}</p>
                <p className="text-blue-theme mt-4 opacity-70 text-center">{t('thankyouPage.reviewPeriod')}</p>
                 <p className="text-blue-theme mt-4 opacity-70 text-center">{t('thankyouPage.reviewDecision')}</p>
        </div>
    );
};

export default Index;